import React from 'react';
import { Grid } from '@mui/material';
import { tokens } from '../../theme';
import NewStats from './NewStats';


const Row1Component = ({ theme }) => {
  const colors = tokens(theme.palette.mode);
  // colors.primary[400]
  return (
    <Grid container spacing={2} mb={1} sx={{padding:"16px 0 16px 16px"}} >
      <NewStats theme={theme}/>
    </Grid>
  );
};

export default Row1Component;
