import React, { useState } from 'react'
import styles from './SearchBox.module.scss'
import { Button, Grid, TextField } from '@mui/material'
import { baseUrl } from '../../utils/baseURL'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SearchBox = () => {
    const [email, setEmail] = useState()
    const handleRegister = () => {
        fetch(`${baseUrl}/subscription/request`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to register');
                }
                toast.success("success");
                console.log('Registration successful');
                setEmail("")
            })
            .catch(error => {
                console.error('Registration failed:', error);
            });
    };
    return (
        <>
        <ToastContainer/>
        <Grid className={styles.SearchBox}>
            <TextField placeholder='Enter your email address' value={email} onChange={(e) => setEmail(e.target.value)}
                InputLabelProps={{
                    style: {color:"#0E588C" }
                  }}
                  InputProps={{
                    style: {
                      color:"#0E588C",
                    }}}
            />
            <Button variant='outlined' onClick={handleRegister}>REGISTER NOW</Button>
        </Grid>
        </>
    )
}

export default SearchBox