import { Grid, Typography } from '@mui/material'
import React from 'react'

const HeadingText = ({ subHeading, heading }) => {
    return (
        <Grid>
            <Typography variant='h5' fontSize={21} marginBlockEnd={1} fontWeight={500}>{subHeading}</Typography>
            <Typography variant='h4' fontSize={31} fontWeight={800}>{heading}</Typography>
        </Grid>
    )
}

export default HeadingText