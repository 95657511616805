import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import {useTheme,Typography} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import { tokens } from '../../theme'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0088AF",
    color: theme.palette.common.white,
    fontSize: 16,
    padding: '10px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '10px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledButton = styled(Button)(({ theme, active }) => ({
  border: 'none',
  padding: '0px 10px',
  borderRadius: '2rem',
  height: '30px',
  backgroundColor: active ? 'var(--intgrow-bg)' : '#e0e0e0',
  color: active ? '#fff' : '#000',
  '&:hover': {
    backgroundColor: 'var(--intgrow-bg)',
    color: '#fff',
  },
}));

const PaginationButton = styled(Button)(({ theme, active }) => ({
  marginRight: theme.spacing(1),
  backgroundColor: active ? 'var(--intgrow-bg)' : 'inherit',
  color: active ? '#fff' : theme.palette.text.primary,
  '&:hover': {
    backgroundColor: active ? 'var(--intgrow-primary-color)' : 'var(--intgrow-bg)',
  },
}));

const ActivePaginationButton = styled(PaginationButton)(({ theme }) => ({
  backgroundColor: 'var(--intgrow-bg)',
  color: '#fff',
  '&:hover': {
    backgroundColor: 'var(--intgrow-primary-color)',
  },
}));

const HSResultTable = ({ setIsHSCodeTreeOpen, handleSelectClick, selectedHSCodeLocal, setIsHSCodeResult, open, setOpen, data, setSelectedHSCode, selectedRowId }) => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const colors = tokens(mode)

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [filterData, setFilterData] = useState([]);
  useEffect(() => {
    setFilterData(data);
  }, [data]);

  const indexOfLastFilterData = currentPage * rowsPerPage;
  const indexOfFirstFilterData = indexOfLastFilterData - rowsPerPage;
  const currentRows = filterData.slice(indexOfFirstFilterData, indexOfLastFilterData);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleHSCodeTree = (hscode) => {
    setSelectedHSCode(hscode);
    setIsHSCodeTreeOpen(true);
    setIsHSCodeResult(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    handleHSCodeTree(selectedHSCodeLocal);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: '10%' }}>HS Codes</StyledTableCell>
              <StyledTableCell style={{ width: '75%' }}>Product Description</StyledTableCell>
              <StyledTableCell style={{ width: '15%' }} align="right">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRows.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>{row.HSCode}</StyledTableCell>
                <StyledTableCell>{row.Description}</StyledTableCell>
                <StyledTableCell align="right">
                  <StyledButton
                    active={row.id === selectedRowId}
                    onClick={() => handleSelectClick(row)}
                  >
                    Select
                    <Radio
                      checked={row.id === selectedRowId}
                      value={row.id}
                      name="selectedHSCode"
                      sx={{ marginLeft: 1 }}
                    />
                  </StyledButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: 'fit-content',
            backgroundColor: colors.primary[400],
            backgroundImage: "none !important"

          }
        }}>
        <DialogTitle>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <img src="/assets/images/point_deduction.png" alt="point deduction" width={150} height={150} />
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{textAlign:"center"}}>
            <Typography variant="p2" className='point-deduction-msg'>If you proceed <span className='highlight-msg'>2 points</span> will be deduct from your <span className='highlight-msg'>HS Code</span> finder credits. </Typography>
            <br/>
            <Typography variant="p2" className='point-want-to-proceed'> Do you want to proceed?</Typography>
          </DialogContentText>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button onClick={handleConfirm} className='point-deduction-yes-btn' autoFocus>
              Yes
            </Button>
            <Button onClick={handleClose} className='point-deduction-cancel-btn'>
              Cancel
            </Button>
          </div>
        </DialogContent>
        <DialogActions>

        </DialogActions>
      </Dialog>

      <div style={{ marginTop: 16 }}>
        <ActivePaginationButton
          variant="contained"
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </ActivePaginationButton>
        {[...Array(Math.ceil(filterData.length / rowsPerPage)).keys()].map(number => (
          <PaginationButton
            key={number}
            onClick={() => paginate(number + 1)}
            active={currentPage === number + 1}
            variant="contained"
          >
            {number + 1}
          </PaginationButton>
        ))}
        <ActivePaginationButton
          variant="contained"
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage >= Math.ceil(filterData.length / rowsPerPage)}
        >
          Next
        </ActivePaginationButton>
      </div>
    </div>
  );
}

export default HSResultTable;
