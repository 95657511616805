import { Box, Typography, Modal, Button, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import CountryFlag from 'react-country-flag';
import { useEffect, useState } from "react";
import { fetchCountry, fetchDutySaverHistoryRecordDetails, fetchDutySaverHistoryRecords } from "../../utils/Api";
import { formatDateString, getCountryNameLabel, getCountryFlagCode, igToLocale } from "../../utils/utils";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DutySaverHistoryStripe from "./DutySaverHistoryStripe";
import DisclaimerStrip from "../../components/Disclaimer/DisclaimerStripe";



const DutySaverProHistoryTable = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [countryList, setCountryList] = useState([]);
    const [landedCosts, setLandedCosts] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [recordDetails, setRecordDetails] = useState(null);
    const [dutySaverProData, setDutySaverProData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const currencyConvertedValue = {
        color: colors.primaryColor[300],
        fontSize: "0.75rem",
        fontWeight: "600"
    }

    useEffect(() => {
        const isCountryInStorage = JSON.parse(window.localStorage.getItem("")) || fetchCountry()
        setCountryList(isCountryInStorage);
    }, []);

    useEffect(() => {
        const getSaverHistory = async () => {
            const result = await fetchDutySaverHistoryRecords(page, pageSize);

            const formattedData = result && result.map((attr, index) => {
                const { response } = attr
                const { userLog } = attr
                const imp_cyn = response?.imp_cyn
                const cyn = response?.cyn

                const hs_code = response.hscode;
                const date = formatDateString(attr.iso_date);
                const id = attr.id;
                const export_country = getCountryNameLabel(response.export_country);
                const import_country = getCountryNameLabel(response.import_country);
                const value_of_product = response.CIFVALUE;
                const value_of_product_original = igToLocale(value_of_product || 0, imp_cyn, imp_cyn);
                const value_of_product_converted = igToLocale(response?.value_of_product_converted, cyn, cyn);


                const import_duty_original = igToLocale(response?.import_duty_original, imp_cyn, imp_cyn);
                const import_duty_converted = igToLocale(response?.import_duty_converted, cyn, cyn);

                const duty_saved_original = igToLocale(response?.save_upto, imp_cyn, imp_cyn);
                const duty_saved_converted = igToLocale(response?.duty_saved_converted, cyn, cyn);
                return { hs_code, id, date, export_country, import_country, value_of_product, import_duty_original, import_duty_converted, duty_saved_original, duty_saved_converted, value_of_product_original, value_of_product_converted };
            });
            setDutySaverProData(formattedData);
        }
        getSaverHistory();
    }, [page, pageSize]);

    const handleViewHistoryModal = async (id) => {
        const details = await fetchDutySaverHistoryRecordDetails(id);
        setRecordDetails(details);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setRecordDetails(null);
    };



    const renderCountryWithFlag = (params) => {
        const countryName = params.value;
        const countryCode = getCountryFlagCode(countryName);

        return (
            <Box display="flex" alignItems="center">
                <CountryFlag countryCode={countryCode} svg style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }} />
                <Typography sx={{ fontWeight: "600", fontSize: "0.75rem", lineHeight: "1.43" }}>
                    {countryName}
                </Typography>
            </Box>
        );
    };

    const renderImportDuty = (params) => {
        return (
            <Box>
                <Typography sx={currencyConvertedValue}>{params.row.import_duty_original}</Typography>
                <Typography sx={currencyConvertedValue}>{params.row.import_duty_converted}</Typography>
            </Box>
        );
    };

    const renderDutySaved = (params) => {
        return (
            <Box>
                <Typography sx={currencyConvertedValue}>{params.row.duty_saved_original}</Typography>
                <Typography sx={currencyConvertedValue}>{params.row.duty_saved_converted}</Typography>
            </Box>
        );
    };

    const renderProductValue = (params) => {
        return (
            <Box>
                <Typography sx={currencyConvertedValue}>{params.row.value_of_product_original}</Typography>
                <Typography sx={currencyConvertedValue}>{params.row.value_of_product_converted}</Typography>
            </Box>
        );
    };
    const columns = [
        { field: "date", headerName: "Date" },
        {
            field: "import_country",
            headerName: "Import Country",
            flex: 1,
            renderCell: renderCountryWithFlag,
        },
        {
            field: "export_country",
            headerName: "Export Country",
            flex: 1,
            renderCell: renderCountryWithFlag,
        },
        {
            field: "hs_code",
            headerName: "HS Code",
            flex: 1,
        },
        {
            field: "value_of_product",
            headerName: "Value Of Product",
            // align: "center",
            // headerAlign: "center",
            renderCell: renderProductValue,
            flex: 1,
        },
        {
            field: "import_duty",
            headerName: "Import Duty",
            // align: "center",
            // headerAlign: "center",
            renderCell: renderImportDuty,
            flex: 1,
        },
        {
            field: "duty_saved",
            headerName: "Duty Saved",
            // align: "center",
            // headerAlign: "center",
            renderCell: renderDutySaved,
            flex: 1,
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                return <div className={`status-cell`} onClick={() => handleViewHistoryModal(params.id)}>View</div>;
            },
        },
    ];

    const handleNextPage = () => {
        if (dutySaverProData.length === pageSize) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <Box
            height="90%"
            width="100%"
            padding="16px"

        >
            <Typography
                sx={{
                    backgroundColor: "#1994b7",
                    padding: "10px",
                    color: "#fff",
                    borderRadius: "7px",
                    margin: "20px 0",
                    fontSize: "18px",
                }}>DutySaver Pro Search History</Typography>

            <Box
                boxShadow={3}
                borderRadius={5}
                bgcolor="background.paper">
                <DataGrid
                    rows={dutySaverProData}
                    columns={columns}
                    disableSelectionOnClick
                    autoHeight
                    components={{ Toolbar: GridToolbar }}
                    sx={{
                        overflow: "auto",
                        "& .status-cell": {
                            padding: "5px",
                            borderRadius: "5px",
                            fontWeight: "bolder",
                            display: "inline-block",
                            cursor: "pointer",
                            color: colors.blueAccent[400],
                        },
                        "& .MuiDataGrid-root": {
                            border: "none",
                            boxShadow: 3,
                            borderRadius: 2,
                            minWidth: "1000px",
                        },
                        "& .MuiDataGrid-cellContent": {
                            fontWeight: "600"
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "800",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colors.table[200],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            display: "none",
                        },
                        "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.grey[100]} !important`,
                        },
                        "& .MuiDataGrid-container--top [role=row]": {
                            backgroundColor: `${colors.grey[500]} !important`,
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px">
                <IconButton onClick={handlePreviousPage} disabled={page === 1}>
                    <ArrowBackIosIcon />
                </IconButton>
                <Typography>Page {page}</Typography>
                <IconButton onClick={handleNextPage} disabled={dutySaverProData?.length < pageSize}>
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 1000,
                    height: "90vh",
                    overflow: "auto",
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: "7px",
                }}>
                
                    {recordDetails ? (
                        <Box id="modal-description" sx={{ mt: 2 }}>
                            <DutySaverHistoryStripe saveDutyResponse={recordDetails} />
                        </Box>
                    ) : (
                        <Typography id="modal-description" sx={{ mt: 2 }}>
                            Loading...
                        </Typography>
                    )}
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={handleCloseModal}
                            variant="contained"
                            sx={{
                                backgroundColor: "var(--intgrow-bg)",
                                color: "#fff",

                                "&:hover": {
                                    backgroundColor: "var(--intgrow-bg)"
                                }
                            }}>Close</Button>
                    </Box>
                </Box>
            </Modal>
            <Box>
                <DisclaimerStrip />
            </Box>
        </Box>
    );
};

export default DutySaverProHistoryTable;
