import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
    Table, TableBody, TableCell, TableContainer,
    TableRow, Typography, Paper
} from '@mui/material';
import { roundToNearestInteger, currencyConvert, formatNumberWithRegex, formatPrice } from '../../utils/utils';



const LeftCell = styled(TableCell)`
 font-size: 14px;
  font-weight: 600;
  width:50%;
  color:#797d8c
`;

const RightCell = styled(TableCell)`
  width:50%;
  font-size: 14px;
  font-weight: 500;
  border-left: 1px solid #dadada;
  color:#797d8c
`;

const DutyCalcShipmentSummary = ({ countryData, product}) => {
    const { exp_country, imp_country } = countryData;
    const { mode,cyn_rate, cyn, CIF, CIFVALUE,imp_cyn } = product;
    let cynRate = cyn_rate && cyn_rate;

    const { inco_term, originCharges, originFreight, exwIntFreight, cifIntFreight, fobIntFreight, exwInsuranceCharges, cifInsuranceCharges, fobInsuranceCharges } = product?.info
    let internationalFreight = exwIntFreight || cifIntFreight || fobIntFreight || 0;
    let insuranceCharge = exwInsuranceCharges || cifInsuranceCharges || fobInsuranceCharges || 0;
    let getdutyTotal = roundToNearestInteger(product.total + product.CIFVALUE);
    let cynConvertDutyTotal = currencyConvert(getdutyTotal, cynRate);
    let totalDuty = currencyConvert(product.total, cynRate);

    return (
        <TableContainer component={Paper} style={{ width: 'fit-content', margin: 'auto' }}>
            <Typography className='shipment-summary' align="center" gutterBottom>
                Your Shipment Summary
            </Typography>
            <Table>
                <TableBody>
                    <TableRow>
                        <LeftCell>Import country:</LeftCell>
                        <RightCell>{imp_country}</RightCell>
                    </TableRow>
                    <TableRow>
                        <LeftCell>Export Country:</LeftCell>
                        <RightCell>{exp_country}</RightCell>
                    </TableRow>
                    <TableRow>
                        <LeftCell>Mode of transport:</LeftCell>
                        <RightCell>{product?.mode}</RightCell>
                    </TableRow>
                    <TableRow>
                        <LeftCell>Import HSN:</LeftCell>
                        <RightCell>{product?.hscode}</RightCell>
                    </TableRow>
                    <TableRow>
                        <LeftCell>Currency:</LeftCell>
                        <RightCell>{cyn}</RightCell>
                    </TableRow>
                    <TableRow>
                        <LeftCell>Currency rate for 1 {cyn}:</LeftCell>
                        <RightCell>{cynRate} {imp_cyn?.toUpperCase()}</RightCell>
                    </TableRow>
                    <TableRow>
                        <LeftCell>CIF Value:</LeftCell>
                        <RightCell>{CIF} {cyn}</RightCell>
                    </TableRow>
                    <TableRow>
                        <LeftCell>Inco Term:</LeftCell>
                        <RightCell>{inco_term}</RightCell>
                    </TableRow>
                    {/* {(originCharges !== null && originCharges !== undefined && originCharges !== 0) && (
                        <TableRow>
                            <LeftCell>Origin Charges:</LeftCell>
                            <RightCell>{originCharges}</RightCell>
                        </TableRow>
                    )}
                    {(originFreight !== null && originFreight !== undefined && originFreight !== 0) && (
                        <TableRow>
                            <LeftCell>Origin Freight:</LeftCell>
                            <RightCell>{originFreight}</RightCell>
                        </TableRow>
                    )} */}
                    <TableRow>
                        <LeftCell>International Freight:</LeftCell>
                        <RightCell>{internationalFreight}</RightCell>
                    </TableRow>
                    <TableRow>
                        <LeftCell>Insurance Charges:</LeftCell>
                        <RightCell>{insuranceCharge}</RightCell>
                    </TableRow>
                    {product?.extras && product?.extras.length > 0 && (
                        product.extras.map(extra => (
                            <TableRow key={extra.key}>
                                <LeftCell>{extra?.lbl || extra?.key}</LeftCell>
                                <RightCell>{extra?.txt || extra?.val}</RightCell>
                            </TableRow>
                        ))
                    )}
                    <TableRow>
                        <LeftCell>Total payable duties and taxes:</LeftCell>
                        <RightCell>{formatPrice(product?.total)} {(product?.imp_cyn.toUpperCase())}</RightCell>
                    </TableRow>
                    <TableRow>
                        <LeftCell>Total Landed cost:</LeftCell>
                        <RightCell>{cynConvertDutyTotal} {cyn}</RightCell>
                    </TableRow>
                    <TableRow>
                        <LeftCell>HSN Description:</LeftCell>
                        <RightCell>{product.des}</RightCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DutyCalcShipmentSummary;
