import React, { useState } from 'react';
import { Button,useTheme, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const DisplayOriginRules = ({ ftaIds, rulesResponse, inputData, countryListResponse }) => {
    const [expandedNotes, setExpandedNotes] = useState({});
    const theme = useTheme()
    const mode = theme.palette.mode
    const handleReadMore = (index) => {
        setExpandedNotes((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    function getCountryId(countryName, returnVal = 'value') {
        var obj = countryListResponse && countryListResponse.find(o => o.label === countryName || o.value === countryName);
        return obj && obj[returnVal];
    }

    function getRTACountry(countryList) {
        if (countryList) {
            const countryArray = countryList.replace(/\s+/g, '').split(',');
            let retVal = [];
            countryArray.forEach(c => {
                retVal.push(getCountryId(c, 'label'));
            });
            return retVal.join(', ');
        }
        return '';
    }


    return (
        <div>
            {rulesResponse && rulesResponse.length > 0 &&
                rulesResponse.map((r, index) => {
                    let rule = ftaIds.find(f => f === r.rule);
                    const truncatedNote = r.note && r.note.length > 110 ? `${r.note.substring(0, 110)}...` : r.note;

                    if (r && r.criteria && r.criteria.toLowerCase() !== 'not applicable' && rule) {
                        const countries = getRTACountry(r.countries);
                        return (
                            <div key={index} className='origin-rules'>
                                <Table className='roo-table-data'>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: "var(--intgrow-bg)" }}>
                                            <TableCell colSpan={2} sx={{ color: "#fff", borderRadius: "7px 7px 0 0", backgroundColor: "#1994b7" }}>Rules Of Origin</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><span className="rules-label">RoO Criteria</span></TableCell>
                                            <TableCell>{r.criteria}</TableCell>
                                        </TableRow>
                                        {r.doc !== '#' &&
                                            <TableRow>
                                                <TableCell><span className="rules-label">Download</span></TableCell>
                                                <TableCell className="overflow-text">
                                                    <a href={r.doc}>
                                                        <img className='pdf-icon' src='/assets/logos/pdf-icon.png' alt='pdf link' width={20} />
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                        }
                                        {countries &&
                                            <TableRow>
                                                <TableCell><span className="rules-label">RoO Countries</span></TableCell>
                                                <TableCell>{countries}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                                {r.note && (
                                    <div className='rules-note'>
                                        <Tooltip title={r.note}>
                                            <InfoIcon />
                                        </Tooltip>
                                        Note:
                                        <span
                                            dangerouslySetInnerHTML={{ __html: expandedNotes[index] ? r.note : truncatedNote }}
                                            className='rules-note-desc'
                                        />
                                        {r.note.length > 110 && (
                                            <Button onClick={() => handleReadMore(index)} className='read-more-btn'
                                                sx={{
                                                    color:mode==="dark"?"#fff":"#000"
                                                }}
                                            >
                                                {expandedNotes[index] ? 'Read Less' : 'Read More'}
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    }
                    return null;
                })
            }
        </div>
    );
};

export default DisplayOriginRules;
