import React from 'react'
import styles from './ContactUs.module.scss'
import { Box, Grid, Typography } from '@mui/material'
import Header from '../../components/header/Header'
import logo from '../../assest/Intgrow Logo Transpernt.png'
import CommonBanner from '../../components/CommonBanner/CommonBanner'
import { MdEmail } from "react-icons/md";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import ContactForm from '../../components/ContactForm/ContactForm'
import Footer from '../../components/footer/Footer'
import SimpleCurcle from '../../components/SimpleCurcle/SimpleCurcle'
import { Helmet } from 'react-helmet';


const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us | IntGrow Corp.</title>
        <meta property="og:title" content="Contact Us" />
        <meta property="og:url" content="https://alpha.intgrow.co/contact-us" />
        <meta property="og:type" content="information" />
        <meta property="og:description" content="what we serve" />
        {/* <meta property="og:image" content={pageImage} /> */}
        <meta name="keywords" content="HS Code, duty calculator, duty saver " />
      </Helmet>
      <Grid className={styles.contactUsContainer}>
        <Header
          logo={logo}
        />
        <CommonBanner
          heading={"Contact Us"}
          subHeading={"Have questions about intgrow or our services? Contact us and our team will respond promptly."}
        />
        <Grid container className={styles.contactUs}>
          <Grid item sm={7}>
            <Typography variant='h4' fontSize={28} fontWeight={800}
              sx={{
                backgroundColor: "var(--intgrow-primary-color)",
                padding: "5px",
                color: "#fff",
                borderRadius: "7px",
              }}>CONTACT INFORMATION</Typography>
            <Box marginBlockStart={2.5} marginBlockEnd={1} lineHeight={1.8} fontSize={15}
              textAlign={"justify"}>
              <Typography>
                Revolutionize your import/export strategies. Whether you’re an exporter, importer, consultant, shipping agent, or a business planning to expand, our tool saves you money and boosts profits.
              </Typography>

              <Typography variant='h4' mt={2} fontWeight={800}>
                Contact Us Today!
              </Typography>

              <ul>
                <li><strong>Expert Guidance:</strong> Tailored advice on tax savings.</li>
                <li><strong>Personalized Support:</strong> Exceptional customer service.</li>
                <li><strong>Innovative Solutions:</strong> Smart management of import taxes.</li>
                <li><strong>Easy Integration:</strong> Powerful APIs for seamless connectivity.</li>
              </ul>
              <Typography>
                Empower your business with smart sourcing strategies. Partner with us for a profitable future.
              </Typography>

              <Typography>
                Now Featuring Seamless API Integration! Perfect for SMEs, data companies, and business platforms.
              </Typography>
            </Box>

            <Box display={"flex"} marginBlock={2}>
              <MdEmail fontSize={21} marginBlock={"auto"} />
              <Typography marginBlock={"auto"} marginInlineStart={1} fontWeight={600} fontSize={16}>Email: <span style={{ fontWeight: 500 }}>support@intgrow.co</span></Typography>
            </Box>

            <Box>
              <FaFacebookF fontSize={25} />
              <FaInstagram fontSize={25} style={{ marginInline: 14 }} />
              <FaXTwitter fontSize={25} />
              <FaLinkedinIn fontSize={25} style={{ marginInline: 14 }} />
            </Box>
            <SimpleCurcle
              size={300}
              border={185}
            />
            <SimpleCurcle
              size={150}
              border={30}
            />
          </Grid>
          <Grid item sm={4}>
            <ContactForm />
          </Grid>
        </Grid>
        <Footer />

      </Grid>
    </>
  )
}

export default ContactUs;