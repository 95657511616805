import React from 'react';
import './Glossary.css'; 
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import { IoIosArrowDown } from "react-icons/io";

const GlossaryAccordionSection = ({ glossaryItems, heading }) => {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Grid className="accordionContainer">
            <Grid>
                {glossaryItems && glossaryItems.map((item, index) => (
                    <Accordion key={item.id} expanded={expanded ===item.id} onChange={handleChange(item.id)}>
                        <AccordionSummary
                            expandIcon={<IoIosArrowDown fontSize={21} style={{ color: "#0C98BC" }} />}
                        >
                            <Typography variant='h5' fontSize={15} fontWeight={500}>
                                {item?.subheading}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography fontSize={15} sx={{
                                    padding: "0 10px",
                            }}>
                                {item?.definition}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}

            </Grid>
        </Grid>
    );
}

export default GlossaryAccordionSection;
