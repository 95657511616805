import React from 'react'
import { Grid, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import './history.css'
import styled from '@emotion/styled';


const RightCell = styled(TableCell)`
  font-size: 14px;
  font-weight: 500;
  border-left: 1px solid #dadada;
  color:#797d8c
`;
const LeftCell = styled(TableCell)`
  font-size: 14px;
  font-weight: 500;
  color:#797d8c
`;
const HSCodeHistoryModel = ({ product, countryData }) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={6} className="import">
                <Typography textAlign={"left"} variant='h4' fontSize={20} marginBlockEnd={2} fontWeight={700} margin={0} borderBottom={"1px solid #d8d8d8"} padding={"10px 20px"}>
                    HS Code of {countryData?.imp_country}
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow style={{ backgroundColor: 'var(--intgrow-bg)' }}>
                                <TableCell style={{ color: '#fff' }}>HS Code</TableCell>
                                <TableCell style={{ color: '#fff',borderRight:"1px solid #dadada" }}>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {product?.import?.map((item, index) => (
                                <TableRow key={index}>
                                    <LeftCell>{item.value}</LeftCell>
                                    <RightCell>{item.label}</RightCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} md={6} className="export">
                <Typography textAlign={"left"} variant='h4' fontSize={20} marginBlockEnd={2} fontWeight={700} margin={0} borderBottom={"1px solid #d8d8d8"} padding={"10px 20px"}>
                    HS Code of {countryData?.exp_country}
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow style={{ backgroundColor: 'var(--intgrow-bg)' }}>
                                <TableCell style={{ color: '#fff', }}>HS Code</TableCell>
                                <TableCell style={{ color: '#fff',borderRight:"1px solid #dadada" }}>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {product?.export?.map((item, index) => (
                                <TableRow key={index}>
                                    <LeftCell>{item.value}</LeftCell>
                                    <RightCell>{item.label}</RightCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export default HSCodeHistoryModel
