import React, { useState } from 'react';
import '../outletform/bloglist.css'; // Make sure to include this CSS file

const Bloglist = () => {
  // Sample data
  const blogData = [
    { id: 1, title: 'First Blog Post', createdAt: '2024-07-30' },
    { id: 2, title: 'Second Blog Post', createdAt: '2024-07-31' },
    { id: 3, title: 'Third Blog Post', createdAt: '2024-08-01' },
    { id: 4, title: 'Fourth Blog Post', createdAt: '2024-08-02' },
  ];

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to first page on rows per page change
  };

  const handlePageChange = (e) => {
    setCurrentPage(parseInt(e.target.value, 10));
  };

  const totalPages = Math.ceil(blogData.length / rowsPerPage);
  const displayedData = blogData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  return (
    <div className="bloglist-container">
      <h1>All Blog List</h1>
      <hr className="divider" />
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Blog Title</th>
              <th>Created At</th>
              <th>Actions</th>
            
            </tr>
          </thead>
          <tbody>
            {displayedData.map((blog, index) => (
              <tr key={blog.id}>
                <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                <td>{blog.title}</td>
                <td>{blog.createdAt}</td>
                <td className="action-buttons">
                  <button className="btn update-btn">Update</button>
                  <button className="btn view-btn">View</button>
                  <button className="btn delete-btn">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-controls">
        <div className="rows-per-page">
          <label htmlFor="rows-per-page">Rows per page:</label>
          <select id="rows-per-page" value={rowsPerPage} onChange={handleRowsPerPageChange}>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
          </select>
        </div>
      </div>
      <div className="pagination-top-right">
        <span className="pagination-text">Showing {(currentPage - 1) * rowsPerPage + 1} to {Math.min(currentPage * rowsPerPage, blogData.length)} of {blogData.length} entries</span>
        <button className="pagination-button">Previous</button>
        <button className="pagination-button">Next</button>
      </div>
    </div>
  );
};

export default Bloglist;
