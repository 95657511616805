import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { baseUrl } from '../utils/baseURL';
import { fetchCountry } from '../utils/Api';

const LoginWithLinkedIn = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.code) {
            handleSuccess({ code: location.state.code });
        }
    }, [location.state]);

    const handleSuccess = async (response) => {
        try {
            const credential = response.code;
            const redirectUri = `${window.location.origin}/linkedin`;

            const accessTokenResponse = await fetch(`${baseUrl}/auth/linkedin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    code: credential,
                    redirectUri,
                }),
            });

            const { userData } = await accessTokenResponse.json();

            const userDetails = {
                email: userData.email,
                first_name: userData.given_name,
                last_name: userData.family_name,
                provider: 'LINKEDIN',
                photo_url: userData.picture,
            };

            const backendResponse = await fetch(`${baseUrl}/auth/loginIn/withSocialMedia`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userDetails),
            });

            const responseData = await backendResponse.json();

            if (backendResponse.ok) {
                window.localStorage.setItem("isAuthenticated", true);
                window.localStorage.setItem('access_token', responseData.result.token);
                window.localStorage.setItem("userInfo", JSON.stringify(responseData?.result));

                await fetchCountry();

                const continueUrl = localStorage.getItem("continueUrl");
                if (continueUrl) {
                    localStorage.removeItem("continueUrl");
                    if (window.opener) {
                        window.opener.location.href = continueUrl;
                    } else {
                        navigate(continueUrl);
                    }
                } else {
                    if (window.opener) {
                        window.opener.location.href = "/dashboard";
                    } else {
                        navigate("/dashboard");
                    }
                }

                // Close the popup window after login
                if (window.opener) {
                    window.close();
                }
            } else {
                console.error('Error in login:', responseData);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleError = (error) => {
        console.log('Login Failed:', error);
    };

    return (
        <LinkedIn
            clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
            redirectUri={`${window.location.origin}/linkedin`}
            onSuccess={handleSuccess}
            onError={handleError}
            scope={'openid profile email'}
        >
            {({ linkedInLogin }) => (
                <button onClick={linkedInLogin} className="linkedIn-login-btn">
                    <img src="/assets/logos/linkedIn.png" alt="linkedin-login" />
                </button>
            )}
        </LinkedIn>
    );
};

export default LoginWithLinkedIn;
