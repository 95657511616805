import React, { createContext, useState } from 'react';

export const HsCodeContext = createContext();

export const HsCodeProvider = ({ children }) => {
  const [hsCodeDetails, setHsCodeDetails] = useState({
    importingCountry: '',
    exportingCountry: '',
    hsCode: '',
   productValue:"",
   selectedCurrency:""
  });

  return (
    <HsCodeContext.Provider value={{ hsCodeDetails, setHsCodeDetails }}>
      {children}
    </HsCodeContext.Provider>
  );
};
