import React from 'react';
import { Helmet } from 'react-helmet';
import '../Disclaimer/disclaimer.css';
import Footer from '../../../footer/Footer'
import Header from '../../../header/Header';
import logo from '../../../../assest/Intgrow_logo.png'
import logo_1 from '../../../../assest/Intgrow Logo Transpernt.png'
import { Box } from '@mui/material';
import Subscribe from '../../../Subscribe/Subscribe';
const Disclaimer = () => {


  const handleSubscribe = () => {

  }
  return (
    <>
      <Helmet>
        <title>Disclaimer | IntGrow Corp.</title>
        <meta property="og:title" content="Dashboard" />
        <meta property="og:url" content="https://alpha.intgrow.co/disclaimer" />
        <meta property="og:type" content="information" />
        <meta property="og:description" content="what we serve" />
        <meta name="keywords" content="HS Code, duty calculator, duty saver " />
      </Helmet>
      <Box sx={{ backgroundColor: "var(--intgrow-primary-color)",
        position: "sticky",
        top: 0,
       }}>
        <Header logo={logo}
          logo_1={logo_1}
          color={"#ffffff"} />
      </Box>

      <div className='main-container'>
        <img className='banner' src="/assets/images/disclaimer.png" alt="Banner" h />
      </div>
      <div className='paragraph'>
        <p className="disclaimer-desc-info">
          The information contained on this website INTGROW.CO is for general information purposes only. The INTGROW.CO assumes no responsibility for errors or omissions in the contents of the website. In no event shall the INTGROW.CO be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other torts, arising out of or in connection with the use of the website or the contents of the website. The INTGROW.CO reserves the right to make additions, deletions, or modifications to the contents on the website at any time without prior notice. Every effort is made to keep the website up and running smoothly. However, the INTGROW.CO takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control. The INTGROW.CO does not warrant that the website is free of viruses or other harmful components.
        </p>
      </div>

      <Subscribe />
      <div style={{ marginTop: "20px" }}>
        <Footer />
      </div>
    </>
  );
}

export default Disclaimer;


{/* <div className="footer">
<div className='footerheadingcontainer'>
  <h1 className='headingfooter'>Subscribe for your free <br /> Account</h1>
  <p className='footerparagraph'>Get Access to free calculations and HS code lookups. no credit card needed</p>
  <Box sx={{display:"flex",justifyContent:"center"}} onClick={handleSubscribe}>
    <input type='email' placeholder='Enter Your Email' className='subscribe-input-field'></input>
  </Box>
  <button className='footerbtn'>Register Now</button>
</div>
</div> */}