import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, Modal, Snackbar } from '@mui/material';
import axios from 'axios';
import { baseUrl } from '../utils/baseURL';

const GenerateApiKeyForm = ({ onApiKeyGenerated }) => {
    const [projectName, setProjectName] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const token = window.localStorage.getItem("access_token");

        try {
            const response = await fetch(`${baseUrl}/generate-developer-api-key`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "x-api-key": token
                },
                body: JSON.stringify({ projectName }),
            });

            if (!response.ok) {
                throw new Error('Error generating API key');
            }

            const data = await response.json();
            setApiKey(data.apiKey);
            setProjectName("")
            setModalOpen(true); 
        } catch (error) {
            console.error(error.message || 'Error generating API key');
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        onApiKeyGenerated()
    };

    const handleCopyApiKey = () => {
        navigator.clipboard.writeText(apiKey);
        setSnackbarOpen(true); // Show snackbar notification when copied
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h5" gutterBottom>
                Generate API Key
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Project Name"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                />
                <Button type="submit" fullWidth variant="contained" color="primary"
                    sx={{
                        backgroundColor: "var(--intgrow-bg)",
                        "&:hover": {
                            backgroundColor: "var(--intgrow-bg)"
                        }
                    }}>
                    Generate API Key
                </Button>
            </Box>

            <Modal open={modalOpen} onClose={handleCloseModal}>
                <Box sx={{
                    p: 4, borderRadius: 2, maxWidth: 800,
                    backgroundColor:"#fff",
                    minWidth: 300,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    overflow: "auto",
                    height: "auto"
                }}>
                    <Typography variant="h6" gutterBottom sx={{
                         backgroundColor: "var(--intgrow-bg)",
                         color:"#fff",
                         padding:"5px",
                         borderRadius:"7px"
                    }}>
                        Copy and Save Your API Key
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        Once you close this modal, you will not be able to view your API key again.
                    </Typography>
                    <TextField
                        fullWidth
                        label="API Key"
                        variant="outlined"
                        value={apiKey}
                        InputProps={{
                            readOnly: true,
                        }}
                        sx={{ mb: 2 }}
                    />
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Button onClick={handleCopyApiKey} variant="contained"
                            sx={{
                                backgroundColor: "var(--intgrow-bg)",
                                "&:hover": {
                                    backgroundColor: "var(--intgrow-bg)"
                                }
                            }}>
                            Copy API Key
                        </Button>
                        <Button onClick={handleCloseModal} variant="contained"
                            sx={{
                                backgroundColor: "var(--intgrow-bg)",
                                "&:hover": {
                                    backgroundColor: "var(--intgrow-bg)"
                                }
                            }}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message="API Key copied to clipboard"
            />
        </Container>
    );
};

export default GenerateApiKeyForm;
