import { Grid, Typography } from '@mui/material'
import React from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import styles from './Cookie.module.scss'
import CommonBanner from '../../components/CommonBanner/CommonBanner'
import logo from '../../assest/Intgrow Logo Transpernt.png'
import logo_1 from '../../assest/Intgrow_logo.png'


const Cookie = () => {
    return (
        <Grid className={styles.cookieContainer}>
            <Header
                logo={logo}
                logo_1={logo}
            />
            <CommonBanner heading={"Data Privacy Policy"} />
            <Grid className={styles.cookie}>
                <Typography variant='h4' fontSize={21} fontWeight={600}>1. DEFINITION OF A COOKIE</Typography>
                <Typography>A cookie is a text file that can be stored in a dedicated area of the hard disk of your device* when you consult an online service by means of your browsing software. A cookie file allows the issuer to identify the device in which it is stored during the period of validity or storage of that cookie.</Typography>
                <Typography>* a device designates the hardware (computer, tablet, smartphone, etc. ) that you use to consult or view a website, an application, advertising content, etc.</Typography>
                <Typography variant='h4' fontSize={21} fontWeight={600}>2. THE DIFFERENT TYPES OF COOKIES USED ON MAURITIUS TRADE EASY </Typography>
                <ul>
                    <li><Typography variant='h5' fontSize={18} fontWeight={600}>Cookies that are strictly necessary for the operation of the site</Typography>
                        <Typography> These are the cookies that are essential to browse our site (such as session identifiers), which allow you to use the main features of the site and secure your connection. For example, they permit you to access directly the reserved and personal spaces of our site, thanks to identifiers or data that you may have entrusted to us previously. Without these cookies you will not be able to use the site normally. We recommend that you do not delete them.</Typography></li>
                    <li> <Typography variant='h5' fontSize={18} fontWeight={600}>Functionality cookies</Typography>
                        <Typography> These cookies are not essential to browse our site but allow you to optimize its operation and give you access to specific features. They also allow you to adapt the presentation of our site to your display preferences for your device. These cookies also enable fluid and customized browsing.</Typography></li>
                    <li>
                        <Typography variant='h5' fontSize={18} fontWeight={600}>Analytical cookies</Typography>
                        <Typography> These are cookies that allow us to learn more about the use and performance of our site, to establish statistics and volumes of frequentation and use of various elements of our site (content visited, routing). This enables us to improve the interest and the ergonomics of our services (the most frequently consulted pages or topics, the most read articles, etc.).</Typography>
                    </li>
                </ul>

                <Typography variant='h4' fontSize={21} fontWeight={600}>3. THIRD-PARTY COOKIES </Typography>
                <Typography>The issue and use of cookies by third parties are subject to the "cookies" policy of those third parties. We inform you of the purpose of recognized cookies, and of the means available to make choices concerning them.</Typography>
                <Typography variant='h4' fontSize={21} fontWeight={600}>4. MODALITIES OF REFUSAL/DELETION OF COOKIES</Typography>
                <Typography>The storage of a cookie on a device essentially depends on the choices of the user. The user can express and modify these choices at any time without cost.</Typography>
                <Typography>If you have chosen to allow your browsing software to enable cookies on your device, the cookies that are embedded in the pages and content that you have consulted may be stored temporarily in a dedicated space on your device.</Typography>
                <Typography>If you refuse the storage of cookies on your device, or if you delete those that are stored there, you will not be able to use a certain number of the features of our site.</Typography>
                <Typography>Most browsers accept cookies by default. However, you can decide to block these cookies or ask your browser to notify you when a site attempts to enable a cookie on your device. To change the cookie management of your browser, you can change the settings in the Privacy tab.</Typography>
                <Typography>Cookie management and the choices of configuration are different for each browser. This is described in the Help menu of your browser, which will inform you of how to change your choices for cookies:</Typography>
                <Typography variant='h5' fontSize={18} fontWeight={600}>Your browser's settings</Typography>
                <ul>
                    <li>Internet Explorer : <span style={{ cursor: "pointer", color: "#0088AF" }} onClick={() => window.location.href = "http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies"}>http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies</span>
                    </li>
                    <li>Firefox : <span style={{ cursor: "pointer", color: "#0088AF" }} onClick={() => window.location.href = "https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"}>https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</span>
                    </li>
                    <li>Safari : <span style={{ cursor: "pointer", color: "#0088AF" }} onClick={() => window.location.href = "http://www.apple.com/legal/privacy/en-ww/cookies"}>http://www.apple.com/legal/privacy/en-ww/cookies</span>
                    </li>
                    <li>Internet Explorer : <span style={{ cursor: "pointer", color: "#0088AF" }} onClick={() => window.location.href = "http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies"}>http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies</span>
                    </li>
                    <li>Google Chrome : <span style={{ cursor: "pointer", color: "#0088AF" }} onClick={() => window.location.href = "https://support.google.com/chrome/answer/95647?hl=en&hlrm=en"}>https://support.google.com/chrome/answer/95647?hl=en&hlrm=en</span>
                    </li>
                </ul>
                <Typography variant='h5' fontSize={18} fontWeight={600}>Opt-out links for analytical cookies</Typography>
                <Typography>You can obtain more information about the statistics cookies of Google Analytics, and in particular how to disable them, by following this link: <span style={{ cursor: "pointer", color: "#0088AF" }} onClick={() => window.location.href = "https://tools.google.com/dlpage/gaoptout?hl=en"}>https://tools.google.com/dlpage/gaoptout?hl=en</span></Typography>
                <Typography variant='h5' fontSize={18} fontWeight={600}>The use of a cookie management platform for sharing and advertising cookies</Typography>
                <Typography>You will find more information concerning behavioural advertising and the protection of privacy on the internet at this address:  <span style={{ cursor: "pointer", color: "#0088AF" }} onClick={() => window.location.href = "https://www.youronlinechoices.com/"}>www.youronlinechoices.com</span>. You will find here a list of the companies that offer you the possibility to refuse or accept their cookies in order to adapt the advertising that may be displayed on your device to your browsing information.</Typography>
                <Typography>Cookies stored when sharing buttons are used are also included in this platform.</Typography>
                <Typography>Be careful! A cookie is necessary to take your choices into account. If you delete all the cookies stored in your device (via your browser), neither we nor our service providers will know that you have chosen this option.</Typography>

            </Grid>
            <Footer />
        </Grid>
    )
}

export default Cookie