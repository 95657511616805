import React from 'react'
import styles from './InputField.module.scss'
import { Grid, TextField, Typography } from '@mui/material'

const InputField = ({ label, name, placeholder, onChange, value, disabled, type }) => {
    // const theme = useTheme();
    return (
        <Grid className={styles.textFieldContainer}>
            <Typography>{label}</Typography>
            <TextField name={name} placeholder={placeholder} onChange={onChange} value={value} disabled={disabled} type={type}
                // style={{
                //     backgroundColor: theme.palette.mode === "dark" ? "#273142" : "#fbfbfb",
                //     color: theme.palette.mode === "dark" ? "#fff" : "#000",
                //     borderRadius:"7px"
                // }}
                 />
        </Grid>
    )
}

export default InputField