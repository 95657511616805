import React, { useState } from 'react';
import { Modal, useTheme, Box, Typography, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import { tokens } from '../../theme'

const CustomModal = ({ open, onClose, attr, selectedValue, handleSelectChange }) => {
    const theme = useTheme()
    const mode = theme.palette.mode
    const colors = tokens(mode)

    const [value, setValue] = useState(selectedValue);

    const handleRadioChange = (event) => {
        setValue(event.target.value);
    };

    const handleSave = () => {
        const selectedLabel = attr.values.find(v => v.value === value)?.label || '';
        handleSelectChange(attr, value, selectedLabel);
        onClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                p: 4, backgroundColor: colors.primary[400], borderRadius: 2, maxWidth: 800,
                minWidth:300,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                overflow: "auto",
                height:"450px"
            }}>
                <Typography variant="h6"
                    sx={{
                        backgroundColor: "#0088af",
                        color: "#fff",
                        padding: "10px",
                        borderRadius: "7px",
                    }}
                >{attr.label}</Typography>
                <RadioGroup value={value} onChange={handleRadioChange}>
                    {attr.values.map((v, i) => (
                        <FormControlLabel key={i} value={v.value} control={<Radio />} label={v.label} sx={{ padding: "5px 0" }} />
                    ))}
                </RadioGroup>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={handleSave} variant="contained"
                        sx={{
                            backgroundColor: "var(--intgrow-bg)",
                            "&:hover": {
                                backgroundColor: "var(--intgrow-bg)"
                            }
                        }}>Save</Button>
                </Box>

            </Box>
        </Modal >
    );
};

export default CustomModal;
