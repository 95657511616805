import React from 'react';
import {Box } from '@mui/material';
import Header from '../../components/header/Header';
import logo_1 from '../../assest/Intgrow Logo Transpernt.png'
import logo from '../../assest/Intgrow_logo.png'

const Welcome = () => {
  return (
    <div>
     <Box sx={{backgroundColor:"var(--intgrow-primary-color)"}}>
                <Header logo={logo}
                    logo_1={logo_1}
                    color="#fff"
                />
            </Box>
      <iframe
        src="/welcome/welcome.html"
        style={{ width: '100%', height: '100vh', border: 'none' }}
        title="Welcome"
      />
    </div>

  );
};

export default Welcome;
