import React from 'react'
import VerifyOtpAndRecaptcha from '../../components/auth/VerifyOtpAndRecaptcha'
import Header from '../../components/header/Header'
import logo from '../../assest/Intgrow Logo Transpernt.png'
import logo_1 from '../../assest/Intgrow_logo.png'

import img from '../../assest/otpVarifycation.png'
import img_1 from '../../assest/Ellipse 1.png'
import img_2 from '../../assest/Ellipse 2.png'
import Footer from '../../components/footer/Footer'
import styles from './OTPVarification.module.scss'
import { Grid, Box, Typography } from "@mui/material"
import { Helmet } from 'react-helmet';

const OTPVerification = () => {

    return (
        <>
            <Helmet>
                <title>OTP Verification | IntGrow Corp.</title>
            </Helmet>
            <Grid className={styles.forgotPasswordContainer}>
                <Header
                    logo={logo}

                />
                <Grid container className={styles.forgotPassword}>
                    <Grid item sm={6} className={styles.leftSide}>
                        <Typography marginBlockEnd={2.5} variant='h5' fontWeight={600} fontSize={25} sx={{ color: "#FD8406" }}>Forgot Password</Typography>
                        <img src={logo_1} />
                        <Box>
                            <img src={img} alt="otp"/>
                        </Box>
                    </Grid>
                    <Grid item sm={6} className={styles.rightSide}>
                        <VerifyOtpAndRecaptcha />
                    </Grid>
                </Grid>
                <Grid>
                    <img src={img_1} alt='layou'/>
                    <img src={img_2} alt="layout"/>
                </Grid>
                <Footer />
            </Grid>
        </>
    )
}
export default OTPVerification
