import { Grid, List, ListItemButton, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Header from '../header/Header';
import logo from '../../assest/Intgrow Logo Transpernt.png';
import CommonBanner from '../CommonBanner/CommonBanner';
import styles from './Glossary.module.scss';
import Footer from '../footer/Footer';
import GlossaryAccordionSection from './GlossaryAccordians';
import { baseUrl } from '../../utils/baseURL';

const filterGlossaryByHeading = (glossary) => {
    const groupedGlossary = {};
    glossary.forEach(item => {
        if (!groupedGlossary[item.heading]) {
            groupedGlossary[item.heading] = [];
        }
        groupedGlossary[item.heading].push(item);
    });
    return groupedGlossary;
};

const Glossary = () => {
    const [selectedHeading, setSelectedHeading] = useState(null);
    const [glossaryList, setGlossaryList] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Handle menu selection
    const handleMenu = (heading) => {
        setSelectedHeading(heading);
    };

    // Fetch glossary data
    const fetchData = async () => {
        const token = window.localStorage.getItem('access_token');
        try {
            const response = await fetch(`${baseUrl}/glossary/list`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': token
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const filteredGlossary = filterGlossaryByHeading(data.glossary);
            setGlossaryList(filteredGlossary);
            setSelectedHeading(Object.keys(filteredGlossary)[0]);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Grid className={styles.glossaryContainer}>
            <Header
                logo={logo}
                logo_1={logo}
            />
            <CommonBanner heading={"Glossary"} />
            <Grid container className={styles.glossary}>
                <Grid item sm={3}>
                    <List>
                        {Object.keys(glossaryList).map((heading, index) => (
                            <ListItemButton
                                key={index}
                                onClick={() => handleMenu(heading)}
                                className={selectedHeading === heading ? `${styles.normal}` : `${styles.custom}`}
                            >
                                <ListItemText>{heading}</ListItemText>
                            </ListItemButton>
                        ))}
                    </List>
                </Grid>
                <Grid item sm={9}>
                    {selectedHeading && glossaryList[selectedHeading] && (
                        <GlossaryAccordionSection
                            heading={selectedHeading}
                            glossaryItems={glossaryList[selectedHeading]}
                        />
                    )}
                </Grid>
            </Grid>
            <Footer />
        </Grid>
    );
};

export default Glossary;
