import React from 'react'
import DutySaverProHistoryTable from '../../../adminPanel/History/DutySaverHistoryTable'
import { Helmet } from 'react-helmet';

const DutySaverProHistory = () => {
    return (
        <div style={{height:"90vh"}}>
            <Helmet>
                <title>Duty Saver History | IntGrow Corp.</title>
                <meta property="og:title" content="Dashboard" />
                <meta property="og:url" content="https://alpha.intgrow.co/dashboard" />
                <meta property="og:type" content="information" />
                <meta property="og:description" content="what we serve" />
                <meta name="keywords" content="HS Code, duty calculator, duty saver " />
            </Helmet>
            <DutySaverProHistoryTable />
        </div>
    )
}

export default DutySaverProHistory
