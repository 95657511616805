import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, TextField, Modal, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import { baseUrl } from '../../../utils/baseURL';

const MediaList = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = tokens(mode);

  const [mediaList, setMediaList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [mediaDetails, setMediaDetails] = useState({});

  const fetchMediaData = async () => {
    const token = window.localStorage.getItem('access_token');
    try {
      const response = await fetch(`${baseUrl}/uploaded-media-list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "x-api-key": token
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setMediaList(data?.media || []);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMediaData();
  }, []);

  const handleDelete = async (id) => {
    const token = window.localStorage.getItem('access_token');
    try {
      const response = await fetch(`${baseUrl}/delete/uploaded-media/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          "x-api-key": token
        },
      });
      if (response.ok) {
        fetchMediaData(); // Reload the media list after deleting
      } else {
        console.error('Failed to delete media');
      }
    } catch (error) {
      console.error('Failed to delete media', error);
    }
  };

  const handleChangePage = (newPage) => setCurrentPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const currentItems = mediaList.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="view-section-container">
      <h1>Media List</h1>
      <div className="media-container" style={{
        backgroundColor: colors.primary[400],
      }}>
        <div className="media-table-wrapper">
          <table className="media-table">
            <thead>
              <tr>
                <th>S.no.</th>
                <th>Title</th>
                <th>Description</th>
                <th>Media Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={item.id} style={{ borderBottom: '1px solid #dadada' }}>
                  <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                  <td>{item.title}</td>
                  <td>{item.description}</td>
                  <td>{item.media_type}</td>
                  <td style={{ display: "flex" }}>
                    <button className="view-button" onClick={() => handleDelete(item.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="pagination">
        <div className="rows-per-page">
          <span>
            Rows per page:
            <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
              <option value={3}>3</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
            </select>
          </span>
        </div>
        <div className="pagination-buttons">
          <button
            className="previous-button"
            onClick={() => handleChangePage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className="next-button"
            onClick={() => handleChangePage(currentPage + 1)}
            disabled={currentPage * rowsPerPage >= mediaList.length}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default MediaList;
