import { Box, Typography, Modal, Button, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import CountryFlag from 'react-country-flag';
import { useEffect, useState } from "react";
import { fetchCountry, fetchDutySaverHistoryRecordDetails, fetchHSCodeHistoryRecords } from "../../utils/Api";
import { formatDateString, getCountryNameLabel, getCountryFlagCode } from "../../utils/utils";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HSCodeHistoryModel from "./HSCodeHistoryModel";
import DisclaimerStrip from "../../components/Disclaimer/DisclaimerStripe";

const HsCodeHistoryTable = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [countryList, setCountryList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [recordDetails, setRecordDetails] = useState(null);
    const [hsCodeData, setHsCodeData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [countryData, setCountryData] = useState("")

    useEffect(() => {
        const isCountryInStorage = JSON.parse(window.localStorage.getItem("")) || fetchCountry()
        setCountryList(isCountryInStorage);
    }, []);

    useEffect(() => {
        const getHSCodeHistory = async () => {
            const result = await fetchHSCodeHistoryRecords(page, pageSize);
            const formattedData = result && result.map((attr, index) => {
                const _body = JSON.parse(attr?.query);
                const hs_code = _body.hs;
                const id = attr.id
                const date = formatDateString(attr.iso_date);
                const export_country = getCountryNameLabel(_body.exp);
                const import_country = getCountryNameLabel(_body.imp);
                const point = attr.point;
                return { hs_code, date, export_country, import_country, point, id };
            });
            setHsCodeData(formattedData);
        }
        getHSCodeHistory();
    }, [page, pageSize]);

    const handleViewHistoryModal = async (id, import_country, export_country) => {
        const details = await fetchDutySaverHistoryRecordDetails(id);
        setRecordDetails(details);
        setCountryData({
            imp_country: import_country,
            exp_country: export_country
        })
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setRecordDetails(null);
    };



    const renderCountryWithFlag = (params) => {
        const countryName = params.value;
        const countryCode = getCountryFlagCode(countryName);

        return (
            <Box display="flex" alignItems="center">
                <CountryFlag countryCode={countryCode} svg style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }} />
                <Typography sx={{ fontWeight: "600", fontSize: "0.75rem", lineHeight: "1.43" }}>
                    {countryName}
                </Typography>
            </Box>
        );
    };

    const columns = [
        { field: "date", headerName: "Date", flex: 1, },
        {
            field: "import_country",
            headerName: "Import Country",
            flex: 1,
            renderCell: renderCountryWithFlag,
        },
        {
            field: "export_country",
            headerName: "Export Country",
            flex: 1,
            renderCell: renderCountryWithFlag,
        },
        {
            field: "hs_code",
            headerName: "HS Code",
            flex: 1,
        },
        {
            field: "point",
            headerName: "Points",
            flex: 1,
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                const { id, row } = params;
                return (
                    <div
                        className={`status-cell`}
                        onClick={() => handleViewHistoryModal(id, row.import_country, row.export_country)}
                    >
                        View
                    </div>
                );
            },
        },
    ];

    const handleNextPage = () => {
        if (hsCodeData.length === pageSize) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <Box
            height="90%"
            width="100%"
            padding="16px"
        >
            <Typography sx={{
                backgroundColor: "#1994b7",
                padding: "10px",
                color: "#fff",
                borderRadius: "7px",
                margin: "20px 0",
                fontSize: "18px",
            }}>HS Code Search History</Typography>
            <Box
                boxShadow={3}
                borderRadius={5}
                bgcolor="background.paper">
                <DataGrid
                    rows={hsCodeData}
                    columns={columns}
                    disableSelectionOnClick
                    autoHeight
                    components={{ Toolbar: GridToolbar }}
                    sx={{
                        overflow: "auto",
                        "& .status-cell": {
                            padding: "5px",
                            borderRadius: "5px",
                            fontWeight: "bolder",
                            display: "inline-block",
                            cursor: "pointer",
                            color: colors.blueAccent[400],
                        },
                        "& .MuiDataGrid-root": {
                            border: "none",
                            boxShadow: 3,
                            borderRadius: 2,
                            minWidth: "1000px",
                        },
                        "& .MuiDataGrid-cellContent": {
                            fontWeight: "600"
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "800",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colors.table[200],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            display: "none",
                        },
                        "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.grey[100]} !important`,
                        },
                        "& .MuiDataGrid-container--top [role=row]": {
                            backgroundColor: `${colors.grey[500]} !important`,
                        },
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px">
                <IconButton onClick={handlePreviousPage} disabled={page === 1}>
                    <ArrowBackIosIcon />
                </IconButton>
                <Typography>Page {page}</Typography>
                <IconButton onClick={handleNextPage} disabled={hsCodeData?.length < pageSize}>
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    height: "90vh",
                    overflow: "auto",
                    bgcolor: 'background.paper',
                    border: '2px solid #dadada',
                    borderRadius: "7px",
                    boxShadow: 24,
                    p: 4,
                }}>
                    {recordDetails ? (
                        <Typography id="modal-description" sx={{ mt: 2 }}>
                            <HSCodeHistoryModel product={recordDetails} countryData={countryData} />
                        </Typography>
                    ) : (
                        <Typography id="modal-description" sx={{ mt: 2 }}>
                            Loading...
                        </Typography>
                    )}
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={handleCloseModal}
                            sx={{
                                backgroundColor: "var(--intgrow-bg)",
                                color: "#fff",
                                "&:hover": {
                                    backgroundColor: "var(--intgrow-bg)"
                                }
                            }}>Close</Button>
                    </Box>
                </Box>
            </Modal>
            <Box>
                <DisclaimerStrip />
            </Box>
        </Box>
    );
};

export default HsCodeHistoryTable;
