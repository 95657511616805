import { Grid } from '@mui/material'
import React from 'react'
import styles from './YoutubeEmbed.module.scss'

const YoutubeEmbed = ({embedurl}) => {
    return (
        <Grid className={styles.youtubeEmbedContainer}>
            <iframe
            
                src={embedurl}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </Grid>
    )
}

export default YoutubeEmbed

{/* <iframe
width="100%"
height="60%"
src="https://www.youtube.com/embed/cf0hcMNPWS0?autoplay=1&mute=1&loop=1&playlist=cf0hcMNPWS0"
title="YouTube video player"
frameBorder="0"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
allowFullScreen
style={{ borderRadius: '7px' }}
></iframe> */}