import React, { useState, useEffect, useCallback } from 'react';
import {Grid, Typography,useTheme } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './ProfileSetting.module.scss';
import CommonHeading from '../CommonHeading/CommonHeading';
import InputField from '../../components/InputField/InputField';
import SimpleButton from '../../components/SimpleButton/SimpleButton';
import { tokens } from '../../theme';
import { baseUrl } from "../../utils/baseURL";
import DisabledInputField from '../../components/InputField/disabledInputField'

const ProfileSetting = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [password, setPassword] = useState({
        old_password: '',
        new_password: '',
    });
 
    const resetPassword = async () => {
        try {

            const url = `${baseUrl}/auth/changePassword`;
            const token = window.localStorage.getItem('access_token');
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "x-api-key": token
                },
                body: JSON.stringify(password),
            });
            const responseData = await response.json()
            if (!response.ok) {
                throw new Error("An error occured")
            }
            setPassword({
                old_password: '',
                new_password: '',
            })
            toast.success(responseData.message || 'Password successfully updated');
            return response;
        } catch (error) {
            console.error('Error:', error);
            toast.error(error.message || 'Error in updating password');
        }
    };

    const handlePasswordChange = useCallback((event) => {
        setPassword((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }, []);

    return (
        <>
            <Grid className={styles.profileSettingContainer}>
                <ToastContainer />
                <CommonHeading heading={"Profile Setting"} />
                <Grid className={styles.profileSetting}>
                    <Grid className={styles.rightSide} sx={{ backgroundColor: colors.primary[400] }}>
                        <Typography variant='h4' fontSize={25} fontWeight={700}>Password</Typography>
                        <Typography marginBlockEnd={2.5} fontWeight={500}>Update password</Typography>

                        <DisabledInputField label={"Old password"} name="old_password" value={password.old_password} onChange={(event) => handlePasswordChange(event)} />
                        <DisabledInputField label={"New Password"} name="new_password" value={password.new_password} onChange={(event) => handlePasswordChange(event)}/>
                        <SimpleButton name={"Update Password"} onClick={resetPassword} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ProfileSetting;
