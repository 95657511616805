import React, { useState } from 'react'
import './auth-index.style.css'
import { useTheme } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
    Box, Button, Stack, TextField, Typography, InputAdornment, IconButton, CircularProgress
} from '@mui/material';
import { Visibility, VisibilityOff, } from '@mui/icons-material';
import { baseUrl } from '../../utils/baseURL';


const ResetPwdForm = () => {
    const theme = useTheme();
    const { token } = useParams()
    console.log("token",token)
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            password: '',
            submit: null
        },
        validationSchema: Yup.object({
            password: Yup
                .string()
                .max(25, 'Password must be less than 25 characters long')
                .min(8, 'Password must be at least 8 characters long')
                .matches(/[a-zA-Z]/, 'Password must contain at least one letter')
                .matches(/\d/, 'Password must contain at least one number')
                .matches(/[@$!%*?&#]/, 'Password must contain at least one special character')
                .required('Password is required'),
            confirm_password: Yup
                .string()
                .max(255)
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Confirm Password is required')
        }),
        onSubmit: async (values, helpers) => {
            let timeoutId;
            try {
                setLoading(true);
                const response = await fetch(`${baseUrl}/auth/forgotPasswordSubmit`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...values, token })
                });
                if (!response.ok) {
                    const responseData = await response.json();
                    console.log(responseData)
                    const loginError = new Error(responseData.error || 'An error occurred');
                    loginError.status = response.status;
                    setLoading(false);
                    throw loginError;
                }

                const responseData = await response.json();
                setData(responseData);
                toast.success(responseData.message || 'Password successfully updated');
                helpers.resetForm();
                timeoutId=setTimeout(() => {
                    navigate('/auth/login');
                }, 3000)

            } catch (err) {
                setLoading(false);
                toast.error(err.message || 'Error in updating password');
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
            } finally {
                return () => {
                    if (timeoutId) {
                        clearTimeout(timeoutId);
                    }
                };
            }
        }
    });


    const handleConfirmPasswordBlur = (e) => {
        formik.handleBlur(e);
        const passwordError = formik.errors.password;
        const confirmError = formik.errors.confirm_password;
        if (!passwordError && !confirmError) {
            setError(null);
        } else {
            setError(passwordError || confirmError);
        }
    }
    return (
        <Stack sx={{ display: "flex", flexDirection: "row", position: "relative" }}>
            <ToastContainer />
            <Box
                sx={{
                    
                    flex: '1 1 auto',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        md: 'row',
                    },
                    justifyContent: 'center',
                    py: 3,
                    gap: "5%"
                }}
            >
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    width: "100%"
                }}>
                    <Box>
                    </Box>
                    <Box>
                        <h4 className="importantInformation"> Important Information</h4>
                        <p className="readInformation">Please <strong>read </strong>the information below and then kindly<br /><strong> share </strong>the requested information.</p>
                        <ul className="pleaseRemember">
                            <li> Do not reveal your password to anybody</li>
                            <li>Do not reuse passwords</li>
                            <li>Use Alphanumeric passwords</li>
                            <li> Your Login ID. and security answer are required</li>
                            <li>Login IDs are case sensitive</li>
                        </ul>
                    </Box>

                </Box>
                <Box className="resetPasswordInputSection">
                    <div style={{ width: "80%", float: "right" }}>
                        <Stack
                            spacing={1}
                            sx={{ mb: 3 }}
                        >
                            <Typography variant="h4" component="h4" color="white">
                                Reset Password ?
                            </Typography>
                        </Stack>

                        <form
                            noValidate
                            onSubmit={formik.handleSubmit}
                        >
                            <Stack spacing={3} sx={{ color: "white" }}>
                                <TextField
                                    error={!!(formik.touched.password && formik.errors.password)}
                                    fullWidth
                                    helperText={formik.touched.password && formik.errors.password}
                                    label="New Password"
                                    name="password"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type={showPassword ? 'text' : 'password'}
                                    value={formik.values.password}
                                    sx={{
                                        color: "#444B59",
                                        padding: "2px",
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                borderColor: theme.palette.mode === "dark" ? "#fff" : "#a19191",
                                            },
                                            "&:hover fieldset": {
                                                borderColor: theme.palette.mode === "dark" ? "#fff" : "#a19191",
                                            },
                                            "&.Mui-focused fieldset": {
                                                borderColor: theme.palette.mode === "dark" ? "#fff" : "#a19191",
                                            },
                                        },
                                        "& .MuiInputLabel-root": {
                                            color: theme.palette.mode === "dark" ? "#fff" : "#0E588C",
                                        },
                                        "& .MuiInputAdornment-root svg": {
                                            color: theme.palette.mode === "dark" ? "#fff" : "#fff",
                                        },
                                        "& .MuiOutlinedInput-input": {
                                            padding: "10px",
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'white' } // Change 'red' to the desired color
                                    }}
                                    InputProps={{
                                        style: { color: "white" },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    edge="end"
                                                    sx={{ color: "white" }}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    error={!!(formik.touched.confirm_password && formik.errors.confirm_password)}
                                    fullWidth
                                    helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                                    label="Confirm New Password"
                                    name="confirm_password"
                                    onBlur={handleConfirmPasswordBlur}
                                    onChange={formik.handleChange}
                                    type={showPassword ? 'text' : 'password'}
                                    value={formik.values.confirm_password}
                                    sx={{
                                        color: "#444B59",
                                        padding: "2px",
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                borderColor: theme.palette.mode === "dark" ? "#fff" : "#a19191",
                                            },
                                            "&:hover fieldset": {
                                                borderColor: theme.palette.mode === "dark" ? "#fff" : "#a19191",
                                            },
                                            "&.Mui-focused fieldset": {
                                                borderColor: theme.palette.mode === "dark" ? "#fff" : "#a19191",
                                            },
                                        },
                                        "& .MuiInputLabel-root": {
                                            color: theme.palette.mode === "dark" ? "#fff" : "#0E588C",
                                        },
                                        "& .MuiInputAdornment-root svg": {
                                            color: theme.palette.mode === "dark" ? "#fff" : "#fff",
                                        },
                                        "& .MuiOutlinedInput-input": {
                                            padding: "10px",
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'white' } // Change 'red' to the desired color
                                    }}
                                    InputProps={{
                                        style: { color: "white" },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    edge="end"
                                                    sx={{ color: "white" }}

                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>

                            {formik.errors.submit && (
                                <Typography
                                    color="error"
                                    sx={{ mt: 3 }}
                                    variant="body2"
                                >
                                    {formik.errors.submit}
                                </Typography>
                            )}
                            <Button
                                fullWidth
                                size="large"
                                sx={{
                                    mt: 3, color: "#19508a", backgroundColor: "white", fontWeight: 900,
                                    '&:hover': {
                                        backgroundColor: "white",
                                    },
                                }}
                                type="submit"
                                variant="contained"
                            >
                                Submit
                            </Button>

                        </form>
                        {data && <span>Password reset successfully.</span>}
                        <Typography
                            color="#fff"
                            variant="body2"
                            sx={{ mt: 3 }}
                        >
                            Already Have an Account?
                            &nbsp;
                            <Link to="/auth/login"
                                style={{ color: "#000" }}
                            >
                                Back to Login
                            </Link>
                        </Typography>
                    </div>
                </Box>

            </Box>

        </Stack >
    )
}

export default ResetPwdForm
