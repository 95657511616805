import React, { useState, useContext, useEffect, useCallback } from 'react'
import { HsCodeContext } from '../../context/HSCodeContext';
import HSResultTable from '../hscode/HSResultTable';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { baseUrl } from '../../utils/baseURL';
import { Link } from 'react-router-dom';
import { Typography, Box,useTheme } from '@mui/material'
import { tokens } from '../../theme'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const BrowseHsCode2 = ({ importingCountry, exportingCountry, hscode }) => {
    const theme = useTheme()
    const mode = theme.palette.mode
    const colors = tokens(mode)
   
    const { hsCodeDetails, setHsCodeDetails } = useContext(HsCodeContext);
    const [selectedHSCode, setSelectedHSCode] = useState("")
    const [isHSCodeResult, setIsHSCodeResult] = useState(false)
    const [hsCodeError, setHsCodeError] = useState(false)
    const [error, setError] = useState("")
    const [hsCodeResult, setHSCodeResult] = useState([])
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [isHSCodeTreeOpen, setIsHSCodeTreeOpen] = useState(false)
    const [open, setOpen] = useState(false);
    const [hscodeModel, setHscodeModel] = useState(false)
    const [openErrorModel, setOpenErrorModel] = useState(false)


    // console.log("hsCodeDetails",hsCodeDetails)
    // console.log("setHsCodeDetails",setHsCodeDetails)
    
    const handleSelectClick = (row) => {
        // console.log("row", row)
        setSelectedRowId(row.id);
        setHsCodeDetails((prevDetails) => ({
            ...prevDetails,
            hsCode: row.HSCode,
        }));
        setIsHSCodeResult(false);
    };

  

    useEffect(() => {
        if (!hscode) {
            return
        }
        setHsCodeDetails((prevDetails) => ({
            ...prevDetails,
            hsCode: hscode,
        }));
    }, [])

    const handleCloseErrorModel = () => {
        setOpenErrorModel(false)
    }
    function createData(id, HSCode, Description) {
        return { id, HSCode, Description };
    }

    const getHSCodeResult = async () => {
        try {
            let error = "";
            if (!importingCountry) {
                error = "Select importing country first";
            } else if (!exportingCountry) {
                error = "Select exporting country first";
            } else if (!hsCodeDetails.hsCode) {
                error = "Enter Product Name or HS Code";
            }

            if (error) {
                console.log("inside error")
                setError(error);
                setHsCodeError(true);
                setOpenErrorModel(true)
                return;
            }

            // search/countryHSCode?hs=rice&imp=IND
            const response = await fetch(`${baseUrl}/search/countryHSCode?hs=${hsCodeDetails?.hsCode}&imp=${importingCountry?.value}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }

            );
            const data = await response.json();
            console.log("response", response)
            if (!response.ok) {
                console.log('Error in fetch hscode');
                throw new Error('No Relevant Matches Found; Please Provide Refine Text for Better Results.."');
            }
            if (response.status === 204) {
                setError("No Relevant Matches Found; Please Provide Refine Text for Better Results..");
                setOpenErrorModel(true)
            }

            setHsCodeError(false);
            setError("");
            handleOpenHscodeModel()
            const filterData = data?.map((h, index) => {
                const { value, label } = h;
                return createData(index + 1, value, label); // Adding index as id
            }) || [];
            setHSCodeResult(filterData);
            setIsHSCodeResult(true);
            return data;
        } catch (error) {
            console.error('An error occurred:', error);
            setError("No Relevant Matches Found; Please Provide Refine Text for Better Results..");
            setOpenErrorModel(true)
            setHsCodeError(true);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            getHSCodeResult();
        }
    };

    const handleOpenHscodeModel = () => setHscodeModel(true);
    const handleCloseHscodeModel = () => setHscodeModel(false);
    return (
        <>
            <div className="duty-calculator-main-SearchDiv">
                <TextField
                    placeholder="Please enter the product name or at least 2, 4, 6 digit of HS codes"
                    type="text"
                    className="duty-calc-label"
                    required
                    fullWidth
                    value={hsCodeDetails?.hsCode}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setHsCodeDetails((prevDetails) => ({
                        ...prevDetails,
                        hsCode: e.target.value,
                    }))}
                />
                <Button variant="contained" sx={{
                    backgroundColor: 'var(--intgrow-primary-color)', marginBottom: '8px', fontWeight: "700",
                    '&:hover': { backgroundColor: 'var(--intgrow-primary-color) !important' }
                }}
                    onClick={getHSCodeResult}
                >Search
                </Button>
            </div>
            <p className='unable-to-locate-hs-code'>If you are unable to locate the HS Code, please use Intgrow's HS Code Finder for more accurate results. <Link to="/hs-code-finder">Click here</Link>.</p>

            {isHSCodeResult && <section className='hs-result-table'>

                <Modal
                    open={hscodeModel}
                    onClose={handleCloseHscodeModel}
                    aria-labelledby="hscode-select-modal"
                    aria-describedby="select-hs-code"
                >
                    <div style={{ padding: 20,  backgroundColor: colors.primary[400], margin: '10% auto', overflow: "auto", maxWidth: 800, height: 500, borderRadius: '7px' }}>
                        <Typography variant="h3" style={{ fontWeight: '700', width: "100%", margin: "0" }}>
                            Products Details
                        </Typography>
                        <Typography gutterBottom style={{ width: "100%" }}>
                            What's my product code?
                        </Typography>
                        <Typography className="duty-calc-label" style={{ width: "100%" }}> Product Name or HS Code</Typography>
                        <Box sx={{ display: "flex", gap: "5px" }}>
                            <TextField
                                placeholder="Please enter the product name or at least 2, 4, 6 digit of HS codes"
                                type="text"
                                className="duty-calc-label"
                                required
                                fullWidth
                                value={hsCodeDetails?.hsCode}
                                onKeyDown={handleKeyDown}
                                onChange={(e) => setHsCodeDetails((prevDetails) => ({
                                    ...prevDetails,
                                    hsCode: e.target.value,
                                }))}
                            />
                            <Button variant="contained" sx={{
                                backgroundColor: 'var(--intgrow-primary-color)', marginBottom: '8px', fontWeight: "700",
                                '&:hover': { backgroundColor: 'var(--intgrow-primary-color) !important' }
                            }}
                                onClick={getHSCodeResult}
                            >Search
                            </Button>
                        </Box>
                        <HSResultTable handleSelectClick={handleSelectClick} open={open} setOpen={setOpen} selectedRowId={selectedRowId} data={hsCodeResult} setIsHSCodeTreeOpen={setIsHSCodeTreeOpen} setIsHSCodeResult={setIsHSCodeResult} setSelectedHSCode={setSelectedHSCode} />
                    </div>
                </Modal>
            </section >}
            <Dialog open={openErrorModel} onClose={handleCloseErrorModel}>
                <DialogContent sx={{ minWidth: "550px" }}>
                    <DialogContentText>
                        <p style={{ color: "red" }}>{error}</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseErrorModel} className='point-deduction-cancel-btn'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
}

export default BrowseHsCode2
