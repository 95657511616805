import { Grid, Typography,Box } from '@mui/material'
import React from 'react'
import styles from './Privacy.module.scss'
import Subscribe from '../../components/Subscribe/Subscribe'
import Footer from '../../components/footer/Footer'
import CommonBanner from '../../components/CommonBanner/CommonBanner';
import { privacyData } from '../../data/privacy'
import { TbArrowBadgeRightFilled } from "react-icons/tb";
import Header from '../../components/header/Header'
import img from '../../assest/Intgrow Logo Transpernt.png'
import { Helmet } from 'react-helmet';


const Privacy = () => {

  return (
    <>
      <Helmet>
        <title>Privacy | IntGrow Corp.</title>
      </Helmet>
      <Grid className={styles.privacyContainer}>
        <Header
          logo={img}
          color={"#444444"}
        />
        <CommonBanner
          heading={"Privacy Policy"}
          subHeading={"We take your privacy seriously."}
        />
        <Grid className={styles.privacy}>
          {privacyData.map((item,index) => {
            return (
              <Box key={index}>
                <Box><TbArrowBadgeRightFilled /></Box>
                <Box><Typography fontSize={16} lineHeight={1.6}>{item}</Typography></Box>
              </Box>
            )
          })}
        </Grid>
        {/* <Subscribe /> */}
        <Footer />
      </Grid>
    </>
  )
}

export default Privacy
