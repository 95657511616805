import React from 'react'
import styles from './InputField.module.scss'
import { Grid, TextField, Typography, useTheme, InputAdornment } from '@mui/material'
import CountryFlag from 'react-country-flag';

const DisabledInputField = ({ label, name, placeholder, onChange, value, disabled, countryCode, type }) => {
    const theme = useTheme();
    return (
        <Grid className={styles.textFieldContainer}>
            <Typography className="duty-calc-label">{label}</Typography>
            <TextField type={type} name={name} placeholder={placeholder} onChange={onChange} value={value} disabled={disabled}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <CountryFlag countryCode={countryCode} svg />
                        </InputAdornment>
                    ),
                }}
                style={{
                    backgroundColor: theme.palette.mode === "dark" ? "#273142" : "#fbfbfb",
                    color: theme.palette.mode === "dark" ? "#fff" : "#000",
                    borderRadius: "7px"
                }} />
        </Grid>
    )
}

export default DisabledInputField