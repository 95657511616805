import React, { useState, useEffect } from 'react';
import dropdownOptions from "../CommingSoon/DropDownOptionData.json";
import "./styleblog.css";
import { Box,useTheme } from '@mui/material';
import BlogDetails from './BlogDataContainer/BlogDetails';
import blogImage from './BlogDataContainer/ImageBlog/blogimage.png'
import logo_1 from '../../assest/Intgrow Logo Transpernt.png'
import logo from '../../assest/Intgrow_logo.png'
import Header from '../header/Header';
import { baseUrl } from '../../utils/baseURL';
import { tokens } from '../../theme'


const Blog = () => {
    const [initialblogList, setInitialBlogList] = useState([])
    const theme = useTheme()
    const mode = theme.palette.mode
    const colors = tokens(mode)

    const fetchAllBlogs = async () => {
        const response = await fetch(`${baseUrl}/blog/list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const responseData = await response.json();
        setInitialBlogList(responseData)
    }
    useEffect(() => {
        fetchAllBlogs()
    }, [])

    return (
        <>
            <Box sx={{
                backgroundColor: "var(--intgrow-primary-color)",
                position: "sticky",
                top: 0,
            }}>
                <Header logo={logo}
                    logo_1={logo_1}
                    color={"#ffffff"} />
            </Box>
            <div className='maincontainer'>
                <img className='blogImage' src={blogImage} alt="Blog" />

                {/* <div className='inputSection'>
                    <select className='dropdown leftDropdown'>
                        <option value="">News Type</option>
                        {dropdownOptions.newsTypes.map((option) => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </select>

                    <select className='dropdown centerDropdown'>
                        <option value="">Publication Date</option>
                        {dropdownOptions.publicationDates.map((option) => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </select>

                    <div className="searchContainer">
                        <input type='text' className='searchBox' placeholder='Search...' />
                    </div>
                </div> */}
                <BlogDetails initialblogList={initialblogList}/>
            </div>
        </>
    );
};

export default Blog;







