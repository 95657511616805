import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const DisclaimerStrip = () => {
    const currentYear = new Date().getFullYear();
    const navigate = useNavigate()
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding={2}
            bgcolor="var(--intgrow-bg)"
            borderTop={1}
            borderColor="divider"
            margin="0 15px"
            sx={{
                flexDirection: {
                    xs: 'column', 
                    sm: 'column', 
                    md: 'row', 
                },
                alignItems: {
                    xs: 'center',
                    sm: 'center',
                    md: 'center',
                },
            }}
        >
            <Typography variant="body2" onClick={() => navigate("/disclaimer")}
                sx={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#fff",
                    cursor: "pointer"
                }}
            >
                Disclaimer
            </Typography>
            <Typography variant="body2"
                sx={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#fff",
                    textAlign:"center"
                }}>
                Copyright © {currentYear} IntGrow.
            </Typography>
        </Box>
    );
};

export default DisclaimerStrip;
