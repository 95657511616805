import React from 'react'
import styles from './Subscribe.module.scss'
import { Grid, Typography } from '@mui/material'
import HeadingText from '../HeadingText/HeadingText'
import SearchBox from '../SearchBox/SearchBox'

const Subscribe = () => {
    return (
        <Grid container className={styles.subscribeContainer}>
            <Grid item sm={5}>
                <Typography variant='h2' fontSize={41} fontWeight={700}>Subscribe for your free account</Typography>
            </Grid>
            <Grid item sm={6}>
                <SearchBox />
                <Typography marginBlock={1.6}>Get access to free calculations and HS code lookups. No credit card needed.</Typography>
            </Grid>
        </Grid>
    )
}

export default Subscribe