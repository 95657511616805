import React, { useState, useEffect } from 'react';
import '../../../components/CommingSoon/createBlog/createblogimage/outletform/supportview.css';
import { formattedDate } from '../../../utils/utils';
import {Box,} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../../../utils/baseURL';
const AllBlogList = () => {
  const navigate = useNavigate()
  const [blogsList, setBlogsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(3);


  const fetchData = async () => {
    const token = window.localStorage.getItem('access_token');
    try {
      const response = await fetch(`${baseUrl}/blog/list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "x-api-key":token
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setBlogsList(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDeleteBlog = async (id) => {
    try {
      const token = window.localStorage.getItem('access_token');
      const response = await fetch(`${baseUrl}/blog/delete/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          "x-api-key":token
        },
      });

      if (response.ok) {
        fetchData()
      } else {
        console.error('Failed to update glossary');
      }
    } catch (error) {
      console.error('Failed to update glossary', error);
    }
  };


  const handleUpdate = (id) => {
    navigate(`/cms/blog-update/${id}`)
  }
  const handleViewBlog = (id) => {
    navigate(`/cms/blog-details/${id}`)
  }

  const handleChangePage = (newPage) => setCurrentPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const currentItems = blogsList.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="view-section-container">
      <h1>Blog List</h1>
      <div className="support-container">
        <div className="support-table-wrapper">
          <table className="support-table">
            <thead>
              <tr>
                <th>S.no.</th>
                <th>Title</th>
                <th>Uploaded Date</th>
                <th>Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={item.id} style={{ borderBottom: '1px solid #dadada' }}>
                  <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                  <td>{item.title}</td>
                  <td>{formattedDate(item.createdAt)}</td>
                  <td>{item.type}</td>
                  <td style={{ display: "flex" }}>
                    <button className="view-button" onClick={() => handleUpdate(item.id)}>
                      Update
                    </button>
                    {/* <button className="view-button" onClick={() => handleViewBlog(item.id)}>
                      View
                    </button> */}
                    <button className="view-button" onClick={() => handleDeleteBlog(item.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="pagination">
        <div className="rows-per-page">
          <span>
            Rows per page:
            <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
              <option value={3}>3</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
            </select>
          </span>
        </div>
        <div className="pagination-buttons">
          <button
            className="previous-button"
            onClick={() => handleChangePage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className="next-button"
            onClick={() => handleChangePage(currentPage + 1)}
            disabled={currentPage * rowsPerPage >= blogsList.length}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AllBlogList;
