import React from 'react'
import { Box, Grid, Typography } from "@mui/material";
import ForgotPasswordForm from '../../components/auth/ForgotPasswordForm';
import Header from '../../components/header/Header'
import logo from '../../assest/Intgrow Logo Transpernt.png'
import logo_1 from '../../assest/Intgrow_logo.png'
import img from '../../assest/forgot_1.png'
import img_1 from '../../assest/Ellipse 1.png'
import img_2 from '../../assest/Ellipse 2.png'
import Footer from '../../components/footer/Footer'
import styles from './ForgotPassword.module.scss'

const ForgotPassword = () => {

  return (
    <Grid className={styles.forgotPasswordContainer}>
      <Header
        logo={logo}

      />
      <Grid container className={styles.forgotPassword}>
        <Grid item xs={12} md={6} className={styles.leftSide}>
          <Typography marginBlockEnd={2.5} variant='h5' fontWeight={600} fontSize={25} sx={{ color: "#FD8406" }}>Forgot Password</Typography>
          <img src={logo_1} />
          <Box>
            <img src={img} />
          </Box>
        </Grid>
        <Grid item xs={12}  md={6} className={styles.rightSide}>
          <ForgotPasswordForm />
        </Grid>
      </Grid>
      <Grid>
        <img src={img_1} />
        <img src={img_2} />
      </Grid>
      <Footer />
    </Grid>

  )
}

export default ForgotPassword
