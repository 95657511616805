import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import { tokens } from "../../theme";
import { useWallet } from "../../context/WalletsContext";

const HSCodeSearchTable = ({ userSearchesHSCode }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [userHSCodeSearchHistory, setUserHSCodeSearchhistory] = useState([]);
  const { userWallets } = useWallet();

  useEffect(() => {
    const getUserHSCodeHistory = async () => {
      const formattedData = userSearchesHSCode && userSearchesHSCode.map((attr, index) => {
        const id = index;
        const hsCode = attr.hscode;
        const product = attr?.des;
        const searches = attr?.search_count;
        const creditUsed = attr?.point_count;
        return { id, hsCode, product, searches, creditUsed };
      });
      setUserHSCodeSearchhistory(formattedData);
    };
    getUserHSCodeHistory();
  }, [userSearchesHSCode]);

  const columns = [
    { field: "hsCode", headerName: "HS Code", flex: 1 },
    { field: "product", headerName: "Product", flex: 2 },
    { field: "searches", headerName: "Searches", flex: 1, headerAlign: "center", align: "center" },
    { field: "creditUsed", headerName: "Credit Used", flex: 1, headerAlign: "center", align: "center" },
  ];

  const pieData = [
    { id: 'Credit left', label: 'Credit left', value: userWallets?.hs_code, color: '#0088af' },
    { id: 'Credit Used', label: 'Credit Used', value: userWallets?.used_hscode, color: '#ff6500' },
  ];

  return (
    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap="20px"
      component={Paper}
      sx={{
        borderRadius: '8px',
      }}>
      <Box
        flex={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: colors.primary[400],
          padding: '20px',
          borderRadius: '8px',
          border: '1px solid #dadada',
        }}
      >
        <Box height="320px" width="100%" alignItems="center">
          <ResponsivePie
            data={pieData}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={{ datum: 'data.color' }}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextColor={'#000000'}
            radialLabelsLinkColor={{ from: 'color' }}
            sliceLabelsSkipAngle={10}
            sliceLabelsTextColor="#ffffff"
          />
        </Box>
      </Box>
      <Box flex={2} display="flex" flexDirection="column"  height="100%"
        sx={{
          borderRadius: '8px',
          padding: "5px",
          overflow: "auto",
          width:{
            md:"100%",
            xs:"100%"  
          }
        }}>
        <Typography variant="h6" fontWeight="bold" mb="10px" sx={{
          padding: "10px",
          backgroundColor: `var(--intgrow-primary-color) !important`,
          borderRadius: "7px 7px 0 0",
          color: "#fff",
          position: "sticky",
          top: "0",
          zIndex: "9",
        }}>
          HS Code Finder
        </Typography>
        <Box flex={1}>
          <DataGrid
            rows={userHSCodeSearchHistory}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            autoHeight
            sx={{
              overflow: "auto",
              "& columnHeaderRow": {
                backgroundColor: "#0088af",
              },
              "& .MuiDataGrid-root": {
                border: "none"
              },
              "& .MuiDataGrid-main": {
                minWidth: "645px"
              },
              ".MuiDataGrid-autoHeight": {
                height: "100% !important"
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
                fontWeight: 600,
              },
              "& .MuiDataGrid-columnHeaders": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "800",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.table[200],
                display: "none"

              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
              "& .MuiDataGrid-container--top [role=row]": {
                backgroundColor: `${colors.grey[500]} !important`,
              },
            }}
          />
        </Box>
      </Box>
    </Box >
  );
};

export default HSCodeSearchTable;
