export const privacyData = [
    "Your privacy is important to us. It is Intgrow's policy to respect your privacy regarding any information we may collect from you across our website, https://intgrow.co/, and other sites we own and operate.",
    "We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and how it will be used.",
    "We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we'll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.",
    "We don't share any personally identifying information publicly or with third-parties, except when required to by law.",
    "Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.",
    "You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services. Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.",
    "As is common practice with almost all professional websites this site uses cookies, which are small files that are downloaded to your computer to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies.",
    "If you create an account with us then we will use cookies for the management of the signupprocess and general administration. These cookies will usually be deleted when you log out however in some cases they may remain afterwards to remember your site preferences when logged out.",
    "We use cookies when you are logged in. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.",
    "This site uses Google Analytics which for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to enhance our user experience.",
    "This policy is effective as of 10 August 2021."
]