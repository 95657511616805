import React, { useState, useEffect } from 'react';
import { Box, useTheme, Typography, Button, TextField, Grid } from '@mui/material';
import axios from 'axios';
import { tokens } from '../../theme'
import { roundToNearestInteger, currencyConvert, formatNumberWithRegex, formatPrice, formattedDate } from '../../utils/utils';
import DisabledInputField from '../../components/InputField/disabledInputField';
import '../DutyCalculator/dutycalculator.style.css'
import { baseUrl } from '../../utils/baseURL';
import DutySaverTable from './DutySaverTable';

const getNotesLabelsUrl = `${baseUrl}/dutyCalculator/getNotesLabels?import_country=`;



const DutySaverStripe = ({ saveDutyResponse, handleModify, inputData, currencyResponse }) => {
    const { selectedCurrency, importingCountry, exportingCountry, productValue } = inputData

    const theme = useTheme()
    const mode = theme.palette.mode
    const colors = tokens(mode)
    const [totalDuty, setTotalDuty] = useState(0);
    const [footnoteData, setFootnoteData] = useState("");
    const [impCurrency, setImpCurrency] = useState("");
    const cyn = saveDutyResponse?.cyn

    useEffect(() => {
        const element = document.getElementById('scroll-duty-saver');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [saveDutyResponse]);

    useEffect(() => {

        currencyResponse && currencyResponse.forEach(c => {
            if (c.unit == c.value) {
                setImpCurrency(c.currency);
            }
        });

        const fetchNotesLabelsData = async () => {
            const savedFootnotes = saveDutyResponse[0]?.[0]
            const _imp = (localStorage.getItem('imp') || savedFootnotes?.import_country)?.toLowerCase();
            const notesLabelsRes = await axios.get(`${getNotesLabelsUrl}${_imp}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }

            });
            const notesData = {};
            notesLabelsRes.data.forEach((n) => {
                notesData[n.duty_code] = n.duty_short_name;
            });
            const footnotes = Object.keys(savedFootnotes).reduce((acc, key) => {
                if (key.endsWith('_f')) {
                    let prefix = (key.split('_f')[0] + "").replace(new RegExp(savedFootnotes?.import_country, 'img'), '').replace(new RegExp(saveDutyResponse.export_country, 'img'), '').replace(/^_+|_+$/g, '');
                    let value = savedFootnotes[key].trim();
                    if (value) {


                        acc += `<li class="footnotes-list"><strong>${notesData[prefix]}</strong><br/>${value}</li>`;

                    }
                }
                return acc;
            }, '');

            setFootnoteData(footnotes);
        };

        fetchNotesLabelsData();

        let dutyTotal = roundToNearestInteger(saveDutyResponse.total + saveDutyResponse.CIFVALUE);
        setTotalDuty(currencyConvert(dutyTotal, saveDutyResponse.cyn_rate));



    }, []);


    return (
        <Box id="scroll-duty-saver" sx={{
            margin: {
                xs: "0px",
                sm: "5px",
                md: "5px",
                lg: "25px"
            },
            padding: "10px",
        }}>
            <Grid container spacing={2} sx={{
                backgroundColor: colors.primary[400]
            }}>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <DisabledInputField label="Exporting Country" value={exportingCountry?.label || ""} countryCode={exportingCountry?.flag} disabled />

                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Typography className="duty-calc-label">Currency</Typography>
                    <TextField value={selectedCurrency?.country} disabled fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Typography className="duty-calc-label">Value of Product</Typography>
                    <TextField type="number" value={productValue} disabled fullWidth />
                </Grid>

                <Grid item xs={12} sm={3} md={3} lg={2}>
                    <Typography className="duty-calc-label" sx={{ visibility: "hidden" }}>Get Result</Typography>

                    <Button sx={{
                        padding: "15px 5px", backgroundColor: "var(--intgrow-primary-color)", color: "#fff",
                        '&:hover': { backgroundColor: 'var(--intgrow-primary-color) !important' }

                    }} variant="outlined" onClick={handleModify} fullWidth>Modify</Button>

                </Grid>
            </Grid>

            <Typography variant="body2">Please click on the "Modify" button to change Exporting Country, Currency, and Value of Products for faster results.</Typography>
            <div className="tnc-note-3">
                This total landed cost calculation is applicable as of {formattedDate()}. Foreign exchange rates are revised in accordance with notifications from the importing country.
            </div>


            <Grid container spacing={2} sx={{overflowX:"auto"}} >
                <Grid item sm={12} md={12} my={4}>
                    <Box sx={{
                        backgroundColor: colors.primary[400],
                        borderRadius: "7px"
                    }}
                    >
                        <DutySaverTable saveDutyResponse={saveDutyResponse} inputData={inputData} impCurrency={impCurrency} />
                    </Box>


                </Grid>

            </Grid>

        </Box>
    );
};

export default DutySaverStripe;
