import React, { useState, useEffect, useRef } from 'react';
import styles from './Help.module.scss';
import { Box, Grid, List, ListItemButton, ListItemText, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { tokens } from '../../theme';
import YoutubeEmbed from '../../components/YoutubeEmbed/YoutubeEmbed';
import { Helmet } from 'react-helmet';
import { baseUrl } from '../../utils/baseURL';

const Help = () => {
    const [show, setShow] = useState(false);
    const [videos, setVideos] = useState([]);
    const [categories, setCategories] = useState('all');
    const dropdownRef = useRef(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleCategories = () => setShow(!show);

    const handleClick = (section) => {
        setCategories(section);
        setShow(false);
    };

    const fetchVideos = async (section) => {
        try {
            const response = await fetch(`${baseUrl}/help/you-tube?section=${section}`);
            const data = await response.json();
            setVideos(data);
        } catch (error) {
            console.error('Error fetching videos:', error);
        }
    };

    useEffect(() => {
        if (categories) {
            fetchVideos(categories);
        }
    }, [categories]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShow(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>Help | IntGrow Corp.</title>
            </Helmet>
            <Grid className={styles.helpContainer}>
                <Box
                    sx={{
                        flexDirection: {
                            md: 'row',
                            sm: 'column',
                            xs: 'column',
                        },
                        gap: {
                            md: '0px',
                            sm: '20px',
                        },
                        padding: '10px',
                    }}
                    ref={dropdownRef}
                >
                    <Typography variant="h4" fontSize={40} fontWeight={700}>
                        Watch and Learn: Video Tutorials
                    </Typography>
                    <Box
                        onClick={handleCategories}
                        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    >
                        <Typography variant="h5" fontSize={15} fontWeight={500}>
                            {/* {categories} */}
                            {categories
                                .toLowerCase()
                                .replace(/\b\w/g, (char) => char.toUpperCase())}
                        </Typography>
                        <ExpandMoreIcon />
                    </Box>
                    {show && (
                        <List sx={{ backgroundColor: colors.primary[400] }}>
                            <ListItemButton onClick={() => handleClick('all')}>
                                <ListItemText>All</ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => handleClick('HS Code Finder')}>
                                <ListItemText>HS Codefinder</ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => handleClick('Duty Calculator')}>
                                <ListItemText>Duty Calculator</ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => handleClick('DutySaver Pro')}>
                                <ListItemText>DutySaver Pro</ListItemText>
                            </ListItemButton>
                        </List>
                    )}
                </Box>
                <Grid container spacing={2} className={styles.help2}>
                    {videos.length > 0 ? (
                        videos.map((item) => (
                            <Grid
                                item
                                sm={12}
                                md={4}
                                lg={3}
                                key={item.id}
                                className={styles.card2}
                                sx={{ backgroundColor: colors.primary[400] }}
                            >
                                <YoutubeEmbed embedurl={item.video_url} />
                            </Grid>
                        ))
                    ) : (
                        <Typography variant="h6" color="textSecondary">
                            No videos found for this category.
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default Help;
