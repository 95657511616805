import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField, Button, Box, Typography } from '@mui/material';
import { baseUrl } from '../../../utils/baseURL';

const AddGlossary = () => {
  const [heading, setHeading] = useState('');
  const [subheading, setSubheading] = useState('');
  const [definition, setDefinition] = useState('');

  const handleSubmit = async () => {
    const faqData = {
      heading,
      subheading,
      definition,
    };

    try {
      const token = window.localStorage.getItem('access_token');
      const response = await fetch(`${baseUrl}/glossary/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "x-api-key":token
        },
        body: JSON.stringify(faqData),
      });

      if (response.ok) {
        const responseData = await response.json()
        toast.success(responseData.message || 'Glossary added successfully');
        setHeading('');
        setSubheading('');
        setDefinition('');
      } else {
        console.error('Failed to add FAQ');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        maxWidth: 600,
        margin: '0 auto',
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
        bgcolor: 'background.paper',
        margin: "20px auto"
      }}
    >
      <ToastContainer />
      <Typography variant="h5" component="h2" gutterBottom>
        Add New Glossary
      </Typography>


      <TextField
        label="Heading"
        variant="outlined"
        fullWidth
        value={heading}
        onChange={(e) => setHeading(e.target.value)}
      />
      <TextField
        label="Sub Heading"
        variant="outlined"
        fullWidth
        value={subheading}
        onChange={(e) => setSubheading(e.target.value)}
      />

      <TextField
        label="Definition"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={definition}
        onChange={(e) => setDefinition(e.target.value)}
      />

      <Button variant="contained" color="primary" onClick={handleSubmit}
       sx={{
        backgroundColor: "var(--intgrow-bg)",
        "&:hover": {
          backgroundColor: "var(--intgrow-bg)"
        }
      }}>
        Add FAQ
      </Button>
    </Box>
  );
};

export default AddGlossary;
