import React, { useState } from 'react';
import './style/SidebarStyle.css';
import intgrowLogo from './createblogimage/intgrowLogo.svg'; // Import the logo
import { Create, ListAlt as ListAltIcon, Visibility as VisibilityIcon, Support, Upload as UploadIcon, Add, FormatListBulleted, Book } from '@mui/icons-material'; // Import MUI icons

const BlogSidebar = ({ onSectionChange }) => {
  const [showBlogMenu, setShowBlogMenu] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const handleBlogClick = () => {
    setShowBlogMenu(!showBlogMenu);
    setActiveSection(showBlogMenu ? null : 'blog');
    setActiveSubmenu(null); // Reset submenu when toggling Blog
    onSectionChange(showBlogMenu ? null : 'blog'); // Clear or set active section in parent component
  };

  const handleSectionClick = (section) => {
    if (section === 'blog') {
      handleBlogClick(); // Toggle blog menu if Blog section is clicked
    } else {
      setActiveSection(section);
      setActiveSubmenu(null); // Reset submenu when switching sections
      onSectionChange(section); // Notify parent about section change
    }
  };

  const handleSubmenuClick = (submenu) => {
    setActiveSubmenu(submenu);
  };

  return (
    <div className="sidebar">
      <div className="logo-container">
        <img src={intgrowLogo} alt="Intgrow Logo" className="logo" />
      </div>
      <div
        className={`section ${activeSection === 'blog' ? 'active' : ''}`}
        onClick={handleBlogClick}
      >
        <Create fontSize="small" style={{ marginRight: 8 }} />
        Blog
      </div>
      {showBlogMenu && (
        <div className="blog-menu">
          <div
            className={`menu-item ${activeSection === 'create' ? 'active' : ''}`}
            onClick={() => handleSectionClick('create')}
          >
            <Create fontSize="small" style={{ marginRight: 8 }} />
            Create
          </div>
          <div
            className={`menu-item ${activeSection === 'blog-list' ? 'active' : ''}`}
            onClick={() => handleSectionClick('blog-list')}
          >
            <ListAltIcon fontSize="small" style={{ marginRight: 8 }} />
            Blog List
          </div>
          <div
            className={`menu-item ${activeSection === 'view' ? 'active' : ''}`}
            onClick={() => handleSectionClick('view')}
          >
            <VisibilityIcon fontSize="small" style={{ marginRight: 8 }} />
            View
          </div>
        </div>
      )}
      <div
        className={`section ${activeSection === 'support' ? 'active' : ''}`}
        onClick={() => {
          handleSectionClick('support');
          handleSubmenuClick('view-support');
        }}
      >
        <Support fontSize="small" style={{ marginRight: 8 }} />
        Support
      </div>
      {activeSection === 'support' && activeSubmenu === 'view-support' && (
        <div className="submenu">
          <div
            className="submenu-item"
            onClick={() => handleSectionClick('view-support')}
          >
            <VisibilityIcon fontSize="small" style={{ marginRight: 8 }} />
            View Support
          </div>
        </div>
      )}
      <div
        className={`section ${activeSection === 'upload-file' ? 'active' : ''}`}
        onClick={() => {
          handleSectionClick('upload-file');
          handleSubmenuClick('upload-csv');
        }}
      >
        <UploadIcon fontSize="small" style={{ marginRight: 8 }} />
        Upload File
      </div>
      {activeSection === 'upload-file' && activeSubmenu === 'upload-csv' && (
        <div className="submenu">
          <div className="submenu-item" onClick={() => handleSectionClick('disclaimer')} >
            <UploadIcon fontSize="small" style={{ marginRight: 8 }} />
            Upload CSV File
          </div>
        </div>
      )}
      <div
        className={`section ${activeSection === 'faq' ? 'active' : ''}`}
        onClick={() => {
          handleSectionClick('faq');
          handleSubmenuClick('add-faq');
        }}
      >
        <Add fontSize="small" style={{ marginRight: 8 }} />
        FAQ
      </div>
      {activeSection === 'faq' && activeSubmenu === 'add-faq' && (
        <div className="submenu">
          <div className="submenu-item">
            <Add fontSize="small" style={{ marginRight: 8 }} />
            Add FAQ
          </div>
          <div className="submenu-item">
            <FormatListBulleted fontSize="small" style={{ marginRight: 8 }} />
            All FAQ
          </div>
        </div>
      )}
      <div
        className={`section ${activeSection === 'glossary' ? 'active' : ''}`}
        onClick={() => {
          handleSectionClick('glossary');
          handleSubmenuClick('add-glossary');
        }}
      >
        <Book fontSize="small" style={{ marginRight: 8 }} />
        Glossary
      </div>
      {activeSection === 'glossary' && activeSubmenu === 'add-glossary' && (
        <div className="submenu">
          <div className="submenu-item">
            <Add fontSize="small" style={{ marginRight: 8 }} />
            Add Glossary
          </div>
          <div className="submenu-item">
            <FormatListBulleted fontSize="small" style={{ marginRight: 8 }} />
            All Glossary
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogSidebar;
