import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LinkedInRedirect = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        if (code && state) {
            navigate('/auth/login', { state: { code, state } });
        }
    }, [navigate]);

    return <div>Redirecting...</div>;
};

export default LinkedInRedirect;
