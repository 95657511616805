// CookieConsent.js
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import CookieModal from '../modal/CookieModal';

const CookieConsent = () => {
    const navigation = useNavigate()
    const [showPopup, setShowPopup] = useState(false);
    const [cookieModal, setCookieModal] = useState(false)
    const handleClose = () => setCookieModal(false)

    useEffect(() => {
        const consent = Cookies.get('cookieConsent');
        if (!consent) {
            setShowPopup(true);
        }
    }, []);

    const handleAccept = () => {
        Cookies.set('cookieConsent', 'true', { expires: 365 });
        setShowPopup(false);
        setCookieModal(false)
    };

    const handleCookiesSetting = () => {
        setCookieModal(!cookieModal);
    };

    return (
        <>
            {showPopup && (
                <div className="cookie-consent-popup">
                    <p>
                        We use cookies to make your connection secure and make statistics about the number of visits. In order to get more information about cookies and find out how to refuse them, <span onClick={() => navigation('/cookie-policy')} style={{ cursor: "pointer", color: "#0088AF" }}>visit our cookie policy page</span> .
                    </p>
                    <button onClick={handleAccept}>Accept</button>
                    <button onClick={handleCookiesSetting}>Cookies Settings</button>
                </div>
            )}
            <CookieModal open={cookieModal} handleClick={handleAccept} handleClose={handleClose} />
        </>
    );
};

export default CookieConsent;
