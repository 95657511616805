import React from 'react';
import '../outletform/createBlogForm.css'; // Adjust the path to your CSS file

const CreateBlogForm = () => {
  return (
    <div className="form-wrapper">
      <h1 className="form-heading">Post New Blog</h1>
      <div className="form-container">
        <form>
          <div className="form-row">
            <div className="input-container">
              <input type="text" name="name" required />
              <label className="floating-label">Name</label>
            </div>
            <div className="input-container">
              <input type="text" name="sortName" required />
              <label className="floating-label">Sort Name</label>
            </div>
            <div className="input-container">
              <input type="text" name="title" required />
              <label className="floating-label">Title</label>
            </div>
          </div>
          <div className="form-row">
            <div className="input-container">
              <input type="text" name="type" required />
              <label className="floating-label">Type</label>
            </div>
            <div className="input-container">
              <input type="text" name="url" required />
              <label className="floating-label">URL</label>
            </div>
            <div className="input-container description-container">
              <textarea name="description" rows="3" required></textarea>
              <label className="floating-label">Description</label>
            </div>
          </div>
          <div className="form-row">
            <div className="input-container">
              <input type="text" name="keyword" required />
              <label className="floating-label">Keyword</label>
            </div>
            <div className="input-container">
              <input type="text" name="tag" required />
              <label className="floating-label">Tag</label>
            </div>
          </div>
          <div className="form-row">
            <div className="input-container">
              <input type="text" name="img_url" required />
              <label className="floating-label">Image URL</label>
            </div>
            <div className="input-container">
              <input type="text" name="image_type" required />
              <label className="floating-label">Image Type</label>
            </div>
            <div className="input-container">
              <input type="text" name="img_alt" required />
              <label className="floating-label">Image Alt</label>
            </div>
          </div>
          <div className="form-row">
            <div className="input-container">
              <input type="text" name="video" required />
              <label className="floating-label">Video</label>
            </div>
            <div className="input-container">
              <input type="text" name="locale" required />
              <label className="floating-label">Locale</label>
            </div>
            <div className="input-container">
              <input type="text" name="related_blog" required />
              <label className="floating-label">Related Blog</label>
            </div>
          </div>
          <div className="form-row">
            <div className="input-container image-upload">
              <input type="file" name="image" required />
              <label className="floating-label">Image Upload</label>
            </div>
          </div>
        </form>
      </div>
      <button className="submit-button">Submit</button>
    </div>
  );
};

export default CreateBlogForm;
