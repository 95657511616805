import React, { useState } from 'react';
import { Container, Box } from '@mui/material';
import GenerateApiKeyForm from '../../APIKey/GenerateApiKeyForm';
import MyApiKeys from '../../APIKey/MyApiKeys';
import Header from '../../components/header/Header';
import logo_1 from '../../assest/Intgrow Logo Transpernt.png'
import logo from '../../assest/Intgrow_logo.png'
const ApiKeyPage = () => {
    const [refreshApiKeys, setRefreshApiKeys] = useState(false);

    const handleApiKeyGenerated = () => {
        setRefreshApiKeys(!refreshApiKeys);
    };

    return (
        <Box>
            <Box sx={{backgroundColor:"var(--intgrow-primary-color)"}}>
                <Header logo={logo}
                    logo_1={logo_1}
                    color="#fff"
                />
            </Box>

            <Container maxWidth="lg">
                <Box sx={{ my: 4 }}>
                    <GenerateApiKeyForm onApiKeyGenerated={handleApiKeyGenerated} />
                    <MyApiKeys refresh={refreshApiKeys} />
                </Box>
            </Container>
        </Box>
    );
};

export default ApiKeyPage;
