import React from 'react'
import styles from './CookieModal.module.scss'
import { Box, Grid, Modal, Typography, FormGroup, FormControlLabel, Checkbox, Divider, Button } from '@mui/material'
import { IoMdClose } from "react-icons/io";

const CookieModal = ({ open, handleClick, handleClose }) => {
    return (
        <Modal
            open={open}
            className={styles.cookieModalContainer}
        >
            <Grid className={styles.cookieModal}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={21} fontWeight={500}>Which type of cookies you want to allow on your device.</Typography>
                    <IoMdClose fontSize={21} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox />} label="Necessary Cookies" />
                        <FormControlLabel control={<Checkbox />} label="Insight Cookies" />
                    </FormGroup>
                </Grid>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid className={styles.action}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClick}>Submit</Button>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default CookieModal