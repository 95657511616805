import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    formData: null,
    otherData: {
        exportingCountry: null,
        selectedCurrency: null,
        productValue: null
    }
};

const dutyCalculatorSlice = createSlice({
    name: 'dutyCalculator',
    initialState,
    reducers: {
        setFormData: (state, action) => {
            state.formData = action.payload.formData;
            state.otherData.exportingCountry = action.payload.otherData.exportingCountry;
            state.otherData.selectedCurrency = action.payload.otherData.selectedCurrency;
            state.otherData.productValue = action.payload.otherData.productValue;
        },
        resetFormData: (state) => {
            state.formData = null;
            state.otherData = {
                exportingCountry: null,
                selectedCurrency: null,
                productValue: null
            };
        }
    }
});

export const { setFormData, resetFormData } = dutyCalculatorSlice.actions;
export default dutyCalculatorSlice.reducer;
