import React, { useEffect, useState } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { useTheme, Box, Paper, Typography, Button, Modal } from "@mui/material";
import { tokens } from "../../theme";
import { formatDateString } from "../../utils/utils";
import ViewTicketDetails from './ViewTicketDetails';


const TicketTable = ({ supportData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [supprtHistory, setSupportHistory] = useState([])
  const [openViewTicketModel, setOpenViewTicketModel] = useState(false)
  const [selectedTicket, setSelectedTicket] = useState({})
  useEffect(() => {
    const getSaverHistory = async () => {
      const formattedData = supportData && supportData?.map((attr, index) => {
        const date = formatDateString(attr.createdAt);
        const id = attr.id;
        const category = attr.category
        const status = attr?.ticket_status;
        const issue = attr?.message
        return { id, date, category, status, issue, attr };
      });
      setSupportHistory(formattedData);
    }
    getSaverHistory();
  }, [supportData]);

  const handleViewTicket = (id, params) => {
    setSelectedTicket(params?.attr)
    setOpenViewTicketModel(true)
  }

  const handleCloseTicketModal = () => {
    setOpenViewTicketModel(false)
  }

  const columns = [
    // { field: "id", headerName: "S.No.", flex: 0.3 },
    { field: "issue", headerName: "Issue", flex: 1 },
    { field: "date", headerName: "Raised Date", flex: 1 },
    { field: "category", headerName: "Category", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <button
            style={{
              backgroundColor: "var(--intgrow-primary-color)",
              color: "white",
              border: "none",
              padding: "5px 10px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => handleViewTicket(params.id, params.row)}

          >
            View Ticket
          </button>
        );
      },
    },
  ];

  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: '7px',
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%", marginTop: "32px"
      }}
    >
      <Typography
        variant='h2'
        sx={{
          padding: "10px",
          backgroundColor: "var(--intgrow-primary-color)",
          borderRadius: "7px 7px 0 0",
          color: "#fff",
        }} >My Tickets
      </Typography>
      <DataGrid
        autoHeight
        rows={supprtHistory}
        columns={columns}
        sx={{
          overflow: "auto",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-main": {
            minWidth: "800px"
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            fontWeight: 600,
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "800",

          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.table[200],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiDataGrid-container--top [role=row]": {
            backgroundColor: `${colors.grey[500]} !important`,
          },
        }}
      />


      <Modal
        open={openViewTicketModel}
        onClose={handleCloseTicketModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          maxWidth: 1000,
          minWidth:300,
          transform: 'translate(-50%, -50%)',
          height: "90vh",
          overflow: "auto",
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: "7px",
          p: 4,
        }}>
          <Typography id="modal-title" variant="h6" component="h2"
            sx={{
              padding: "10px",
              backgroundColor: "#1994b7",
              color: "#fff",
            }}>
            Ticket Details
          </Typography>
          {selectedTicket ? (
            <Typography id="modal-description" sx={{ mt: 2 }}>
              <ViewTicketDetails selectedTicket={selectedTicket} />
            </Typography>
          ) : (
            <Typography id="modal-description" sx={{ mt: 2 }}>
              Loading...
            </Typography>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="outlined"
              sx={{
                backgroundColor: "var(--intgrow-bg)",
                marginTop: "20px",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "var(--intgrow-bg)"
                }
              }}
              onClick={handleCloseTicketModal}>
              Close Ticket
            </Button>
          </Box>
        </Box>
      </Modal>
    </Paper >
  );
};

export default TicketTable;
