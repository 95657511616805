import React from 'react'
import { Box,Typography,useTheme } from '@mui/material';
import { tokens } from "../../theme";

const formatCurrencyValue = (value) => {
    const [beforeDecimal, afterDecimal] = value.toString().split(".");
    return { beforeDecimal, afterDecimal };
  };
  
const FormatedCalcValue = ({value,currency,originaFontSize,convertedFontSize,cssStyle}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
        const { beforeDecimal, afterDecimal } = formatCurrencyValue(value);        
        const beforeDecimalValue = {
            color:cssStyle?.color || colors.primaryColor[300],
            fontSize: cssStyle?.fontSize || "0.75rem",
            fontWeight:cssStyle?.fontWeight || "600"
          }

   const afterDecimalValue = {
    color:cssStyle?.color || colors.primaryColor[300],
    fontSize: cssStyle?.fontSize2 || "0.75rem",
    fontWeight:cssStyle?.fontWeight || "600"
          }
        return (
          <Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' ,...beforeDecimalValue}}>
              {beforeDecimal}.
              <Typography component="span" sx={{ fontWeight: 'normal', fontSize: '0.8rem',...afterDecimalValue }}>
                {afterDecimal?afterDecimal:0} {currency && currency}
              </Typography>
            </Typography>
          </Box>
        );      
}

export default FormatedCalcValue