import React from 'react';
import { withUserAuthGuard } from '../../withGaurd/withUserGaurd';
import { Box, Typography, } from '@mui/material';

import HeaderSlider from '../../adminPanel/hscode/HeaderSlider';
import HSCodeClassifier from '../../adminPanel/hscode/HSCodeClassifier';
import { Helmet } from 'react-helmet';
import DisclaimerStrip from '../../components/Disclaimer/DisclaimerStripe';
import CommonFAQAccordionSection from '../../components/AccordionSection/CommonFAQAccordian';

const HSCodeFinder = withUserAuthGuard(() => {
  return (
    <>
      <Helmet>
        <title>HS Code Finder | IntGrow Corp.</title>
      </Helmet>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center"
          sx={{
            marginBottom: "7px"
          }}>
          <Typography variant="h1" component="h2"
            sx={{
              fontWeight: "700",
              fontSize:"48px"
            }}>Find Your HS Code </Typography>
        </Box>

        {/* <div>
          <HeaderSlider />
        </div> */}
        <HSCodeClassifier />
      </Box >
      <CommonFAQAccordionSection section="hs code finder" />
      <DisclaimerStrip />
    </>
  );
});

export default HSCodeFinder;
