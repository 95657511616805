import React from 'react';
import { Typography,useTheme, Box } from '@mui/material';
import { igToLocale, currencyConvert } from '../../utils/utils';

const SavedAmount = ({ savedDuty,totalSaverPrecision,saverTotal, cyn, ftaRule, duty, getDutyTotal, cynRateInfo }) => {
    const theme = useTheme()
    const mode = theme.palette.mode
    const impCurrency=duty[0]?.imp_cyn?.toUpperCase()
    return (
        <Box sx={{
            display: "flex", alignItems: "ceneter",
            gap: "10px", padding: "25px 5px",
            border: "1px solid #dadada",
            borderRadius: "7px", 
        }}>
{/* {ftaRule} */}
            <img className="thumbs-up-icon" src={mode==="dark"?"/assets/images/congratulation_dark.svg":"/assets/images/congratulation.png"} alt="success" width={40} height={40} />
            <Typography variant="body1" component="span" className='congrat-success-msg'>
                Congratulations! You've successfully saved <strong> {igToLocale(savedDuty, impCurrency, impCurrency)} ({igToLocale(currencyConvert(savedDuty, cynRateInfo), cyn, cyn)}) </strong> on the above transaction. Now, you may only 
                need to pay <strong> {igToLocale(duty[0].total, impCurrency, impCurrency)} ({igToLocale(currencyConvert(duty[0].total, cynRateInfo), cyn, cyn)}) </strong> import duty against the Standard Import Duty of  <strong> {igToLocale(saverTotal) } {impCurrency} ({igToLocale(totalSaverPrecision)} {cyn})
                </strong>.
            </Typography>
        </Box >
    );
};

export default SavedAmount;
