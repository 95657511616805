import React, { useEffect, useState } from 'react';
import styles from './FAQ.module.scss';
import { Grid, Box, Typography, Icon } from '@mui/material';
import HeadingText from '../HeadingText/HeadingText';
import FAQAccordionSection from './FAQAccordian';
import { baseUrl } from '../../utils/baseURL';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CalculateIcon from '@mui/icons-material/Calculate';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";


const filterFAQBySection = (faqList) => {
    const groupedFAQ = {};
    faqList.forEach(item => {
        if (!groupedFAQ[item.section]) {
            groupedFAQ[item.section] = [];
        }
        groupedFAQ[item.section].push(item);
    });
    return groupedFAQ;
};

const FAQ = () => {
    const [faqList, setFAQList] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        const token = window.localStorage.getItem('access_token');
        try {
            const response = await fetch(`${baseUrl}/faq/all-list`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "x-api-key": token
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const filteredFAQ = filterFAQBySection(data?.faqs);
            setFAQList(filteredFAQ);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <Box className={styles.faqContainer}>
        <HeadingText heading="Frequently Asked Questions" />
        <section className="faq-section" style={{ margin: "50px 0" }}>
        <Grid container spacing={2} >
            {faqList?.Home && (
                <Grid item xs={12} md={6} >
                    <Box boxShadow={3} borderRadius={5} height={"100%"}>
                        <Typography variant="h6" align="center">
                            <HomeOutlinedIcon sx={{ fontSize: "3rem" }} />
                        </Typography>
                        <Typography variant="h6" align="center">
                            Home
                        </Typography>
                        <FAQAccordionSection accordionslist={faqList?.Home} />
                    </Box>
                </Grid>
            )}

            {faqList["duty saver pro"] && (
                <Grid item xs={12} md={6} >
                    <Box  boxShadow={3} borderRadius={5} height={"100%"}>
                        <Typography variant="h6" align="center">
                            <ReceiptOutlinedIcon sx={{ fontSize: "3rem" }} />
                        </Typography>
                        <Typography variant="h6" align="center">
                            Duty Saver Pro
                        </Typography>
                        <FAQAccordionSection accordionslist={faqList["duty saver pro"]} />
                    </Box>

                </Grid>
            )}
            {faqList["Duty Calculation"] && (

                <Grid item xs={12} md={6}>
                    <Box boxShadow={3} borderRadius={5} height={"100%"}>
                        <Typography variant="h6" align="center">
                            <CalculateIcon sx={{ fontSize: "3rem" }} />
                        </Typography>
                        <Typography variant="h6" align="center">
                            Duty Calculation
                        </Typography>
                        <FAQAccordionSection accordionslist={faqList["Duty Calculation"]} />
                    </Box>
                </Grid>
            )}

            {faqList["hs code finder"] && (
                <Grid item xs={12} md={6}>
                    <Box boxShadow={3} borderRadius={5} height={"100%"}>
                        <Typography variant="h6" align="center">
                            <ManageSearchIcon sx={{ fontSize: "3rem" }} />
                        </Typography>
                        <Typography variant="h6" align="center">
                            HS Code Finder
                        </Typography>
                        <FAQAccordionSection accordionslist={faqList["hs code finder"]} />
                    </Box>
                </Grid>
            )}

        </Grid>
        </section>
        </Box>
    );
};

export default FAQ;
