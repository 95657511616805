import React, { useState, useEffect } from 'react';
import { useTheme, Grid, Box, Button, Stack, TextField, Typography, InputAdornment } from "@mui/material";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import { baseUrl } from '../../utils/baseURL';
import styles from './ForgotPasswordForm.module.scss';

const ForgotPasswordForm = () => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const [data, setData] = useState(null);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: '',
            submit: null
        },
        validationSchema: Yup.object({
            email: Yup
                .string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required')
        }),
        onSubmit: async (values, helpers) => {
            const controller = new AbortController();
            const signal = controller.signal;
            try {
                const response = await fetch(`${baseUrl}/auth/forgotPassword`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...values }),
                    signal
                });
                if (!response.ok) {
                    const responseData = await response.json();
                    const loginError = new Error(responseData.error || 'An error occurred');
                    loginError.status = response.status;
                    throw loginError;
                }

                const responseData = await response.json();
                setData(responseData);
                helpers.resetForm();
                alert("Password Reset Link sent to your Email");
                navigate("/auth/login");
            } catch (err) {
                if (err.name !== 'AbortError') {
                    helpers.setStatus({ success: false });
                    helpers.setErrors({ submit: err.message });
                    helpers.setSubmitting(false);
                }
            }

            return () => {
                controller.abort();
            };
        }
    });

    return (
        <Grid className={styles.forgotPasswordFormContainer}>
            <Typography variant='h4' fontSize={25} fontWeight={800}>FORGOT PASSWORD?</Typography>
            <Typography marginBlock={2}>Please read the information below and then kindly share the requested information.</Typography>
            <Box display={"flex"} marginBlockEnd={4}>
                <ul>
                    <li>Do not reveal your password to anybody.</li>
                    <li>Do not reuse passwords.</li>
                    <li>Use Alpha-numeric passwords.</li>
                    <li>Login IDs are case sensitive.</li>
                </ul>
            </Box>
            <form
                noValidate
                onSubmit={formik.handleSubmit}
            >
                <Stack spacing={3} sx={{ color: "white" }}>
                    <TextField
                        error={!!(formik.touched.email && formik.errors.email)}
                        fullWidth
                        helperText={formik.touched.email && formik.errors.email}
                        label="Enter Your Email"
                        name="email"
                        id="outlined-required"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="email"
                        value={formik.values.email}
                        sx={{
                            color: "#444B59",
                            padding: "2px",
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: mode === "dark" ? "#a19191" : "#a19191",
                                },
                                "&:hover fieldset": {
                                    borderColor: mode === "dark" ? "#a19191" : "#a19191",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: mode === "dark" ? "#a19191" : "#a19191",
                                },
                            },
                            "& .MuiInputLabel-root": {
                                color: mode === "dark" ? "#fff" : "#0E588C",
                            },
                            "& .MuiInputAdornment-root svg": {
                                color: mode === "dark" ? "#fff" : "#0E588C",
                            },
                            "& .MuiOutlinedInput-input": {
                                padding: "10px",
                            }
                        }}
                        InputLabelProps={{
                            style: { color: mode === "dark" ? "#fff" : "#0E588C" }
                        }}
                        InputProps={{
                            style: {
                                color: "#0E588C",
                                fontSize: "medium",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal",
                                width: "100%",
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EmailIcon sx={{ color: "#0E588C", marginTop: "0px" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>

                {formik.errors.submit && (
                    <Typography color="error" sx={{ mt: 3 }} variant="body2">
                        {formik.errors.submit}
                    </Typography>
                )}
                <Button
                    fullWidth
                    size="large"
                    sx={{
                        mt: 3, color: "#fff", backgroundColor: "var(--intgrow-primary-color)",
                        '&:hover': {
                            backgroundColor: "var(--intgrow-primary-color)",
                        },
                    }}
                    type="submit"
                    variant="contained"
                >
                    Send
                </Button>
            </form>
            <Typography variant="h5" marginBlock={2}>
                Already Have an Account?
                &nbsp;
                <Link to="/auth/login">
                    Back to Login
                </Link>
            </Typography>
        </Grid>
    );
}

export default ForgotPasswordForm;
