import React, { useState } from 'react'
import { Grid, Box, Typography, useTheme } from "@mui/material";
import './auth.style.css'
import SignupForm from '../../components/auth/SignupForm';
import Header from '../../components/header/Header'
import logo from '../../assest/Intgrow Logo Transpernt.png'
import logo_1 from '../../assest/Intgrow_logo.png'

import styles from './SignUp.module.scss'
import img from '../../assest/signUp_1.png'
import img_1 from '../../assest/Ellipse 1.png'
import img_2 from '../../assest/Ellipse 2.png'
import Footer from '../../components/footer/Footer'
import { Helmet } from 'react-helmet';

const Signup = () => {

  return (
    <>
      <Helmet>
                <title>Signup | IntGrow Corp.</title>
            </Helmet>
    <Grid className={styles.signUpContainer}>
      <Header logo={logo} />
      <Grid container className={styles.signUp}>
        <Grid item md={6}  className={styles.leftSide}>
          <Typography marginBlockEnd={2.5} variant='h5' fontWeight={600} fontSize={25} sx={{ color: "#FD8406" }}>Welcome To</Typography>
          <img src={logo_1} />
          <Typography sx={{ color: "#FFFFFF" }}>If you don’t have an account you can Register HERE</Typography>
          <Box>
            <img src={img} />
          </Box>
        </Grid>
        <Grid item md={6} className={styles.rightSide}>
          <SignupForm />
        </Grid>
      </Grid>
      <Grid>
        <img src={img_1} />
        <img src={img_2} />
      </Grid>
      <Footer />
    </Grid>
    </>

  )
}
{/* <AuthLayout authTitle="Create Account" layoutImg="/assets/images/signup2.png" imgClass="zoom-in-out">
      <SignupForm/>
    </AuthLayout> */}

export default Signup
