import React from 'react'
import HsCodeHistoryTable from '../../../adminPanel/History/HsCodeHistoryTable'
import { Helmet } from 'react-helmet';

const HsCodeHistory = () => {
  return (
    <div style={{ height: "90vh" }}>
      <Helmet>
        <title>HS Code History | IntGrow Corp.</title>
        <meta property="og:title" content="Dashboard" />
        <meta property="og:url" content="https://alpha.intgrow.co/dashboard" />
        <meta property="og:type" content="information" />
        <meta property="og:description" content="what we serve" />
        <meta name="keywords" content="HS Code, duty calculator, duty saver " />
      </Helmet>
      <HsCodeHistoryTable />
    </div>
  )
}

export default HsCodeHistory
