import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MousePopover = ({
   title = 'Nav-link',
   navLinksArr = [],
   color
}) => {
   const navigate = useNavigate()
   const [anchorEl, setAnchorEl] = React.useState(null);

   const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handlePopoverClose = () => {
      setAnchorEl(null);
   };

   const open = Boolean(anchorEl);

   const onHandleSelect = (menuLink) => {
      handlePopoverClose()
      navigate(`/${menuLink}`)
   }

   return (
      <div>
         <Typography
         variant='h5'
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            // onMouseLeave={handlePopoverClose}
            sx={{
               color:color,
               cursor: 'pointer',
               display:"flex",
               gap:"2px",
               alignItems:"center",
               fontSize:"15px"
            }}
         >
            {title} 
            <ExpandMoreIcon/>
         </Typography>
         <Popover
            id="mouse-over-popover"
            sx={{
               p:1
               // pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'center',
            }}
            transformOrigin={{
               vertical: 'top',
               horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
         >
            <List onMouseLeave={handlePopoverClose} sx={{p:0,width:'fit-content'}}>
               {(navLinksArr || []).map((item, i) => {
                  return (
                     <ListItemButton
                        sx={{
                           color: 'inherit',
                           '&:hover': {
                              color: '#fff',
                              bgcolor: '#0C98BC'
                           }
                        }}
                        key={i}
                        onClick={() => onHandleSelect(item.menuLink)}>
                        <ListItemText>{item.menuTitle}</ListItemText>
                     </ListItemButton>
                  )
               })}
            </List>
            {/* <Typography sx={{ p: 1 }}></Typography> */}
         </Popover>
      </div>
   );
}
export default React.memo(MousePopover);