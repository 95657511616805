import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import TicketTable from './TicketTable'
import HSCodeSearchTable from './HSCodeSearchTable'
import MySubscriptionTable from './MySubscription'
import DutyCalTable from './DutyCalTable'
import { baseUrl } from '../../utils/baseURL'

const MyProfile = () => {
    const [supportData,setSupportData]=useState([])
    const [userSearchesHSCode,setUserSearchesHSCode]=useState([])
    const [userSearchesDuty,setUserSearchesDutyCal]=useState([])
    const [userOrderHistory,setUserOrderHistory]=useState([])

    const fetchUserSupportRequestList = async () => {
        try {
            const token = window.localStorage.getItem("access_token")
            const response = await fetch(`${baseUrl}/get-support-requests-by-user`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "x-api-key":token
                },
            })
            const data = await response.json();
            if (!response.ok) {
                throw new Error('Error in fetch support list');
            }
            setSupportData(data?.supportRequests)
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    const fetchUserSearchHSCodeList = async () => {
        try {
            const token = window.localStorage.getItem("access_token")
            const response = await fetch(`${baseUrl}/get/user/searches/hscode?page=1`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "x-api-key":token
                },
            })
            const data = await response.json();
            if (!response.ok) {
                console.log('Error in fetch search HS code list');
                throw new Error('Error in fetch search HS code list');
            }
            setUserSearchesHSCode(data?.rows)
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    const fetchUserSearchDutyCalcList = async () => {
        try {
            const token = window.localStorage.getItem("access_token")
            const response = await fetch(`${baseUrl}/get/user/searches/duty?1`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "x-api-key":token
                },
            })
            const data = await response.json();
            if (!response.ok) {
                console.log('Error in fetch search duty list');
                throw new Error('Error in fetch search duty list');
            }
            setUserSearchesDutyCal(data?.searchesDutyCalc?.rows)
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    const fetchUserOrderList = async () => {
        try {
            const token = window.localStorage.getItem("access_token")
            const response = await fetch(`${baseUrl}/order/all`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "x-api-key":token
                },
            })
            const data = await response.json();
            if (!response.ok) {
                console.log('Error in fetch search duty list');
                throw new Error('Error in fetch search duty list');
            }
            setUserOrderHistory(data?.result)
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    useEffect(() => {
      
        fetchUserSupportRequestList()
        fetchUserSearchHSCodeList()
        fetchUserSearchDutyCalcList()
        fetchUserSearchDutyCalcList()
        fetchUserOrderList()
    }, [])
    return (
        <Box>
            <Typography
                variant='h2'
                sx={{ margin: "32px 0" }} >Top Searches
            </Typography>
            <Stack height="max-content">
                <HSCodeSearchTable userSearchesHSCode={userSearchesHSCode} />
            </Stack>
            <Stack height="max-content" mt={2}>
                < DutyCalTable userSearchesDuty={userSearchesDuty} />
            </Stack>
            <Stack height="max-content">
                <TicketTable supportData={supportData}/>
            </Stack>

            <Stack>
                <MySubscriptionTable userOrderHistory={userOrderHistory} />
            </Stack>
        </Box>
    )
}

export default MyProfile
