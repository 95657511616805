import React from 'react'
import SupportView from '../../components/CommingSoon/createBlog/createblogimage/outletform/SupportView'

const Support = () => {
  return (
    <div>
      <SupportView />
    </div>
  )
}

export default Support
