import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import PropTypes from 'prop-types';

const UserGuard = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const ignore = useRef(false);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (!location) return;

        if (ignore.current) return;
        ignore.current = true;

        const token = window.localStorage.getItem("access_token");
        // const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
        const userInfo = {
            role:"user",
            name:"ADMIN"
        };
        const isAuthenticated = token && userInfo && userInfo.role === 'user';
        // const isAuthenticated = false;
        // console.log("inside auth")
        if (!isAuthenticated) {
            // If user is not authenticated, redirect to login page
            navigate('/auth/login', {
                state: { continueUrl: location.pathname !== '/' ? location.pathname : undefined }
            });
        } else if (userInfo && userInfo.role !== 'user') {
            // If user is authenticated but not an admin, redirect to not-authorized page
            navigate('/not-authorize');
        } else {
            // If user is authenticated and is an admin, proceed
            setChecked(true);
        }
    }, [location,navigate]);


    if (!checked) return null;

    return <>{children}</>;
};

// UserGuard.propTypes = {
//     children: PropTypes.node
// };

export default UserGuard;
