import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import { tokens } from "../../theme";
import CountryFlag from 'react-country-flag';
import { getCountryNameLabel, getCountryFlagCode } from "../../utils/utils";
import { useWallet } from "../../context/WalletsContext";


const DutyCalTable = ({ userSearchesDuty }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [userDutyCalSearchHistory, setUserDutyCalSearchhistory] = useState([])
    const { userWallets } = useWallet();

    useEffect(() => {
        const getDutyCalHistory = async () => {
            const formattedData = userSearchesDuty && userSearchesDuty.map((attr, index) => {
                const id = index;
                const duty_saver = attr.fta_point_count
                const imp_duty = attr?.duty_point_count;
                const exporting_country = getCountryNameLabel(attr?.exp);
                const importing_country = getCountryNameLabel(attr?.imp);
                return { id, duty_saver, imp_duty, exporting_country, importing_country };
            });
            setUserDutyCalSearchhistory(formattedData);
        }
        getDutyCalHistory();
    }, [userSearchesDuty]);


    const renderCountryWithFlag = (params) => {
        const countryName = params.value;
        const countryCode = getCountryFlagCode(countryName);

        return (
            <Box display="flex" alignItems="center">
                <CountryFlag countryCode={countryCode} svg style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }} />
                <Typography sx={{ fontWeight: "600", fontSize: "0.75rem", lineHeight: "1.43" }}>
                    {countryName}
                </Typography>
            </Box>
        );
    };



    const columns = [
        {
            field: "importing_country", headerName: "Importing Country", flex: 1,
            renderCell: renderCountryWithFlag,
        },
        {
            field: "exporting_country", headerName: "Exporting Country", flex: 1,
            renderCell: renderCountryWithFlag,
        },
        { field: "imp_duty", headerName: "Import Duty Cal", flex: 1, },
        { field: "duty_saver", headerName: "DutySaver Pro", flex: 1, },
    ];

    const pieData = [
        { id: 'Credit left', label: 'Credit left', value: userWallets?.duty_calc, color: '#0088af' },
        { id: 'Credit Used', label: 'Credit Used', value: userWallets?.used_duty, color: '#ff6500' },
    ];



    return (
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap="20px"
            // height="412px"
            component={Paper}
            sx={{
                borderRadius: '8px',
            }}>
            <Box
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                    backgroundColor: colors.primary[400],
                    padding: '20px',
                    borderRadius: '8px',
                    border: '1px solid #dadada',
                    width:"100%"
                }}
            >
                <Box height="320px" width="100%" alignItems="center">
                    <ResponsivePie
                        data={pieData}
                        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        colors={{ datum: 'data.color' }}
                        borderWidth={1}
                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                        radialLabelsSkipAngle={10}
                        radialLabelsTextColor="#333333"
                        radialLabelsLinkColor={{ from: 'color' }}
                        sliceLabelsSkipAngle={10}
                        // sliceLabelsTextColor="#333333"
                        sliceLabelsTextColor="#ffffff"
                    />
                </Box>
            </Box>
            <Box flex={2} display="flex" flexDirection="column" height="100%"
                sx={{
                    borderRadius: '8px',
                    padding: "5px",
                    overflow: "auto",
                    width:"100%"

                }}>
                <Typography variant="h6" fontWeight="bold" mb="10px" display="flex" justifyContent="space-between"
                    sx={{
                        padding: "10px",
                        backgroundColor: `var(--intgrow-primary-color) !important`,
                        borderRadius: "7px 7px 0 0",
                        color: "#fff",
                        position: "sticky",
                        top: "0",
                        zIndex: "9",
                    }}>
                    <span>Duty Calculations</span>
                    <span style={{ width: "50%", textAlign: "center" }}>Credit Used</span>
                </Typography>
                <Box flex={1}>
                    <DataGrid
                        rows={userDutyCalSearchHistory}
                        columns={columns}
                        components={{ Toolbar: GridToolbar }}

                        autoHeight
                        sx={{
                            overflow: "auto",
                            "& columnHeaderRow": {
                                backgroundColor: "#0088af",
                            },
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-main": {
                                minWidth: "645px;"
                              },
                            ".MuiDataGrid-autoHeight": {
                                height: "100% !important"
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                                fontWeight: 600,
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                // backgroundColor: `${colors.table[300]} !important`,
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                fontWeight: "800",
                                // color: "#fff"
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: colors.primary[400],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: colors.table[200],
                                display: "none"

                            },
                            "& .MuiCheckbox-root": {
                                color: `${colors.greenAccent[200]} !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${colors.grey[100]} !important`,
                            },
                            "& .MuiDataGrid-container--top [role=row]": {
                                backgroundColor: `${colors.grey[500]} !important`,
                            },
                        }}
                    />
                </Box>
            </Box>
        </Box >
    );
};

export default DutyCalTable;
