import React from 'react'
import { Link } from 'react-router-dom';

const Logo = ({ logo = "/assets/logos/main-logo.png" }) => {

    return (
        <Link to="/">
            <img src={logo}
                alt='intgrow_logo'
                width={150}
            />
        </Link>
    );
};
export default Logo
