import React, { createContext, useEffect, useRef, useReducer, useContext } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { baseUrl } from '../utils/baseURL';

// Define initial state and action types
const initialState = {
    user: null,
    isAuthenticated: false,
    isLoading: true,

};

const HANDLERS = {
    INITIALIZE: 'INITIALIZE',
    SIGN_IN: 'SIGN_IN',
    SIGN_OUT: 'SIGN_OUT',
};

// Define reducer function
const reducer = (state, action) => {
    switch (action.type) {
        case HANDLERS.INITIALIZE:
            return {
                ...state,
                user: action.payload || null,
                isAuthenticated: !!action.payload,
            };
        case HANDLERS.SIGN_IN:
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true,
            };
        case HANDLERS.SIGN_OUT:
            return {
                ...state,
                user: null,
                isAuthenticated: false,
            };
        default:
            return state;
    }
};

export const AuthContext = createContext();

const getMe = async () => {
    try {
        const token = window.localStorage.getItem('access_token');
        const response = await fetch(`${baseUrl}/auth/me`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "x-api-key":token
            },
        })
        const data = await response.json();
        if (!response.ok) {
            console.log('Error in user profile');
            throw new Error('Error in user profile');
        }
        return data?.result;
    } catch (error) {
        console.error('An error occurred:', error);
    }
}

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const location = useLocation();
    const initialized = useRef(false);
    const initialize = async () => {
        if (initialized.current) {
            return;
        }

        initialized.current = true;

        let isAuthenticated = false;
        let token;

        try {
            token = window.localStorage.getItem('access_token');
            if(!token){
                navigate("/auth/login")
                return
              }
            isAuthenticated = token

            const userInfo = await getMe()
            if(!userInfo){
             throw new Error("User Not found")
            }
            const user = {
                id: userInfo.id,
                name: userInfo.first_name,
                email: userInfo.email,
                role: userInfo?.role
            };
            
            console.log("authenticated user", user)
         
        } catch (err) {
            console.error(err);
        }

        if (isAuthenticated) {
            const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
            const user = {
                email: userInfo?.email,
                role: userInfo?.role
            };

            dispatch({
                type: HANDLERS.INITIALIZE,
                payload: user
            });
        } else {
            dispatch({
                type: HANDLERS.INITIALIZE
            });
        }
    };

    useEffect(() => {
        initialize();
    }, []);

    const skip = () => {
        try {
            window.localStorage.setItem('authenticated', 'true');
        } catch (err) {
            console.error(err);
        }

        const user = {
            email: 'test@test.com',
            role: "student"
        };

        dispatch({
            type: HANDLERS.SIGN_IN,
            payload: user
        });
    };

    // Other functions like signIn, signOut, etc.
    const signOut = async () => {
        window.localStorage.removeItem('access_token');
        window.localStorage.removeItem('userInfo');
        navigate('/auth/login', {
            state: { continueUrl: location.pathname !== '/' ? location.pathname : undefined }
        });
        dispatch({
            type: HANDLERS.SIGN_OUT
        });

    };

    const testAuth = () => {
        console.log("working")
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                skip,
                signOut,
                testAuth
                // Add other functions here
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuthContext = () => useContext(AuthContext);

export { AuthProvider, useAuthContext };
