import React from 'react'
import DutyCalcHistoryTable from '../../../adminPanel/History/DutyCalculatorHistoryTable'
import { Helmet } from 'react-helmet';
import DisclaimerStrip from '../../../components/Disclaimer/DisclaimerStripe';

const DutyCalcHistory = () => {
  return (
    <>
      <Helmet>
        <title>Duty Calculation History | IntGrow Corp.</title>
        <meta property="og:title" content="Dashboard" />
        <meta property="og:url" content="https://alpha.intgrow.co/dashboard" />
        <meta property="og:type" content="information" />
        <meta property="og:description" content="what we serve" />
        <meta name="keywords" content="HS Code, duty calculator, duty saver " />
      </Helmet>
      <div style={{ height: "90vh" }}>
        <DutyCalcHistoryTable />
      </div>
     
    </>

  )
}

export default DutyCalcHistory
