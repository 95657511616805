import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';

const TruncatedText = ({ text }) => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const [isExpanded, setIsExpanded] = useState(false);
    const maxCharacters = 150;

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    // Function to escape plain text if it's not HTML
    const escapeHTML = (str) => {
        return str.replace(/&/g, "&amp;")
                  .replace(/</g, "&lt;")
                  .replace(/>/g, "&gt;")
                  .replace(/"/g, "&quot;")
                  .replace(/'/g, "&#039;");
    };

    const truncatedText = text.length > maxCharacters && !isExpanded
        ? `${text.slice(0, maxCharacters)}...`
        : text;

    // Assume the text contains HTML or plain text, and handle both cases
    const processedText = /<\/?[a-z][\s\S]*>/i.test(text) // Check if it contains HTML tags
        ? truncatedText
        : escapeHTML(truncatedText); // Escape plain text

    const buttonColor = mode === 'light' ? 'black' : 'white';

    return (
        <div>
            <p className='saver-notes'>
                <span dangerouslySetInnerHTML={{ __html: processedText }} />
            </p>
            {text.length > maxCharacters && (
                <Button onClick={toggleExpand} style={{ color: buttonColor }}>
                    {isExpanded ? 'Show Less' : 'Read More'}
                </Button>
            )}
        </div>
    );
};

export default TruncatedText;
