import React from 'react'
import styles from './CommonBanner.module.scss'
import { Grid, Typography } from '@mui/material'

const CommonBanner = ({ heading, subHeading }) => {
    return (
        <Grid className={styles.commonBanner}>
            <Typography textAlign={"center"} sx={{ color: "#ffffff" }} variant='h4' fontSize={41} fontWeight={800}>{heading}</Typography>
            <Typography textAlign={"center"} sx={{ color: "#ffffff" }} marginBlockStart={2} fontSize={16}>{subHeading}</Typography>
        </Grid>
    )
}

export default CommonBanner