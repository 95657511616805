import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  TextField, Button, Select, MenuItem, InputLabel,
  FormControl, Typography, Grid, Container, Paper
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { baseUrl } from '../../../utils/baseURL';

const CreateNewBlog = () => {
  const [content, setContent] = useState('');
  const [blogOptions, setBlogOptions] = useState([]);
  const [seletedOption, setSelectedOption] = useState([]);
  const [seletedImgType, setSelectedImgType] = useState([]);
  const [selectedImageName, setSelectedImageName] = useState('');

  const initialValues = {
    name: '',
    short_name: '',
    title: '',
    type: '',
    url: '',
    description: '',
    keywords: '',
    tags: '',
    img_url: '',
    img_type: '',
    img_alt: '',
    video: '',
    related_blogs: [],
    content: '',
    image: null,
  };

  const imgTypeOptions = [
    { value: "image/jpeg", label: "image/jpeg" },
    { value: "image/png", label: "image/png" },
    { value: "image/gif", label: "image/gif" },
    { value: "image/tiff", label: "image/tiff" },
    { value: "image/svg", label: "image/svg" },
    { value: "image/webp", label: "image/webp" },
  ];

  useEffect(() => {
    const fetchedOptions = [
      { value: 'HS Code Finder', label: 'HS Code Finder' },
      { value: 'Duty Calculator', label: 'Duty Calculator' },
      { value: 'DutySaver Pro', label: 'DutySaver Pro' },
      { value: 'Home', label: 'Home' },
      { value: 'Developer API', label: 'Developer API' },
      { value: 'Support', label: 'Support' },
      { value: 'Dashboard', label: 'Dashboard' },
      { value: 'History', label: 'History' },
      { value: 'FAQ', label: 'FAQ' },
      { value: 'Accounts', label: 'Accounts' },
      { value: 'Help', label: 'Help' },
    ];
    setBlogOptions(fetchedOptions);
  }, []);

  const validationSchema = Yup.object().shape({
    related_blogs: Yup.array().required('Related Blogs are required'),
    name: Yup.string().required('Name is required'),
    short_name: Yup.string().required('Short name is required'),
    title: Yup.string().required('Title is required'),
    type: Yup.string().required('Type is required'),
    description: Yup.string().required('Description is required'),
    image: Yup.mixed().required('Image is required'),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const token = window.localStorage.getItem('access_token');
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('short_name', values.short_name);
    formData.append('title', values.title);
    formData.append('type', values.type);
    formData.append('url', values.url);
    formData.append('description', values.description);
    formData.append('keywords', values.keywords);
    formData.append('tags', values.tags);
    formData.append('img_url', values.img_url);
    formData.append('img_type', values.img_type);
    formData.append('img_alt', values.img_alt);
    formData.append('video', values.video);
    formData.append('related_blogs', values.related_blogs);
    formData.append('content', content);
    formData.append('image', values.image);

    try {
      const token = window.localStorage.getItem('access_token');
      const response = await fetch(`${baseUrl}/blog/create`, {
        method: 'POST',
        headers: {
          "x-api-key":token
        },
        body: formData,
      });

      const data=await response.json()
      if (response.ok) {
        toast.success('Blog posted successfully');
        window.location.reload();
      } else {
        toast.error(`Failed to post blog.${data?.message}`);
      }
    } catch (error) {
      toast.error(`Failed to post blog.${error?.message}`);
    }

    setSubmitting(false);
  };

  return (
    <Container maxWidth="md">
      <ToastContainer />
      <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Create New Blog
        </Typography>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    variant="outlined"
                    onChange={(e) => setFieldValue('name', e.target.value)}
                  />
                  <ErrorMessage name="name" component="div" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Short Name"
                    name="short_name"
                    variant="outlined"
                    onChange={(e) => setFieldValue('short_name', e.target.value)}
                  />
                  <ErrorMessage name="short_name" component="div" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Title"
                    name="title"
                    variant="outlined"
                    onChange={(e) => setFieldValue('title', e.target.value)}
                  />
                  <ErrorMessage name="title" component="div" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Type"
                    name="type"
                    variant="outlined"
                    onChange={(e) => setFieldValue('type', e.target.value)}
                  />
                  <ErrorMessage name="type" component="div" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="URL"
                    name="url"
                    variant="outlined"
                    onChange={(e) => setFieldValue('url', e.target.value)}
                  />
                  <ErrorMessage name="url" component="div" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    variant="outlined"
                    multiline
                    rows={4}
                    onChange={(e) => setFieldValue('description', e.target.value)}
                  />
                  <ErrorMessage name="description" component="div" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Keywords"
                    name="keywords"
                    variant="outlined"
                    onChange={(e) => setFieldValue('keywords', e.target.value)}
                  />
                  <ErrorMessage name="keywords" component="div" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Tags"
                    name="tags"
                    variant="outlined"
                    onChange={(e) => setFieldValue('tags', e.target.value)}
                  />
                  <ErrorMessage name="tags" component="div" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Image URL"
                    name="img_url"
                    variant="outlined"
                    onChange={(e) => setFieldValue('img_url', e.target.value)}
                  />
                  <ErrorMessage name="img_url" component="div" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Image Type</InputLabel>
                    <Select
                      label="Image Type"
                      name="img_type"
                      value={seletedImgType}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setSelectedImgType(selectedValue);
                        setFieldValue('img_type', selectedValue);
                      }}
                    >
                      {imgTypeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <ErrorMessage name="img_type" component="div" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Image Alt"
                    name="img_alt"
                    variant="outlined"
                    onChange={(e) => setFieldValue('img_alt', e.target.value)}
                  />
                  <ErrorMessage name="img_alt" component="div" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Video"
                    name="video"
                    variant="outlined"
                    onChange={(e) => setFieldValue('video', e.target.value)}
                  />
                  <ErrorMessage name="video" component="div" />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Related Blogs</InputLabel>
                    <Select
                      label="Related Blogs"
                      name="related_blogs"
                      multiple
                      value={seletedOption}
                      onChange={(e) => {
                        const selectedValues = e.target.value;
                        setSelectedOption(selectedValues);
                        setFieldValue('related_blogs', selectedValues);
                      }}
                    >
                      {blogOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <ErrorMessage name="related_blogs" component="div" />
                </Grid>

                <Grid item xs={12}>
                  <Editor
                    apiKey="wpc3883gy9wwm18yhjxowq2ic75qi67u0b0h3vi9eydn69mm"
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                         alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help | table',
                      images_upload_url: `${baseUrl}/blog/upload-image`,
                      file_picker_types: 'image',
                      images_upload_handler: (blobInfo, success, failure) => {
                        const data = new FormData();
                        data.append('file', blobInfo.blob(), blobInfo.filename());
                        fetch(`${baseUrl}/blog/upload-image`, {
                          method: 'POST',
                          body: data,
                        })
                          .then((response) => response.json())
                          .then((result) => success(result.fileUrl))
                          .catch(() => failure('Image upload failed.'));
                      },
                    }}
                    onEditorChange={(content, editor) => setContent(content)}
                  />


                  <ErrorMessage name="content" component="div" />
                </Grid>
                <Grid item xs={12}>
                  <input
                    accept="image/*"
                    id="image"
                    name="image"
                    type="file"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      setFieldValue('image', file);
                      setSelectedImageName(file ? file.name : '');
                    }}
                    hidden
                  />
                  <label htmlFor="image">
                    <Button variant="contained" component="span">
                      Upload Image
                    </Button>
                  </label>
                  <ErrorMessage name="image" component="div" />
                  {selectedImageName && (
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Selected file: {selectedImageName}
                    </Typography>
                  )}
                  <ErrorMessage name="image" component="div" />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default CreateNewBlog;
