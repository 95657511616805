import React from 'react'
import { Grid, Typography, Box, SvgIcon } from '@mui/material'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PageviewIcon from '@mui/icons-material/Pageview';
import CalculateIcon from '@mui/icons-material/Calculate';
import CategoryIcon from '@mui/icons-material/Category';
import LanguageIcon from '@mui/icons-material/Language';
import { totalSavedDuty } from '../../utils/Api';
import { tokens } from '../../theme';
import { useWallet } from '../../context/WalletsContext';
import './stats.style.css';
const NewStats = ({ theme }) => {
    const colors = tokens(theme.palette.mode);
    const { userWallets, } = useWallet();
  
    console.log("check wallets",userWallets)
    let count = 0;

    const data = {
        used_hscode: 150,
        used_duty: 50,
        searched_product: 12,
        searched_country: 5,
        total_saved: 500
    }
    const points = JSON.parse(window.localStorage.getItem("points")) || data

    const dashboardItem = [
        {
            title: 'HS Code Finder',
            value: userWallets?.used_hscode || 0,
            remaining: "",
            iconBg: "rgb(25, 80, 138)",
            icon: (
                <SvgIcon fontSize="small">
                    <PageviewIcon />
                </SvgIcon>
            )
        },
        {
            title: 'Duty Calculations',
            value: userWallets?.used_duty || 0,
            remaining: "",
            iconBg: "#3ec9d6",
            icon: (
                <SvgIcon fontSize="small">
                    <CalculateIcon />
                </SvgIcon>
            )
        },
        {
            title: 'Products',
            value: userWallets?.searched_product || 0,
            remaining: "",
            iconBg: "#ffa21d",
            icon: (
                <SvgIcon fontSize="small">
                    <CategoryIcon />
                </SvgIcon>
            )
        },
        {
            title: 'Countries',
            value: userWallets?.searched_country || 0,
            remaining: "",
            iconBg: "rgb(25, 80, 138)",
            icon: (
                <SvgIcon fontSize="small">
                    <LanguageIcon />
                </SvgIcon>
            )
        },

        {
            title: 'Duty Saved',
            value: `${totalSavedDuty(userWallets?.total_saved || 0)}`,
            remaining: "",
            iconBg: "#3ec9d6",
            icon: (
                <SvgIcon fontSize="small">
                    <MonetizationOnIcon />
                </SvgIcon>
            )
        }
    ];
    return (
        <Grid className="heroCardContainer" sx={{ backgroundColor: colors.primary[400] }}>
            <Grid container className="subHeroCardContainer" key={("t_" + new Date().getTime() + "_" + (count++))}>
                {dashboardItem.map((item) => {
                    return (
                        <Grid item xs={12} sm={6} md={2.4} className="subHeroCard" key={("c_" + new Date().getTime() + "_" + (count++))}>
                            <Typography className="heading">{item.title}</Typography>
                            <Box display={"flex"} alignItems={"center"} sx={{ padding: "5px 0",
                                  justifyContent: {
                                    xs: "space-between",
                                    md: "space-around",
                                  },
                             }}>
                                <Typography className="number" dangerouslySetInnerHTML={{ __html: item.value }}></Typography>
                                <Grid className="icon">{item.icon}</Grid>
                            </Box>
                            <Typography className="label">{item.label}</Typography>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default NewStats
