import React from 'react'
import styles from './SimpleButton.module.scss'
import { Button, Grid } from '@mui/material'

const SimpleButton = ({name,onClick}) => {
  return (
    <Grid className={styles.simpleButton}>
      <Button onClick={onClick}>{name}</Button>
    </Grid>
  )
}

export default SimpleButton