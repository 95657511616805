import { useCallback } from 'react';
import {  useNavigate } from 'react-router-dom';
import {
  Divider,
  MenuItem,
  MenuList,
  Popover,
} from '@mui/material';

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open } = props;
  const navigate = useNavigate();


  const handleSignOut = useCallback(() => {
    onClose?.();
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("userInfo");
    window.localStorage.removeItem("isAuthenticated");
    navigate("/auth/login");
  }, [onClose,navigate]);

  const handleAccountSettings = useCallback(() => {
    onClose?.();
    navigate('/account');
  }, [onClose,navigate]);

  const handleProfile = useCallback(() => {
    onClose?.();
    navigate('/profile');
  }, [onClose,navigate]);

  const handleHelp = useCallback(() => {
    onClose?.();
    navigate('/help');
  }, [onClose,navigate]);

  const handleCurrentPlan = useCallback(() => {
    onClose?.();
    navigate('/upgrade-plan');
  }, [onClose,navigate]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      onClose={onClose}
      open={open}
    >
      <MenuList
        disablePadding
        dense
        sx={{
          p: '8px',
          '& > *': {
            borderRadius: 1,
          },
        }}
      >
        <MenuItem onClick={handleAccountSettings}>Account Setting</MenuItem>
        <MenuItem onClick={handleProfile}>Profile</MenuItem>
        <MenuItem onClick={handleHelp}>Help</MenuItem>
        <MenuItem onClick={handleCurrentPlan}>
          {'Upgrade Plan'}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </MenuList>
    </Popover>
  );
};

