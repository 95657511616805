import React, { useState } from 'react';
import BlogSidebar from './BlogSidebar.js';
import CreateBlogForm from './createblogimage/outletform/CreateBlogForm.js';
import Bloglist from "../createBlog/createblogimage/outletform/Bloglist.js";
import ViewSection from './createblogimage/outletform/ViewSection.js';
import SupportView from './createblogimage/outletform/SupportView.js';
import { Helmet } from 'react-helmet';


const CreateBlog = () => {
  const [activeSection, setActiveSection] = useState(null);

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  return (
    <>
      <Helmet>
        <title>Create Blog | IntGrow Corp.</title>
        <meta property="og:title" content="Dashboard" />
        <meta property="og:url" content="https://alpha.intgrow.co/dashboard" />
        <meta property="og:type" content="information" />
        <meta property="og:description" content="what we serve" />
        <meta name="keywords" content="HS Code, duty calculator, duty saver " />
      </Helmet>
      <div className="main-container">
        <BlogSidebar onSectionChange={handleSectionChange} />
        <div className="content">
          {activeSection === 'create' && <CreateBlogForm />}
          {activeSection === 'blog-list' && <Bloglist />}
          {activeSection === 'view' && <ViewSection />}
          {activeSection === 'view-support' && <SupportView />}

        </div>

      </div>
    </>
  );
};

export default CreateBlog;
