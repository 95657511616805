import React from 'react';
import { Card, CardMedia, CardContent, Typography, Avatar, Box, Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { tokens } from "../../theme";

// Custom date formatting function
const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

// Helper function to determine file type
const isImage = (url) => {
  return /\.(jpg|jpeg|png|gif)$/i.test(url);
};

const isPDF = (url) => {
  return /\.pdf$/i.test(url);
};

const ViewTicketDetails = ({ selectedTicket }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Card sx={{margin: 'auto', mt: 5, borderRadius: 3, boxShadow: 5,display:"flex",
      flexDirection: {
        xs: 'column', 
        md: 'row-reverse' 
      }
     }}>
      {isImage(selectedTicket.attachment) && (
        <CardMedia
          component="img"
          height="200"
          image={selectedTicket.attachment}
          alt="Attachment"
          sx={{ borderRadius: '3px 3px 0 0' }}
        />
      )}
      {isPDF(selectedTicket.attachment) && (
        <Box
          component="iframe"
          src={selectedTicket.attachment}
          height="200"
          width="100%"
          sx={{ borderRadius: '3px 3px 0 0', border: 'none' }}
        />
      )}
      <CardContent sx={{width:{
         xs: '100%', 
         md: '50%' 
      }}}>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar sx={{ bgcolor: colors.primary[500], mr: 2,color:"#fff" }}>{selectedTicket.username.charAt(0)}</Avatar>
          <Typography variant="h6" component="div">
            {selectedTicket.username}
          </Typography>
        </Box>
        <Typography variant="body2" color="text.secondary" mb={1}>
          <strong>Email:</strong> {selectedTicket.email}
        </Typography>
        <Typography variant="body2" color="text.secondary" mb={1}>
          <strong>Category:</strong> {selectedTicket.category}
        </Typography>
        <Typography variant="body2" color="text.secondary" mb={1}>
          <strong>Message:</strong> {selectedTicket.message}
        </Typography>
        <Typography variant="body2" color="text.secondary" mb={1}>
          <strong>Status:</strong> <Chip label={selectedTicket.ticket_status} color={selectedTicket.ticket_status === 'Pending' ? 'warning' : 'success'} />
        </Typography>
        <Typography variant="body2" color="text.secondary" mb={1}>
          <strong>Created At:</strong> {formatDate(selectedTicket.createdAt)}
        </Typography>
        <Typography variant="body2" color="text.secondary" mb={1}>
          <strong>Updated At:</strong> {formatDate(selectedTicket.updatedAt)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ViewTicketDetails;
