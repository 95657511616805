import React from 'react';
import './faqAccordian.css';
import { Accordion, AccordionDetails, AccordionSummary, Grid,Box, Typography } from '@mui/material';
import { IoIosArrowDown } from "react-icons/io";

const FAQAccordionSection = ({accordionslist}) => {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box className="faqaccordionContainer">
            <Box>
                {accordionslist && accordionslist.map((item, index) => (
                    <Accordion key={item.id} expanded={expanded ===item.id} onChange={handleChange(item.id)}>
                        <AccordionSummary
                            expandIcon={<IoIosArrowDown fontSize={21} style={{ color: "#0C98BC" }} />}
                        >
                            <Typography variant='h5' fontSize={15} fontWeight={500}>
                                {item?.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography fontSize={15} sx={{
                                    padding: "0 10px",
                            }}>
                                {item?.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}

            </Box>
        </Box>
    );
}

export default FAQAccordionSection;
