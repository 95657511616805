import React from 'react'
import styles from './T&C.module.scss'
import { Box, Grid, Modal, Typography, Divider, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import CommonButton from '../CommonButton/CommonButton'

const TermsAndCond = ({ open, agree, handleAgree, handleAcept, handleReject, handleClose }) => {

    return (
        <Modal
            open={open}
            className={styles.termsAndCondContainer}
        >
            <Grid className={styles.termsAndCond}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant='h5' fontSize={21} fontWeight={600}>Terms and Conditions</Typography>
                    <IoMdClose fontSize={21} cursor={"pointer"} onClick={handleClose} />
                </Box>
                <Divider sx={{ marginBlock: 2 }} />
                <Grid>
                    <Typography>This website is established with the intention of facilitating international trade by providing information on the landed cost of products, inclusive of import taxes such as customs duties, local taxes, and anti-dumping duties etc;. This information serves as a guide for the importation and exportation of goods, aiming to assist users in saving financial resources. Access to the information on this website is granted through a paid subscription. Users are advised that utilizing the information for commercial transactions or any other purposes implies their acknowledgment, comprehension, and acceptance of the terms and conditions outlined by Intgrow.</Typography>
                    <Typography variant='h5' fontSize={18} fontWeight={600} marginBlock={2}>Site Publisher:</Typography>
                    <Typography>Managed by Intgrow Insights LLC, Wyoming USA</Typography>
                    <Typography variant='h5' fontSize={18} fontWeight={600} marginBlock={2}>DISCLAIMER</Typography>
                    <Typography>This platform disseminates information sourced from various entities including government agencies, ministries, institutions, and specialized providers. While diligent efforts are made to maintain accuracy and relevance, the dynamic nature of information necessitates acknowledgment that content may not always be current or precise unless explicitly stated.</Typography>
                    <Typography>The information provided on this portal, including any legal documentation, is not legally binding. Accordingly, users are encouraged to seek professional counsel or consult relevant authorities for specific guidance.</Typography>
                    <Typography>Intgrow Insights LLC assumes no responsibility for financial losses, damages, or legal repercussions arising from the utilization of information featured on this platform. External links provided are subject to differing terms and conditions, and the functionality of these links cannot be guaranteed.</Typography>
                    <Typography variant='h5' fontSize={18} fontWeight={600} marginBlock={2}>COPYRIGHT</Typography>
                    <Typography>Materials published on this platform are permitted for personal or internal use. However, reproduction or distribution of said materials to third parties requires acknowledgment of copyright ownership.</Typography>
                    <Typography>The reproduction of government entity logos featured on this website is prohibited without prior authorization. Requests for permission to reproduce logos or any other materials should be directed to Intgrow Insights LLC via the Contact Us form provided on this website.</Typography>
                </Grid>
                <Divider sx={{ marginBlock: 2 }} />
                <Box>
                    <FormGroup>
                        <FormControlLabel value={agree} onClick={handleAgree} required control={<Checkbox />} label="Agree"
                        sx={{color:"#000"}} />
                    </FormGroup>
                    <Button onClick={handleAcept}>OK</Button>
                </Box>
            </Grid>
        </Modal>
    )
}

export default TermsAndCond