import { Grid, Typography, Box } from '@mui/material'
import React from 'react'
import styles from './TermsAndConditions.module.scss'
import Footer from '../../components/footer/Footer'
import CommonBanner from '../../components/CommonBanner/CommonBanner';
import { PiArrowFatLinesRightFill } from "react-icons/pi";
import { temsData } from '../../data/terms'
import { GoDot } from "react-icons/go";
import Header from '../../components/header/Header'
import logo_img from '../../assest/Intgrow Logo Transpernt.png'
import { Helmet } from 'react-helmet';


const TermsAndConditions = () => {
    return (
        <>
          <Helmet>
                <title>Terms And Conditions | IntGrow Corp.</title>
            </Helmet>
        <Grid className={styles.termsAndConditionsContainer}>
            <Header
                logo={logo_img}
                color={"#444444"}
            />
            <CommonBanner
                heading={"Terms & Conditions"}
                subHeading={`Access to and use of INTGROW.Co (an USA business, SSN*********) is subject to the following terms, conditions and notices (the "Terms and Conditions").Intgrow Terms & Conditions. These T&Cs were last updated on 10 August, 2022.`}
            />
            <Grid className={styles.termsAndConditions} >
                {temsData.map((item) => {
                    return (
                        <Box>
                            <Typography variant='h4' fontSize={21} fontWeight={600} lineHeight={1.6}>{item.heading}</Typography>
                            {item?.para.map((item) => {
                                return (
                                    <Box >
                                        <Box display={"flex"} marginInlineStart={2}>
                                            <Box><PiArrowFatLinesRightFill style={{ marginBlockStart: 18 }} /></Box>
                                            <Typography fontSize={16} marginInlineStart={2} marginBlock={2}>{item.label}</Typography>
                                        </Box>
                                        <Box display={"flex"} flexDirection={"column"}>
                                            {
                                                item?.subleble?.map((item) => {
                                                    return (
                                                        <Box display={"flex"} marginInlineStart={10}>
                                                            <Box><GoDot style={{ marginBlockStart: "" }} /></Box>
                                                            <Box><Typography fontSize={15} marginBlockStart={1}>{item}</Typography></Box>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Box>
                    )
                })}
            </Grid>
            {/* <Subscribe /> */}
            <Footer />
        </Grid>
        </>
    )
}

export default TermsAndConditions
