import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SettingsIcon from '@mui/icons-material/Settings';
import styles from './CommonHeading.module.scss'
const CommonHeading = ({heading}) => {
    return (
        <Grid className={styles.CommonHeading}>
            <Box>
                <Typography variant='h4' fontSize={40} fontWeight={700}>{heading}</Typography>
            </Box>
        </Grid>
    )
}

export default CommonHeading