import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme,useMediaQuery } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CalculateIcon from '@mui/icons-material/Calculate';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import HomeIcon from '@mui/icons-material/Home';
import Logo from "../../components/Layout/Logo"
import { toFormData } from "axios";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === toFormData}
      style={{
        color: selected === to ? "#fff" : colors.grey[100],
        backgroundColor: selected === to ? "var(--intgrow-primary-color)" : 'transparent',
        borderRadius: "7px"
      }}
      onClick={() => setSelected(to)}
      icon={icon}
    >
      <Typography
        sx={{ fontWeight: "600", color: selected === to ? "#fff" : colors.typography[100] }}>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate()
  const path = location.pathname;
  const mode = theme.palette.mode
  const colors = tokens(mode);
  // const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState(path);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({})
  const toggleHistory = () => {
    setHistoryOpen(!historyOpen);
  };

  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isCollapsed, setIsCollapsed] = useState(isMobile);
  
  useEffect(() => {
    setIsCollapsed(isMobile);
  }, [isMobile]);

  useEffect(() => {
    setSelected(location.pathname)
  }, [location.pathname])

  useEffect(() => {
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    if (!userInfo) {
      navigate("/auth/login")
      return
    }
    const user = {
      id: userInfo.id,
      name: userInfo?.first_name + " " + userInfo?.last_name,
      email: userInfo?.email,
      role: userInfo?.role,
      photo_url: userInfo?.photo_url
    };
    setUserInfo(user)
  }, [])
  return (
    <Box
      sx={{
        // "& .pro-sidebar": {
        //   width: "248px",
        //   minWidth: "250px"
        // },

        "& .pro-sidebar-layout": {
          overflowY: "auto",
          height: "100vh !important"
        },
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 20px 5px 8px !important",

        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa ",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square"
          style={{ padding: "10px" }}>
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon
              sx={{ color: mode === "dark" ? "" : "#000" }} /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: "#fff",
            }}
          >

            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  <Logo logo={mode === "dark" ? "/assets/images/darkmode_logo.png" : "/assets/images/intgrow-transpernt-logo.png"} />
                  {/* <img src="/assets/logo2.png" width={100} alt="logo" /> */}
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="45px">
              {/* <Box display="flex" justifyContent="center" alignItems="center" mb="30px">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={userInfo?.photo_url || "/assets/images/profile.png"}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box> */}
              <Box textAlign="center">
                <Typography
                  variant="h3"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {userInfo?.name || userInfo?.email}
                </Typography>

              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="HS Code Finder"
              to="/hs-code-finder"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Duty Calculator"
              to="/duty-calculator"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
        
            <Item
              title="DutySaver Pro"
              to="/duty-saver-pro"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
              <Item
              title="Export Price Calculator"
              to="/offline-calculator"
              icon={<CalculateIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Current Plan"
              to="/current-plan"
              icon={<CurrencyExchangeIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Account"
              to="/account"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
  
            <MenuItem
              onClick={toggleHistory}
              icon={<CalendarTodayOutlinedIcon />}
              style={{
                color: historyOpen ? "#fff" : colors.grey[100],
                backgroundColor: historyOpen ? "var(--intgrow-primary-color)" : 'transparent',
                marginTop: "2px",
                borderRadius: "7px"
              }}
            >
              <Typography sx={{ fontWeight: "600", color: historyOpen ? "#fff" : colors.typography[100] }}>History</Typography>
            </MenuItem>
            {historyOpen && (
              <Box pl="20px" mt="10px">
                <Item
                  title="HS Code Finder"
                  to="/hs-code-finder-history"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Duty Calculator"
                  to="/duty-calculator-history"
                  icon={<ContactsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="DutySaver Pro"
                  to="/duty-saver-pro-history"
                  icon={<ReceiptOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </Box>
            )}
            <Item
              title="Home"
              to="/"
              icon={<HomeIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Help"
              to="help"
              icon={<HelpOutlineIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Support"
              to="support"
              icon={<SupportAgentIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
