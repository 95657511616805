import React from 'react';
import '../outletform/viewsection.css';

const ViewSection = () => {
  const blogs = [
    { id: 1, title: 'Import Duty Calculator', date: '2/26/2024' },
    { id: 2, title: 'blog content', date: '2/26/2024' },
    { id: 3, title: 'International Youth day', date: '2/26/2024' },
    { id: 4, title: 'Import Duty calculator', date: '2/26/2024' }
  ];

  return (
    <div className="view-section-container">
      <h1>All blog list</h1>
      <div className="pagination-info">
        <span>Showing 1 to 3 of 3 entries</span>
        <div className="pagination-buttons">
          <button className="previous-button">Previous</button>
          <button className="next-button">Next</button>
        </div>
      </div>
      
      <div className="bloglist-container">
        <div className="bloglist-table-wrapper">
          <table className="bloglist-table">
            <thead>
              <tr>
                <th>S.no.</th>
                <th>Blog Title</th>
                <th>Created At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {blogs.map((blog, index) => (
                <tr key={blog.id}>
                  <td>{index + 1}</td>
                  <td>{blog.title}</td>
                  <td>{blog.date}</td>
                  <td>
                    <button className="update-button">Update</button>
                    <button className="view-button">View</button>
                    <button className="delete-button">Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="pagination">
        <div className="rows-per-page">
          <span>Rows per page: <select><option>3</option><option>5</option><option>10</option></select></span>
        </div>
      </div>
    </div>
  );
};

export default ViewSection;
