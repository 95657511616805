import React from 'react'
import styles from './VideoModal.module.scss'
import { Grid, Modal, Typography } from '@mui/material'
import video from '../../assest/shiping_details.mp4'
import { IoMdClose } from "react-icons/io";

const VideoModal = ({ open, handleClose }) => {
    return (
        <Modal
            open={open}
            className={styles.videoModalContainer}
        >
            <Grid className={styles.videoModal}>
                <video controls autoPlay>
                    <source src={video} type='video/mp4' />
                </video>
                <IoMdClose fontSize={35} cursor={"pointer"} onClick={handleClose} />
            </Grid>
        </Modal>
    )
}

export default VideoModal