import React, { useEffect, useContext, useState } from 'react';
import { Box, Grid, useTheme, Typography, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from '@mui/material';
import { roundToNearestInteger, currencyConvert, igToLocale, formatNumberWithRegex, formatPrice, formattedDate } from '../../utils/utils';
import './dutysaver.style.css';
import InfoIcon from '@mui/icons-material/Info';
import SavedAmount from './SavedAmount';
import DisplayOriginRules from './DisplayOriginRules';
import { baseUrl } from '../../utils/baseURL';
import { tokens } from '../../theme'
import ShipmentSummary from '../DutyCalculator/ShipmentSummary';
import { HsCodeContext } from '../../context/HSCodeContext';

import styled from '@emotion/styled';
import TruncatedText from './TruncatedText';



const formatValue = (value, integerPartfontSize = '1.2rem', decimalPartPartfontSize = '0.9rem') => {
    const [integerPart, decimalPart] = value.toString().split('.');

    return (
        <>
            <span style={{ fontWeight: 'bold', fontSize: integerPartfontSize }}>{igToLocale(integerPart)}</span>
            {decimalPart && (
                <span style={{ fontWeight: 'normal', fontSize: decimalPartPartfontSize }}>{'.' + decimalPart?.slice(0, 5)}</span>
            )}
        </>
    );
};

const DutySaverTable = ({ saveDutyResponse, inputData }) => {
    const theme = useTheme()
    const mode = theme.palette.mode
    const colors = tokens(mode)
    const { hsCodeDetails } = useContext(HsCodeContext);
    const [rulesResponse, setRulesResponse] = useState([]);
    const { hsCode } = hsCodeDetails;

    const [openTableIndex, setOpenTableIndex] = useState(null);
    const [countryListResponse, setCountryListResponse] = useState(null);
    const [savedDutyDetails, setSavedDutyDetails] = useState([]);
    const { importingCountry, exportingCountry } = inputData;

    const handleStripClick = (index) => {
        setOpenTableIndex(prevIndex => (prevIndex === index ? null : index));
    };

    const getRulesOfOrigin = () => {
        try {
            let importCountry = importingCountry.value,
                exportCountry = exportingCountry.value,
                hs = hsCode
            const rulesOfOriginUrl = `${baseUrl}/country/rules?hs=${hs}&imp=${importCountry}&exp=${exportCountry}`;

            fetch(rulesOfOriginUrl)
                .then(function (response) {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Could not reach the origin rules API: ' + response.statusText);
                    }
                }).then(function (data) {
                    setRulesResponse(data);
                }).catch(function (error) {
                    console.error('Error occurred in origin rules api', error);
                });
        } catch (e) {
            console.error('Error in origin rules API => ', e);
        }
    }

    useEffect(() => {
        getRulesOfOrigin();
    }, []);


    const tableHeadCell = {
        backgroundColor: "#1994b7",
        textAlign: "center",
        color: "#fff",
        fontSize: "1rem",
        borderRight: "3px solid",
        borderLeft: "3px solid"

    };
    const BoldCell = styled(TableCell)`
 font-size: 16px;
 font-weight: 500;
 color:${colors.primaryColor[200]}

`;


    const LightLeftBorderCell = styled(TableCell)`
  font-size: 16px;
  font-weight: 400;
  border-left: 1px solid #dadada;
  color:${colors.primaryColor[200]}

`;

    const LighCell = styled(TableCell)`
  font-size: 16px;
  font-weight: 400;
  color:${colors.primaryColor[200]};
  color:${colors.primaryColor[200]}

`;
    const BoldGrayCell = styled(TableCell)`
 font-size: 16px;
 font-weight: 700;
 color: ${colors.primaryColor[200]};
 color:${colors.primaryColor[200]}

`;

    const tableHeadCellBL = {
        backgroundColor: "#1994b7",
        textAlign: "center",
        color: "#fff",
        fontSize: "1rem",
        borderRadius: "7px 0 0 0",
        borderRight: "1px solid #dadada",

    };
    const tableHeadCellBR = {
        backgroundColor: "#1994b7",
        textAlign: "center",
        color: "#fff",
        fontSize: "1rem",
        borderRadius: "0 7px 0 0"
    };

    const cyn = saveDutyResponse[0]?.[0]?.cyn;
    const getDutyResponse = saveDutyResponse[0]?.[0];
    const cynRateInfo = saveDutyResponse[0]?.[0]?.cyn_rate;
    const impCurrency = saveDutyResponse[0]?.[0]?.imp_cyn?.toUpperCase()

    // console.log("cynRateInfo", cynRateInfo, cyn)
    const assessable_value = saveDutyResponse[0]?.[0]?.getDuty.CIFVALUE;
    const getDutyTotal = saveDutyResponse[0]?.[0]?.getDuty.CIFVALUE + saveDutyResponse[0]?.[0]?.getDuty.total;

    const loadCountryList = () => {
        fetch(`${baseUrl}/country/search`).then(function (response) {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Could not reach the country ist API: ' + response.statusText);
            }
        }).then(function (data) {
            setCountryListResponse(data)
        }).catch(function (error) {
            console.error('Error in countryList fetch ', error);
        });
    }


    useEffect(() => {
        loadCountryList()
    }, [])

    useEffect(() => {
        let saved = [];
        if (saveDutyResponse && saveDutyResponse.length > 0) {
            saveDutyResponse.forEach(duty => {
                const dutyDetailsDesc = duty && duty[0] && duty[0].dutyDetails || [];
                const total = (duty[0].total + duty[0].CIFVALUE);
                // console.log("total", total)
                const key = Object.keys(duty[0].dutyDetails[1]).filter(o => o.includes('_dd'));
                saved.push(
                    {
                        total: total,
                        cyn: duty[0].cyn,
                        code: duty[0].dutyDetails[1][key],
                        hide_section: duty[0]?.hide_section
                    }
                );
            });
            setSavedDutyDetails(saved);
        }
    }, [saveDutyResponse]);

    return (
        <>
            {saveDutyResponse && saveDutyResponse.length > 0 && saveDutyResponse.map((duty, index) => {

                // console.log("saveDutyResponse", saveDutyResponse)


                const dutyDetailsDesc = duty && duty[0] && duty[0].dutyDetails || [];
                const dutyPreferencialDetailsDesc = duty && duty[0] && duty[0]?.getDuty?.dutyDetails || [];
                const total = (duty[0].total + duty[0].CIFVALUE);
                let cynConvertTotal = currencyConvert(total, cynRateInfo);
                let hideSection = duty[0]?.hide_section ? "hide_section" : "show_section";
                let hide_section = duty[0]?.hide_section;
                let dutyRows = [];
                let dutyPreferencialRows = [];

                let savedDuty = getDutyTotal - total;

                let ftaRule = "";

                let ftaId = [];
                let ftaLabel = '';
                let footnote_data = '';

                if (total > 0 && dutyDetailsDesc.length > 0) {
                    dutyRows = dutyDetailsDesc.map((ele, idx) => {
                        let getKey = Object.keys(ele).filter(e => e.match(/(_dd)$/))[0].match(/(^((?!mfn).)*$)/);
                        getKey = getKey && getKey[0];

                        if (getKey) {
                            var prefix = getKey.split('_dd')[0];
                            const _dd = ele[`${prefix}_dd`];
                            const _d = ele[`${prefix}_d`] || 0;
                            const _cl = ele[`${prefix}_cl`] || 0;
                            const _define = ele[`${prefix}_define`] || '';


                            // console.log("_cl",_cl)
                            if (!ftaRule) {
                                ftaId.push(prefix.split(`${prefix.split('_')[0]}_`)[1]);
                                ftaLabel = prefix.split(`${prefix.split('_')[0]}_`)[1];
                                footnote_data = duty[0][`${prefix}_f`];
                                ftaRule = _dd;
                            }

                            return (
                                <TableRow key={idx} className={(_d === 'hide_row') ? 'hide_row' : ''}>
                                    <LighCell>{_dd}
                                        <Tooltip title={_define} sx={{ marginLeft: "5px" }}>
                                            <InfoIcon
                                                sx={{
                                                    fontSize: '0.8em !important',
                                                }}
                                            />
                                        </Tooltip>
                                    </LighCell>
                                    <LightLeftBorderCell sx={{ borderLeft: "1px solid #ddd" }}>{_d}

                                    </LightLeftBorderCell>
                                    <LightLeftBorderCell sx={{ borderLeft: "1px solid #ddd" }}>{igToLocale(roundToNearestInteger(_cl))}</LightLeftBorderCell>
                                    {impCurrency !== cyn && (
                                        <LightLeftBorderCell sx={{ borderLeft: "1px solid #ddd" }}>{igToLocale((currencyConvert(_cl, cynRateInfo)))}</LightLeftBorderCell>
                                    )}

                                </TableRow>
                            );
                        }
                        return null;
                    });
                }

                if (total > 0 && dutyPreferencialDetailsDesc.length > 0) {
                    // console.log("dutyPreferencialDetailsDesc", dutyPreferencialDetailsDesc)
                    dutyPreferencialRows = dutyPreferencialDetailsDesc.map((ele, idx) => {
                        let getKey = Object.keys(ele).filter(e => e.match(/(_dd)$/))[0].match(/(^((?!mfn).)*$)/);
                        getKey = getKey && getKey[0];

                        const dutyKey = Object.keys(ele).find(key => key.endsWith('_dd'));
                        const prefix = dutyKey ? dutyKey.split('_dd')[0] : '';
                        if (!prefix) return null;
                        if (prefix) {
                            // const prefix = getKey.split('_dd')[0];
                            const _dd = ele[`${prefix}_dd`];
                            const _d = ele[`${prefix}_d`];
                            const _cl = ele[`${prefix}_cl`];
                            const _define = ele[`${prefix}_define`] || '';

                            // if (!ftaRule) {
                            //     ftaId.push(prefix.split(`${prefix.split('_')[0]}_`)[1]);
                            //     ftaLabel = prefix.split(`${prefix.split('_')[0]}_`)[1];
                            //     footnote_data = duty[0][`${prefix}_f`] + "<br/>";
                            //     ftaRule = _dd;
                            // }
                            // console.log("check fta rule", _dd)

                            return (
                                <TableRow key={idx} className={_d == 'hide_row' ? 'hide_row' : ''}>
                                    <LighCell>
                                        <Tooltip title={_define} >
                                            {_dd}
                                            <InfoIcon
                                                sx={{
                                                    fontSize: '0.8em !important',
                                                }}
                                            />
                                        </Tooltip>

                                    </LighCell>
                                    <LightLeftBorderCell sx={{ borderLeft: "1px solid #ddd" }}>{_d}</LightLeftBorderCell>
                                    <LightLeftBorderCell sx={{ borderLeft: "1px solid #ddd" }}>{igToLocale(roundToNearestInteger(_cl))}</LightLeftBorderCell>
                                    {impCurrency !== cyn && (
                                        <LightLeftBorderCell sx={{ borderLeft: "1px solid #ddd" }}>{igToLocale((currencyConvert(_cl, cynRateInfo)))}</LightLeftBorderCell>
                                    )}

                                </TableRow>
                            );
                        }
                        return null;
                    });
                }


                // console.log("ftaRule2", ftaRule)
                const dutyTotal = duty[0].total || 0;
                const saverTotal = duty[0]?.getDuty?.total || 0
                const totalPrecision = impCurrency?.toLowerCase() !== cyn?.toLowerCase() && currencyConvert(dutyTotal, cynRateInfo);
                const totalSaverPrecision = impCurrency?.toLowerCase() !== cyn?.toLowerCase() && currencyConvert(saverTotal, cynRateInfo);

                return (
                    <React.Fragment key={index}>
                        <div className={hideSection} style={{
                            backgroundColor: colors.primary[400],
                            borderRadius: "7px",
                            marginBottom: "40px"

                        }}>
                            <Typography variant="h6" className='DutySaverSrtipes' sx={{ marginBottom: "20px", backgroundColor: "#ff6500" }}
                            >Save {formatValue(savedDuty)} {impCurrency} {impCurrency !== cyn && (
                                <>{formatValue(currencyConvert(savedDuty, cynRateInfo))} {cyn}</>)} Landing Cost
                            </Typography>

                            <div>
                                <Box sx={{
                                    display: "flex", gap: "5px", 
                                    flexDirection: {
                                        xs: "column",
                                        md: "row",
                                    },
                                }}>

                                    <Table
                                        sx={{
                                            backgroundColor: colors.primary[400],
                                            border: "1px solid #dadada",
                                            borderRadius: "7px !important",
                                        }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={tableHeadCellBL}>Breakdown</TableCell>
                                                <TableCell sx={tableHeadCellBR} colSpan={3}>Standard Tariff(s)
                                                </TableCell>
                                            </TableRow>
                                            <TableRow
                                                sx={{
                                                    backgroundColor: colors.primary[400]
                                                }}>
                                                <BoldCell>Duty Details</BoldCell>
                                                <BoldCell sx={{ borderLeft: "1px solid #ddd" }}>Duty Rate <br /> (in %)
                                                    <Tooltip title={`Tariff(s) are expressed in "%" unless otherwise specified`}>
                                                        <InfoIcon />
                                                    </Tooltip>
                                                </BoldCell>
                                                <BoldCell sx={{ borderLeft: "1px solid #ddd" }}>Duty Amount <br /> (in {impCurrency})</BoldCell>
                                                {
                                                    impCurrency !== cyn && (
                                                        <BoldCell sx={{ borderLeft: "1px solid #ddd" }}>Duty Amount  <br />(in {cyn})</BoldCell>
                                                    )
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dutyPreferencialRows}

                                            <TableRow>
                                                <LighCell colSpan={2} >Total payable duties and taxes</LighCell>
                                                <BoldGrayCell className="text-right">{igToLocale(roundToNearestInteger(saverTotal))}</BoldGrayCell>
                                                {totalSaverPrecision && (
                                                    <BoldGrayCell className="text-right">{igToLocale(roundToNearestInteger(totalSaverPrecision))}</BoldGrayCell>
                                                )}
                                            </TableRow>
                                        </TableBody>
                                    </Table>

                                    <Table
                                        sx={{
                                            backgroundColor: colors.primary[400],
                                            border: "1px solid #dadada",
                                            borderRadius: "7px !important",
                                        }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={tableHeadCellBL}>Breakdown</TableCell>
                                                <TableCell sx={tableHeadCellBR} colSpan={3}>Preferential Tariff(s)
                                                </TableCell>
                                            </TableRow>
                                            <TableRow sx={{
                                                backgroundColor: colors.primary[400]
                                            }}>
                                                <BoldCell>Duty Details</BoldCell>
                                                <BoldCell sx={{ borderLeft: "1px solid #ddd" }}>Duty Rate <br /> (in %)
                                                    <Tooltip title={`Tariff(s) are expressed in "%" unless otherwise specified`}>
                                                        <InfoIcon />
                                                    </Tooltip>
                                                </BoldCell>
                                                <BoldCell sx={{ borderLeft: "1px solid #ddd" }}>Duty Amount <br /> (in {impCurrency})</BoldCell>
                                                {
                                                    impCurrency !== cyn && (
                                                        <BoldCell sx={{ borderLeft: "1px solid #ddd" }}>Duty Amount  <br />(in {cyn})</BoldCell>
                                                    )
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dutyRows}

                                            <TableRow>
                                                <LighCell colSpan={2} >Total payable duties and taxes</LighCell>
                                                <BoldGrayCell className="text-right">{igToLocale(roundToNearestInteger(dutyTotal))}</BoldGrayCell>
                                                {totalPrecision && (
                                                    <BoldGrayCell className="text-right">{igToLocale(roundToNearestInteger(totalPrecision))}</BoldGrayCell>
                                                )}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Box>

                                <section className='landed-cost-and-origin-rule'>
                                    <Grid container spacing={1}>
                                        <Grid item sx={12} md={8}>
                                            <SavedAmount cynRateInfo={cynRateInfo} saverTotal={saverTotal} totalSaverPrecision={totalSaverPrecision} savedDuty={savedDuty} impCurrency={impCurrency} cyn={cyn}
                                                ftaRule={ftaRule} duty={duty} getDutyTotal={getDutyTotal} />
                                            <div className="saver-pro-landed-cost">
                                                <div className="tnc-note-1">
                                                    <Box sx={{ display: "flex", gap: "5px" }}>
                                                        <Typography className="landed-cost-main" sx={{ color: colors.primaryColor[100] }}>Landed Cost: {formatValue(roundToNearestInteger(total, impCurrency))} {impCurrency}</Typography>
                                                        {impCurrency !== cyn && (
                                                            <Typography className="landed-cost-main-2" sx={{ color: colors.primaryColor[100] }}>({formatValue(roundToNearestInteger(cynConvertTotal, cyn), "16px", "10px")} {cyn})</Typography>

                                                        )}
                                                    </Box>

                                                    <p className="landed-cost-sub-1">
                                                        Landed Cost({impCurrency}) = {igToLocale(assessable_value)}{' '}
                                                        <span className="lc-imp-ass-val">(Assessable value)</span> + {igToLocale(duty[0].total)}{' '}
                                                        <span className="lc-imp-tot-val">(Total Duty)</span>
                                                    </p>
                                                    <p className="landed-cost-sub-2">
                                                        Landed Cost({cyn}) = {igToLocale(currencyConvert(assessable_value, cynRateInfo))}{' '}
                                                        <span className="lc-exp-ass-val">(Assessable value)</span> + {igToLocale(currencyConvert(duty[0].total, cynRateInfo))}{' '}
                                                        <span className="lc-imp-tot-val">(Total Duty)</span>
                                                    </p>
                                                </div>
                                                <div className="row">
                                                    <div className="landed-cost-body-1">
                                                        <i>*Excluding destination freight, destination charges, and intermediaries margin (importer, wholesaler, etc.)</i>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item sx={12} md={4}>
                                            <DisplayOriginRules ftaIds={ftaId} rulesResponse={rulesResponse} inputData={inputData} countryListResponse={countryListResponse} />
                                        </Grid>
                                        {
                                            footnote_data &&
                                            <Grid item xs={12} md={12}>
                                                <Box boxShadow={3} borderRadius={5} sx={{
                                                    padding: "10px 0",
                                                }}>

                                                    <Typography variant="h6" className='footnotes'>Note:</Typography>
                                                    <p className='saver-notes' >
                                                        <TruncatedText text={footnote_data} />
                                                    </p>
                                                </Box>
                                            </Grid>
                                        }

                                    </Grid>
                                </section>
                            </div>
                        </div>
                    </React.Fragment>
                );
            })}

            <Grid container spacing={1} justifyContent="space-between">
                <Grid item sx={12} md={3.5}>
                    <ShipmentSummary inputData={inputData} impCurrency={impCurrency} getDutyResponse={getDutyResponse} cynRateInfo={cynRateInfo} />
                </Grid>

                <Grid item sx={12} md={8.5}>
                    <Grid container spacing={1} justifyContent="space-between" sx={{
                        backgroundColor: colors.primary[400],
                        borderRadius: "7px"
                    }}>
                        {(savedDutyDetails && savedDutyDetails.length > 0) &&
                            savedDutyDetails.map((s, index) => {
                                // const saved = getDutyTotal - s.total;
                                const saved = Math.abs(getDutyTotal - s.total);

                                return (
                                    <Grid item sx={12} md={6}
                                        key={index}
                                        className={`fta-summary-header-row ${s?.hide_section ? 'hide_section' : ''}`}
                                    >
                                        <div style={{ border: "1px solid #ddd", borderRadius: "8px" }}>
                                            {/* <Typography className='fta-summary-under'>Option {index+1}</Typography> */}
                                            <Typography className='fta-summary-under'>Duty Saved Options</Typography>
                                            <Grid container>
                                                <Grid item sx={6} md={6}>
                                                    <Typography className='fta-summary-under-header'>Total Import Duty</Typography>
                                                    <div className="fta-summary-data-value">
                                                        <p>{formatValue(s.total, "1rem", "0.7rem")} {impCurrency}<br /></p>
                                                        {
                                                            impCurrency !== cyn && <p>{formatValue(currencyConvert(s.total, cynRateInfo), "1rem", "0.7rem")} {cyn}</p>
                                                        }
                                                    </div>
                                                </Grid>
                                                <Grid item sx={6} md={6}>
                                                    <Typography className='fta-summary-under-header'>Duty Saved</Typography>
                                                    <div className="fta-summary-data-value">
                                                        <p>{formatValue(saved, "1rem", "0.7rem")} {impCurrency}<br /></p>
                                                        {
                                                            impCurrency !== cyn && <p>{formatValue(currencyConvert(saved, cynRateInfo), "1rem", "0.7rem")} {cyn}</p>
                                                        }
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </Grid>

            </Grid>
        </>
    );
};

export default DutySaverTable;
