import React, { useEffect } from 'react'
import styles from './Pricing.module.scss'
import { Grid, Box } from '@mui/material'
import Header from '../../components/header/Header'
// import logo from '../../assest/Intgrow Logo Transpernt.png'
import logo_1 from '../../assest/Intgrow Logo Transpernt.png'
import logo from '../../assest/Intgrow_logo.png'
import Footer from '../../components/footer/Footer'
import CommonBanner from '../../components/CommonBanner/CommonBanner'
import { Helmet } from 'react-helmet';

const Pricing = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <>
            <Helmet>
                <title>Pricing | IntGrow Corp.</title>
            </Helmet>
            <Box sx={{
                backgroundColor: "var(--intgrow-primary-color)",
                position: "sticky",
                top: 0,
            }}>
                <Header logo={logo}
                    logo_1={logo_1}
                    color={"#ffffff"} />
            </Box>
            <CommonBanner
                heading={"Our Pricing"}
                subHeading={"Simple, affordable, self-serving, cancel whenever."}
            />

                <Grid className={styles.pricing}>
                    <stripe-pricing-table pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
                        publishable-key={process.env.REACT_APP_PUBLISHIABL_KEY}>
                    </stripe-pricing-table>
                </Grid>
                <Footer />
        </>
    )
}

export default Pricing
