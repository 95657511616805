import React, { useState } from 'react'
import PinIcon from '@mui/icons-material/Pin';
import {} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Box, Button, Link, Stack, TextField,
    Typography, InputAdornment, useTheme,Modal,CircularProgress
} from '@mui/material';
import { baseUrl } from '../../utils/baseURL';
import { useNavigate } from 'react-router-dom';
import { tokens } from '../../theme'
import VerifyOtpAndRecaptcha from './VerifyOtpAndRecaptcha';

const LoginWithMobile = () => {
    const theme = useTheme();
    const mode = theme.palette.mode
    const colors = tokens(mode)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [openOtpModal,setOpenModel]=useState(false)
    const formik = useFormik({
        initialValues: {
            mobile: '',
            submit: null
        },
        validationSchema: Yup.object({
            mobile: Yup
                .string()
                .matches(/^\d{7,14}$/, 'Mobile number must be exactly greater that 6 and less than 15 digit')
                .required('Mobile No. is required'),
        }),

        onSubmit: async (values, helpers) => {
            setLoading(true);
            try {
                const response = await fetch(`${baseUrl}/auth/login-with/mobile/number`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...values }),
                });
                if (!response.ok) {
                    const responseData = await response.json();
                    const loginError = new Error(responseData.error || 'An error occurred');
                    loginError.status = response.status;
                    setLoading(false);
                    throw loginError;
                }

                const responseData = await response.json();
                // console.log("mobile", responseData)
                window.localStorage.setItem('mobile', values.mobile);
                window.localStorage.setItem('orderId', responseData?.data?.orderId);
                setLoading(false);
                setOpenModel(true)
            } catch (err) {
                setLoading(false);
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);

            }
        }
    });


const onClose=()=>{
    setOpenModel(false)
}

    return (
        <div className='login-with-mobile-section'>
            <form
                noValidate
                onSubmit={formik.handleSubmit}
            >
                <Stack spacing={3}
                    sx={{ color: "#444B59" }}>
                    <TextField
                        error={!!(formik.touched.mobile && formik.errors.mobile)}
                        fullWidth
                        helperText={formik.touched.mobile && formik.errors.mobile}
                        label="Mobile Number"
                        name="mobile"
                        id="outlined-required"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="number"
                        value={formik.values.mobile}
                        sx={{
                            color: "#444B59",
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: theme.palette.mode === "dark" ? "#a19191" : "#a19191",
                                },
                                "&:hover fieldset": {
                                    borderColor: theme.palette.mode === "dark" ? "#a19191" : "#a19191",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: theme.palette.mode === "dark" ? "#a19191" : "#a19191",
                                },
                            },
                            "& .MuiInputLabel-root": {
                                color: theme.palette.mode === "dark" ? "#fff" : "#0E588C",
                            },
                            "& .MuiInputAdornment-root svg": {
                                color: theme.palette.mode === "dark" ? "#fff" : "#0E588C",

                            },
                        }}
                        InputLabelProps={{
                            style: { color: theme.palette.mode === "dark" ? "#fff" : "#0E588C", } // Change 'red' to the desired color
                        }}
                        InputProps={{
                            style: {
                                color: "#0E588C",
                                fontSize: "medium",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal",
                                width: "100%",
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <PinIcon sx={{ color: "#0E588C", marginTop: "0px" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                    }}>
                        <Button sx={{ color: "white", textTransform: "capitalize" }}>
                            <Link
                                component={Link}
                                href="/auth/forgot-password"
                                underline="hover"
                                variant="subtitle2"
                                color="#0E588C"
                            >
                                Forgot Password
                            </Link>
                        </Button>
                    </Box>
                    {formik.errors.submit && (
                        <Typography
                            color="error"
                            sx={{ mt: 1 }}
                            variant="body2"
                        >
                            {formik.errors.submit}
                        </Typography>
                    )}
                </Stack>
                <div className='login-with-mobile'>
                    <button type='submit' className={theme.palette.mode === "dark" ? "btn btn-secondary" : "btn btn-primary"}
                    style={{width: "100%"}}>Send OTP</button>
                </div>
            </form>
            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            )}


            <Modal open={openOtpModal} onClose={onClose}>
                <Box sx={{
                    p: 4, backgroundColor: colors.primary[400], borderRadius: 2, maxWidth: 800,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    overflow: "auto",
                    height: "450px",
                    border:"none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",

                }}>
                   
                     <VerifyOtpAndRecaptcha/> 
                </Box>
            </Modal >
        </div>
    )
}

export default LoginWithMobile
