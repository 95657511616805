import { Grid, Typography } from '@mui/material'
import React from 'react'
import Blog from '../../components/blog/Blog'
import { Helmet } from 'react-helmet';
import Footer from '../../components/footer/Footer';

const BlogPage = () => {
    return (
        <div >
            <Helmet>
                <title>Blog | IntGrow Corp.</title>
                <meta property="og:title" content="Blog" />
                <meta property="og:url" content="https://intgrow.co/blog" />
                <meta property="og:type" content="information" />
                <meta property="og:description" content="what we serve" />
                <meta name="keywords" content="HS Code, duty calculator, duty saver " />
            </Helmet>
            <Blog />
            <Footer/>
        </div>
    )
}

export default BlogPage