import React from 'react'
import DutySaverPro from '../../../adminPanel/DutySaverPro/DutySaverPro'
import { Helmet } from 'react-helmet';
import DisclaimerStrip from '../../../components/Disclaimer/DisclaimerStripe';
import { Box, Typography } from '@mui/material';
import CommonFAQAccordionSection from '../../../components/AccordionSection/CommonFAQAccordian';
import DutyCalculator from '../../../adminPanel/DutyCalculator/DutyCalculator';

const DutySaverProPage = () => {
  return (
    <>
      <Helmet>
        <title>Duty Saver Pro | IntGrow Corp.</title>
        <meta property="og:title" content="DutySaver Pro" />
        <meta property="og:url" content="https://alpha.intgrow.co/dashboard" />
        <meta property="og:type" content="information" />
        <meta property="og:description" content="what we serve" />
        <meta name="keywords" content="HS Code, duty calculator, duty saver " />
      </Helmet>

      {/* <DutySaverPro /> */}
      <Box sx={{
        margin:{
          lg:"20px",
          sm:"10px",
          sx:"5px"
        }
      }}>
        <Box display="flex" justifyContent="space-between" alignItems="center"
          sx={{
            marginBottom: "7px"
          }}>
          <Typography variant="h1" component="h2"
            sx={{
              fontWeight: "700",
              fontSize: "48px"
            }}>DutySaver Pro</Typography>
        </Box>
        <DutyCalculator />
        <CommonFAQAccordionSection section="duty saver pro" />
        <DisclaimerStrip />
      </Box>

    </>
  )
}

export default DutySaverProPage
