import React from 'react'

import styles from './SimpleCurcle.module.scss'
import { Grid } from '@mui/material'


const SimpleCurcle = ({ size, border }) => {
  return (
    <Grid className={styles.simpleCurcle} sx={{ width: size, height: size, border: `${border}px solid #0C98BC` }}>
    </Grid>
  )
}

export default SimpleCurcle