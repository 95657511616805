import React, { useState, useEffect } from 'react';
import {
  Box, useTheme, Typography, Button, TextField, Table, TableBody, TableCell, TableHead, TableRow,
  Tooltip, Grid
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import styled from '@emotion/styled';

import { tokens } from '../../theme'
import { roundToNearestInteger, currencyConvert, igToLocale, formattedDate } from '../../utils/utils';
import DisabledInputField from '../../components/InputField/disabledInputField';
import ShipmentSummary from './ShipmentSummary';
import './dutycalculator.style.css'
import { baseUrl } from '../../utils/baseURL';
import FootNotesTab from './FootNotesTab';
import FormatedCalcValue from './FormateCalcValue';
const getNotesLabelsUrl = `${baseUrl}/dutyCalculator/getNotesLabels?import_country=`;

const formatValue = (value, integerPartfontSize = '1.2rem', decimalPartPartfontSize = '0.9rem') => {
  const [integerPart, decimalPart] = value.toString().split('.');

  return (
    <>
      <span style={{ fontWeight: 'bold', fontSize: integerPartfontSize }}>{igToLocale(integerPart)}</span>
      {decimalPart && (
        <span style={{ fontWeight: 'normal', fontSize: decimalPartPartfontSize }}>{'.' + decimalPart?.slice(0, 5)}</span>
      )}
    </>
  );
};


const DisplayGetDuty = ({ getDutyResponse, handleDutySaverProResultPage, handleModify, setScrollToTop, inputData, exportingCountry, currencyResponse }) => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const colors = tokens(mode)

  const [totalDuty, setTotalDuty] = useState(0);
  const [footnoteData, setFootnoteData] = useState("");
  const [notesLabelData, setNotesLabelData] = useState("");
  const [impCurrency, setImpCurrency] = useState("");


  useEffect(() => {
    const element = document.getElementById('scroll-get-duty');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [getDutyResponse]);

  const BoldCell = styled(TableCell)`
 font-size: 16px;
  font-weight: 500;
  color:${colors.primaryColor[100]}
`;

  const BoldGrayCell = styled(TableCell)`
 font-size: 16px;
  font-weight: 700;
  color:${colors.primaryColor[200]}
`;

  const LightLeftBorderCell = styled(TableCell)`
  font-size: 16px;
  font-weight: 400;
  border-left: 1px solid #dadada;
  color:${colors.primaryColor[200]}
`;

  const LighCell = styled(TableCell)`
  font-size: 16px;
  font-weight: 400;
  color:${colors.primaryColor[200]}
`;
  const cyn = getDutyResponse?.cyn
  let getdutyTotal = roundToNearestInteger(getDutyResponse.total + getDutyResponse.CIFVALUE);
  let cynRateInfo = currencyResponse && currencyResponse.find(c => c.currency === cyn);



  const fetchFootNotesData = async () => {
    const _imp = (localStorage.getItem('imp') || getDutyResponse.import_country).toLowerCase();
    const _exp = (localStorage.getItem('imp') || getDutyResponse.export_country).toLowerCase();

    const footnoteRes = await axios.get(`${baseUrl}/footnote?hs=${getDutyResponse?.hs}&imp=${_imp}&exp=${_exp}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }

    });

    setFootnoteData(footnoteRes?.data);
  };

  useEffect(() => {
    currencyResponse && currencyResponse.forEach(c => {
      if (c.unit == c.value) {
        setImpCurrency(c.currency);
      }
    });

    const fetchNotesLabelsData = async () => {
      const _imp = (localStorage.getItem('imp') || getDutyResponse.import_country).toLowerCase();
      const notesLabelsRes = await axios.get(`${getNotesLabelsUrl}${_imp}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }

      });
      const notesData = {};
      notesLabelsRes.data.forEach((n) => {
        notesData[n.duty_code] = n.duty_short_name;
      });
      const footnotes = Object.keys(getDutyResponse).reduce((acc, key) => {
        if (key.endsWith('_f')) {
          let prefix = (key.split('_f')[0] + "").replace(new RegExp(getDutyResponse.import_country, 'img'), '').replace(new RegExp(getDutyResponse.export_country, 'img'), '').replace(/^_+|_+$/g, '');
          let value = getDutyResponse[key].trim();
          if (value) {

            acc += `<li class="footnotes-list"><strong>${notesData[prefix]}</strong><br/>${value}</li>`;
          }
        }
        return acc;
      }, '');

      setNotesLabelData(footnotes);
    };

    fetchNotesLabelsData();
    fetchFootNotesData()

    let dutyTotal = roundToNearestInteger(getDutyResponse.total + getDutyResponse.CIFVALUE);
    setTotalDuty(currencyConvert(dutyTotal, getDutyResponse.cyn_rate));

  }, []);

  const handleDutyCalculation = () => {
    // Implement duty calculation logic
  };

  return (
    <Box id="scroll-get-duty" sx={{
      margin: {
        xs: "0px",
        sm: "5px",
        md: "25px",
      },
      padding: "10px",
    }}>
      <Grid container spacing={2} sx={{
        backgroundColor: colors.primary[400],
        padding: "25px 5px",
        borderRadius: "7px",
      }}>
        <Grid item xs={12} sm={6} md={6} lg={2.5}>
          <DisabledInputField label="Exporting Country" value={exportingCountry?.label || ""} countryCode={exportingCountry?.flag} disabled />

        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={2.5}>
          <Typography className="duty-calc-label">Currency</Typography>
          <TextField value={getDutyResponse?.cyn} disabled fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Typography className="duty-calc-label">Value of Product</Typography>
          <TextField type="number" value={getDutyResponse.CIF} disabled fullWidth />
        </Grid>
        <Grid item xs={12} sm={4} md={6} lg={2}>
          <Typography className="duty-calc-label" sx={{ visibility: "hidden" }}>Get Result</Typography>
          <Button sx={{
            padding: "15px 5px", backgroundColor: "var(--intgrow-primary-color)", color: "#fff",
            '&:hover': { backgroundColor: 'var(--intgrow-primary-color) !important' }

          }} variant="outlined" onClick={handleDutyCalculation} fullWidth>Get Result</Button>
        </Grid>
        <Grid item xs={12} sm={2} md={6} lg={2}>
          <Typography className="duty-calc-label" sx={{ visibility: "hidden" }}>Get Result</Typography>
          <Button sx={{
            padding: "15px 5px", backgroundColor: "var(--intgrow-primary-color)", color: "#fff",
            '&:hover': { backgroundColor: 'var(--intgrow-primary-color) !important' }

          }} variant="outlined" onClick={handleModify} fullWidth>Modify</Button>

        </Grid>
      </Grid>

      {/* <Typography variant="body2">Please click on the "Modify" button to change Exporting Country, Currency, and Value of Products for faster results.</Typography> */}
      <div className="tnc-note-3">
        This total landed cost calculation is applicable as of {formattedDate()}. Foreign exchange rates are revised in accordance with notifications from the importing country.
      </div>

      <Grid container spacing={2} >
        <Grid item sm={12} md={8} my={4} width={"100%"}>
          <Box sx={{
            backgroundColor: colors.primary[400],
            borderRadius: "7px",
            overflowX: "auto",
          }}>
            {/* <Typography className='BreakDownDutyTax'>Breakdown of Duties and Taxes</Typography> */}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className='BreakDownDutyTax' colSpan={4} sx={{ color: "#fff" }}>Breakdown of Duties and Taxes</TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    backgroundColor: colors.primary[400]
                  }}>
                  <BoldCell>Duty Details</BoldCell>
                  <BoldCell sx={{ borderLeft: "1px solid #888" }}>Duty Rate <br />(in %)
                    <Tooltip title={`Tariff(s) are expressed in "%" unless otherwise specified`}>
                      <InfoIcon />
                    </Tooltip>
                  </BoldCell>
                  <BoldCell sx={{ borderLeft: "1px solid #888" }}>Duty Amount <br /> (in {(getDutyResponse.imp_cyn).toUpperCase()})</BoldCell>
                  {getDutyResponse.imp_cyn?.toLowerCase() !== getDutyResponse.cyn?.toLowerCase() && (
                    <BoldCell sx={{ borderLeft: "1px solid #888" }}>Duty Amount <br /> (in {getDutyResponse.cyn})</BoldCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {getDutyResponse.dutyDetails.map((duty, index) => {
                  // Extracting the dynamic key as in the string-based formatting
                  const dutyKey = Object.keys(duty).find(key => key.endsWith('_dd'));
                  const prefix = dutyKey ? dutyKey.split('_dd')[0] : '';

                  // Guard clause for valid prefix
                  if (!prefix) return null;

                  return (
                    <TableRow key={index} className={prefix && duty[`${prefix}_d`]}>
                      <LighCell >{duty[`${prefix}_dd`]}
                        <Tooltip title={duty[`${prefix}_define`]}>
                          <InfoIcon />
                        </Tooltip>
                      </LighCell>
                      <LightLeftBorderCell >{duty[`${prefix}_d`]}</LightLeftBorderCell>
                      <LightLeftBorderCell >{duty[`${prefix}_cl`] && igToLocale(roundToNearestInteger(duty[`${prefix}_cl`]))}</LightLeftBorderCell>
                      {getDutyResponse.imp_cyn?.toLowerCase() !== getDutyResponse.cyn?.toLowerCase() && (
                        <LightLeftBorderCell >{duty[`${prefix}_cl`] && igToLocale(currencyConvert(duty[`${prefix}_cl`], getDutyResponse.cyn_rate))}</LightLeftBorderCell>
                      )}
                    </TableRow>
                  );
                })}
                <TableRow>
                  <LighCell colSpan={2}>Total payable duties and taxes</LighCell>
                  <BoldGrayCell>
                    <FormatedCalcValue value={igToLocale(roundToNearestInteger(getDutyResponse.total))}
                      cssStyle={{
                        fontWeight: '700',
                        fontSize: '16px',
                        fontSize2: "12px",
                        color: colors.primaryColor[200]
                      }} />
                    {/* {igToLocale(roundToNearestInteger(getDutyResponse.total))} */}
                  </BoldGrayCell>
                  {getDutyResponse.imp_cyn?.toLowerCase() !== getDutyResponse.cyn?.toLowerCase() && (
                    <BoldGrayCell >
                       <FormatedCalcValue value={igToLocale(currencyConvert(getDutyResponse.total, getDutyResponse.cyn_rate))}
                      cssStyle={{
                        fontWeight: '700',
                        fontSize: '16px',
                        fontSize2: "12px",
                        color: colors.primaryColor[200]
                      }} />
                      
                      {/* {igToLocale(currencyConvert(getDutyResponse.total, getDutyResponse.cyn_rate))} */}
                      </BoldGrayCell>
                  )}

                </TableRow>
              </TableBody>
            </Table>
          </Box>


          <Box sx={{
            backgroundColor: colors.primary[400],
            margin: "20px 0px",
            padding: "25px 10px",
            borderRadius: "7px"
          }}>
            <Box sx={{ display: "flex", gap: "5px" }}>
              <Typography className="landed-cost-main">Landed Cost: {formatValue(getdutyTotal)} {impCurrency}</Typography>
              {impCurrency !== getDutyResponse.cyn && (
                <Typography className="landed-cost-main-2">({formatValue(totalDuty, "16px", "10px")} {getDutyResponse.cyn})</Typography>
              )}
            </Box>
            <Typography className={mode === "dark" ? "dark-landed-cost-sub" : "landed-cost-sub"} >Landed Cost = Assessable value + Total Duty</Typography>
            <Typography className={mode === "dark" ? "dark-landed-cost-body-1" : "landed-cost-body-1"}>*Excluding destination freight, destination charges and intermediaries margin (importer, wholesaler, etc.)</Typography>
            {/* <Typography className="landed-cost-body-2">This total landed cost calculation is applicable as of {new Date().toLocaleDateString()}. Foreign exchange rates are revised in accordance with notifications from the importing country.</Typography> */}

          </Box>

          {notesLabelData && (
            <Box sx={{
              backgroundColor: colors.primary[400],
              margin: "20px 0px",
              padding: "10px",
              borderRadius: "7px"
            }}>
              <Typography variant="h6" className='footnotes'>Note:</Typography>
              <ul dangerouslySetInnerHTML={{ __html: notesLabelData }} className='order' />
            </Box>
          )}

          {footnoteData &&
            <FootNotesTab footNoteData={footnoteData} />
          }
          <Button sx={{
            padding: "10px",
            backgroundColor: "#1994b7",
            color: "#fff",
            borderRadius: "7px",
            width: "100%",
            fontWeight: "700",
            fontSize: "24px",
            '&:hover': {
              backgroundColor: "#1994b7"
            }
          }}
            onClick={handleDutySaverProResultPage}
          >
            DutySaver Pro
          </Button>
        </Grid>
        <Grid item sm={12} md={4} my={4}>
          <Box sx={{
            backgroundColor: colors.primary[400],
            borderRadius: "7px"
          }}>
            <ShipmentSummary inputData={inputData} impCurrency={impCurrency} getDutyResponse={getDutyResponse} cynRateInfo={cynRateInfo} />
          </Box>
        </Grid>

      </Grid>

    </Box >
  );
};

export default DisplayGetDuty;
