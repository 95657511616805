import React, { useState, useEffect } from 'react';
import { Paper, Dialog, DialogTitle, DialogContent, Typography, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";

const MySubscriptionTable = ({ userOrderHistory }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [userOrderHistoryList, setUserOrderHistoryList] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    useEffect(() => {
        const getUserSubscriptionHistory = async () => {
            const formattedData = userOrderHistory && userOrderHistory.map((attr, index) => {
                const id = attr.id;
                const plan = attr.package;
                const purchaseDateTimestamp = attr?.transaction_date;
                const purchaseDate = new Date(purchaseDateTimestamp * 1000);
                const purchase_date = purchaseDate.toLocaleString();
                const transaction_method = attr?.transaction_method;
                const transaction_currency = attr?.transaction_currency?.toUpperCase();
                const value = (attr?.transaction_amount / 100);
                const details = attr
                return { id, plan, purchase_date, transaction_method, transaction_currency, value, details };
            });
            setUserOrderHistoryList(formattedData);
        }
        getUserSubscriptionHistory();
    }, [userOrderHistory]);

    const handleOpen = (record) => {
        setSelectedRecord(record);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedRecord(null);
    };

    const columns = [
        // { field: "id", headerName: "S.No.", flex: 0.3 },
        { field: "plan", headerName: "Plan", flex: 1 },
        { field: "purchase_date", headerName: "Purchase Date", flex: 1 },
        { field: "transaction_method", headerName: "Transaction Method", flex: 1 },
        { field: "transaction_currency", headerName: "Transaction Currency", flex: 1 },
        { field: "value", headerName: "Plan Value", flex: 1 },
        {
            field: "action",
            headerName: "Action",
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpen(params.row)}
                    >
                        View Details
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            <Paper
                elevation={3}
                sx={{
                    borderRadius: '7px',
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    marginTop: "32px"
                }}
            >
                <Typography
                    variant='h2'
                    sx={{
                        padding: "10px",
                        backgroundColor: "var(--intgrow-primary-color)",
                        color: "#fff",
                        borderRadius: "7px 7px 0 0",
                    }}
                >
                    My Subscription
                </Typography>
                <DataGrid
                    autoHeight
                    rows={userOrderHistoryList}
                    columns={columns}
                    sx={{
                        overflow: "auto",
                        "& .MuiDataGrid-root": {
                            border: "none"
                        },
                        "& .MuiDataGrid-main": {
                            minWidth: "800px"
                          },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                            fontWeight: 600,
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "800",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: colors.table[200],
                        },
                        "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.grey[100]} !important`,
                        },
                        "& .MuiDataGrid-container--top [role=row]": {
                            backgroundColor: `${colors.grey[500]} !important`,
                        },
                    }}
                />
            </Paper>

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>Subscription Details</DialogTitle>
                <DialogContent>
                    {selectedRecord && (
                        // new Date(parseInt(currentPlanType.transaction_date) * 1000).toLocaleDateString()
                        <div>
                            <Typography variant="h6">Plan: {selectedRecord.plan}</Typography>
                            <Typography>Purchase Date: {new Date(parseInt(selectedRecord?.transaction_date) * 1000)?.toLocaleString()}</Typography>
                            <Typography>Transaction Method: {selectedRecord.transaction_method}</Typography>
                            <Typography>Transaction Currency: {(selectedRecord.transaction_currency)?.toUpperCase()}</Typography>
                            <Typography>Plan Value: {(selectedRecord.value) / 100}</Typography>
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default MySubscriptionTable;
