import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import AdminPanelTopbar from "../scenes/global/AdminPanelTobbar";

import { Box } from "@mui/material";
import CMSSidebar from "./cms/CMSSidebar";

const CMSDashboardLayout = () => {
    return (
        <div className='app'>
            <CMSSidebar />
            <main className='content'>
                <Box flexGrow={1}>
                    <AdminPanelTopbar />
                    <Box>
                        <Outlet />
                    </Box>
                </Box>
            </main>
        </div >
    );
};

export default CMSDashboardLayout;
