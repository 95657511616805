import React, { useState } from 'react'
import {
    Box, Button, Stack, TextField,
    Typography, InputAdornment, IconButton, useTheme, Checkbox, useMediaQuery, CircularProgress
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import {Visibility, VisibilityOff } from '@mui/icons-material';
import PinIcon from '@mui/icons-material/Pin';
import { baseUrl } from '../../utils/baseURL';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TermsAndCond from '../modal/T&C';
import LoginWithGoogle from '../../SSO/LoginWithGoogle';
import LoginWithLinkedIn from '../../SSO/LoginWithLinkedIn';

const SignupForm = () => {
    const theme = useTheme();
    const navigate = useNavigate()
    const mode = theme.palette.mode;
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    const handleTC = () => setOpen(!open)
    const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    // const [openWelcomeDialog, setOpenWelcomeDialog] = useState(false);
    // const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAcept, setIsAcept] = useState("")
    const [agree, setAgree] = useState(false)

   

    const formik = useFormik({
        initialValues: {
            email: '',
            name: '',
            mobile: "",
            last_name: "",
            first_name: "",
            password: '',
            submit: null
        },
        validationSchema: Yup.object({
            mobile: Yup
                .string()
                .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits')
                .required('Mobile No. is required'),
            email: Yup
                .string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
            first_name: Yup
                .string()
                .required('First Name is required'),
            last_name: Yup
                .string()
                .required("Last Name is required"),
            password: Yup
                .string()
                .max(25, 'Password must be less than 25 characters long')
                .min(8, 'Password must be at least 8 characters long')
                .matches(/[a-zA-Z]/, 'Password must contain at least one letter')
                .matches(/\d/, 'Password must contain at least one number')
                .matches(/[@$!%*?&#]/, 'Password must contain at least one special character')
                .required('Password is required'),
            confirm_password: Yup
                .string()
                .max(255)
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Confirm Password is required')
        }),

        onSubmit: async (values, helpers) => {
            let UserData
            let timeoutId;
            
            try {
                setLoading(true);
                const response = await fetch(`${baseUrl}/auth/signUp`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...values, istncAccepted: isAcept || true }),
                });
                if (!response?.ok) {
                    const responseData = await response.json();
                    const registerError = new Error(responseData.message || 'An error occurred');
                    registerError.status = response.status;

                    if (responseData?.message === "Failed! Username is already in use!. Verify email or login if already verifed." || response?.status === 400) {
                        UserData = {
                            loginLink: "https://beta.intgrow.co/auth/login/",
                            status: 400,
                            email: values?.email
                        }
                        console.log("error", responseData?.message)
                    }
                    setLoading(false);
                    throw registerError;
                }
                const responseData = await response.json();
                window.localStorage.setItem('authenticated', 'true');
                window.localStorage.setItem('access_token', responseData.result.token);
                setData(responseData);
                setError(null);
                setLoading(false);
                toast.success('Please verify your Email!.Link send to your Email');
                timeoutId = setTimeout(() => {
                    navigate("/auth/resend-email-verification-link");
                    // navigate("/welcome");
                }, 2000);
                if (response?.status === 200 && response.ok) {
                    UserData = {
                        username: responseData?.result?.first_name,
                        password: values?.password,
                        email: values?.email,
                        status: 200
                    }
                }

            } catch (err) {
                setLoading(false);
                toast.error(err.message)
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
            } finally {
                return () => {
                    if (timeoutId) {
                        clearTimeout(timeoutId);
                    }
                };
            }
        }
    });


    const handleConfirmPasswordBlur = (e) => {
        formik.handleBlur(e);
        const passwordError = formik.errors.password;
        const confirmError = formik.errors.confirm_password;
        if (!passwordError && !confirmError) {
            setError(null);
        } else {
            setError(passwordError || confirmError);
        }
    }
    const handleAcept = (acept) => {
        if (!agree) {
            return alert("Please accept terms & conditions")

        }
        setIsAcept(acept)
        setOpen(false)

    }
    const handleAgree = () => {
        setAgree((presStr) => !presStr)
    }
    const handleCheckboxChange = (event) => {
        setAgree(event.target.checked)
    };
    return (
        <div style={{ display: "flex", alignItems: "center", padding: "10px" }}>
            <ToastContainer />
            <Stack spacing={3}>
                <Typography variant='h4' fontSize={25} fontWeight={700}>Create Account</Typography>
                <form
                    noValidate
                // onSubmit={formik.handleSubmit}
                >
                    <Stack spacing={3}
                        flexDirection={"column"}
                        gap={1}
                    >
                        <Box display={isMediumScreen ? 'flex' : 'flex'}
                            flexDirection={isMediumScreen ? 'row' : 'column'}
                            gap={isMediumScreen ? 2 : 2}>
                            <TextField
                                error={!!(formik.touched.first_name && formik.errors.first_name)}
                                fullWidth
                                id="outlined-required"
                                helperText={formik.touched.first_name && formik.errors.first_name}
                                label="First Name"
                                name="first_name"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.first_name}
                                sx={{
                                    color: "#444B59",
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: mode === "dark" ? "#fff" : "#0E588C",
                                    },
                                    "& .MuiInputAdornment-root svg": {
                                        color: mode === "dark" ? "#fff" : "#0E588C",
                                    },
                                }}
                                InputLabelProps={{
                                    style: { color: mode === "dark" ? "#fff" : "#0E588C", } // Change 'red' to the desired color
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                error={!!(formik.touched.last_name && formik.errors.last_name)}
                                fullWidth
                                helperText={formik.touched.last_name && formik.errors.last_name}
                                label="Last Name"
                                name="last_name"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.last_name}
                                sx={{
                                    color: "#444B59",
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: mode === "dark" ? "#fff" : "#0E588C",
                                    },
                                    "& .MuiInputAdornment-root svg": {
                                        color: mode === "dark" ? "#fff" : "#0E588C",
                                    },
                                }}
                                InputLabelProps={{
                                    style: { color: mode === "dark" ? "#fff" : "#0E588C", } // Change 'red' to the desired color
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box display={isMediumScreen ? 'flex' : 'flex'}
                            flexDirection={isMediumScreen ? 'row' : 'column'}
                            gap={isMediumScreen ? 2 : 2}>
                            <TextField
                                error={!!(formik.touched.email && formik.errors.email)}
                                fullWidth
                                helperText={formik.touched.email && formik.errors.email}
                                label="Enter Your Email"
                                name="email"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="email"
                                value={formik.values.email}
                                sx={{
                                    color: "#444B59",
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: mode === "dark" ? "#fff" : "#0E588C",
                                    },
                                    "& .MuiInputAdornment-root svg": {
                                        color: mode === "dark" ? "#fff" : "#0E588C",
                                    },
                                }}
                                InputLabelProps={{
                                    style: { color: mode === "dark" ? "#fff" : "#0E588C", } // Change 'red' to the desired color
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start"
                                            sx={{ marginTop: "0px" }}
                                        >
                                            <EmailIcon
                                                sx={{ marginTop: "0px" }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                error={!!(formik.touched.mobile && formik.errors.mobile)}
                                fullWidth
                                helperText={formik.touched.mobile && formik.errors.mobile}
                                label="Mobile Number"
                                name="mobile"
                                id="outlined-required"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="number"
                                value={formik.values.mobile}
                                sx={{
                                    color: "#444B59",
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                    },
                                    "& .MuiOutlinedInput-input": {
                                        padding: "15px"
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: mode === "dark" ? "#fff" : "#0E588C",
                                    },
                                    "& .MuiInputAdornment-root svg": {
                                        color: mode === "dark" ? "#fff" : "#0E588C",
                                    },
                                }}
                                InputLabelProps={{
                                    style: { color: mode === "dark" ? "#fff" : "#0E588C", } // Change 'red' to the desired color
                                }}
                                InputProps={{
                                    style: {
                                        color: "#0E588C",
                                        fontSize: "medium",
                                        fontStyle: "normal",
                                        fontWeight: 400,
                                        lineHeight: "normal",
                                        width: "100%",
                                        letterSpacing: "2.4px",
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <PinIcon sx={{ color: "#0E588C", marginTop: "0px" }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box display={isMediumScreen ? 'flex' : 'flex'}
                            flexDirection={isMediumScreen ? 'row' : 'column'}
                            gap={isMediumScreen ? 2 : 2}>
                            <TextField
                                error={!!(formik.touched.password && formik.errors.password)}
                                fullWidth
                                helperText={formik.touched.password && formik.errors.password}
                                label="Password"
                                name="password"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="password"
                                value={formik.values.password}
                                sx={{
                                    color: "#444B59",
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: mode === "dark" ? "#fff" : "#0E588C",
                                    },
                                    "& .MuiInputAdornment-root svg": {
                                        color: mode === "dark" ? "#fff" : "#0E588C",
                                    },
                                }}
                                InputLabelProps={{
                                    style: { color: mode === "dark" ? "#fff" : "#0E588C", } // Change 'red' to the desired color
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                error={!!(formik.touched.confirm_password && formik.errors.confirm_password)}
                                fullWidth
                                helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                                label="Confirm Password"
                                name="confirm_password"
                                onBlur={handleConfirmPasswordBlur}
                                onChange={formik.handleChange}
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={formik.values.confirm_password}
                                sx={{
                                    color: "#444B59",
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: mode === "dark" ? "#fff" : "#a19191",
                                        },
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: mode === "dark" ? "#fff" : "#0E588C",
                                    },
                                    "& .MuiInputAdornment-root svg": {
                                        color: mode === "dark" ? "#fff" : "#0E588C",
                                    },
                                }}
                                InputLabelProps={{
                                    style: { color: mode === "dark" ? "#fff" : "#0E588C", } // Change 'red' to the desired color
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Stack>
                    {formik.errors.submit && (
                        <Typography
                            color="error"
                            sx={{ mt: 3 }}
                            variant="body2"
                        >
                            {formik.errors.submit}
                        </Typography>
                    )}
                    {loading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                    )}
                </form>
                <Box display={"flex"}>
                    <Checkbox
                        checked={agree}
                        onChange={handleCheckboxChange}
                        sx={{
                            borderColor: 'blue',
                            color: mode === "dark" ? 'white' : 'black', // For the checkmark color
                            '&.Mui-checked': {
                                color: mode === "dark" ? 'white' : 'black',
                            },
                        }}
                    />
                    <Typography onClick={handleTC} sx={{ marginBlock: "auto", cursor: "pointer", color: "#0088AF", textDecoration: "underline" }}>Terms & Conditions</Typography>
                </Box>
                <Box display={isMediumScreen ? 'flex' : 'flex'}
                    flexDirection={isMediumScreen ? 'row' : 'column'}
                    gap={isMediumScreen ? 2 : 2}>
                    <Button
                        fullWidth
                        size="large"
                        sx={{
                            mt: 0, color: "white", backgroundColor: "#19508a",
                            '&:hover': {
                                backgroundColor: "#19508a",
                            },
                        }}
                        type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit}
                    >
                        Create Account
                    </Button>
                    <Button
                        fullWidth
                        size="large"
                        sx={{
                            mt: 0, color: "white", backgroundColor: "#000",
                            border: "1px solid rgba(255, 255, 255, 0.12)",
                            gap: "10px",
                            '&:hover': {
                                backgroundColor: "#000",
                            },
                        }}
                        variant="contained"
                    >
                        <span>Continue with</span>
                        <LoginWithGoogle />
                        <LoginWithLinkedIn />

                    </Button>
                </Box>
                <Box sx={{ display: "flex" }}>
                    <p className='already-have-account'>Resend Email Verification<Link to="/auth/resend-email-verification-link" style={{ marginInlineStart: 6 }}>Click</Link></p>
                    <p className='already-have-account'>Have an Account? <Link to="/auth/login" style={{ marginInlineStart: 6 }}>Login</Link></p>
                </Box>
            </Stack>
            <TermsAndCond
                open={open}
                agree={agree}
                handleAgree={handleAgree}
                handleClose={handleClose}
                handleAcept={handleAcept}
                handleReject={handleClose}
            />
        </div>
    )
}

export default SignupForm
