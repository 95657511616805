import React, {Suspense } from 'react';
import { withUserAuthGuard } from '../../withGaurd/withUserGaurd';
import { Box, Typography, useTheme } from '@mui/material';
import Row1Component from '../../adminPanel/dashboard/Row1Component';
import { Helmet } from 'react-helmet';
import MyProfile from '../../adminPanel/myProfile/MyProfile';

const Row2Component = React.lazy(() => import('../../adminPanel/dashboard/Row2Component'));


const MyProfilePage = withUserAuthGuard(() => {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>My Profile | IntGrow Corp.</title>
        <meta property="og:title" content="Dashboard" />
        <meta property="og:url" content="https://alpha.intgrow.co/dashboard" />
        <meta property="og:type" content="information" />
        <meta property="og:description" content="what we serve" />
        <meta name="keywords" content="HS Code, duty calculator, duty saver " />
      </Helmet>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" sx={{ marginBottom: "10px", fontWeight: "700" }}>
            Profile
          </Typography>
        </Box>

        {/* GRID & CHARTS */}
        <Row1Component theme={theme} />
        <Suspense fallback={<div>Loading data...</div>}>
           <MyProfile/>
        </Suspense>
   
      </Box>
    </>
  );
});

export default MyProfilePage;
