import React from 'react'
import './LoginPage.css'
import styles from './Login.module.scss'
import { Grid, Box, Typography } from "@mui/material";
import LoginForm from '../../components/auth/LoginForm';
import Header from '../../components/header/Header';
import logo from '../../assest/Intgrow_logo.png'
import logo_1 from '../../assest/Intgrow Logo Transpernt.png'
import img from '../../assest/img-1.png'
import img_1 from '../../assest/Ellipse 1.png'
import img_2 from '../../assest/Ellipse 2.png'
import Footer from '../../components/footer/Footer'
import { Helmet } from 'react-helmet';


const LoginPage = () => {
    return (
        <>
            <Helmet>
                <title>Login | IntGrow Corp.</title>
                <meta property="og:title" content="Login" />
                <meta property="og:url" content="https://alpha.intgrow.co/auth/login" />
                <meta property="og:type" content="authentication" />
                <meta property="og:description" content="login to use" />
                {/* <meta property="og:image" content={pageImage} /> */}
                <meta name="keywords" content="HS Code, duty calculator, duty saver " />
            </Helmet>
            <Grid className={styles.loginPageContainer}>
                <Header
                    logo={logo_1}
                    logo_1={logo_1}
                />
                <Grid container className={styles.loginPage}>
                    <Grid item sm={6} className={styles.leftSide}>
                        <Typography marginBlockEnd={2.5} variant='h5' fontWeight={600} fontSize={25} sx={{ color: "#FD8406" }}>Welcome Back!</Typography>
                        <img src={logo} alt="login" />
                        <Typography sx={{ color: "#ffffff" }}>If you don’t have an account you can Register HERE</Typography>
                        <Box>
                            <img src={img} alt='login' />
                        </Box>
                    </Grid>
                    <Grid item sm={6} className={styles.rightSide}>
                        <LoginForm />
                    </Grid>
                </Grid>
                <Grid>
                    <img src={img_1} alt='layou' />
                    <img src={img_2} alt="layout" />
                </Grid>
                <Footer />
            </Grid>
        </>
    )
}

export default LoginPage


