import React, { useState, useEffect } from 'react';
import '../Glossary/Glossary.css';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Box, Typography } from '@mui/material';
import { IoIosArrowDown } from "react-icons/io";
import { baseUrl } from '../../utils/baseURL';

const CommonFAQAccordionSection = ({ section }) => {
    const [expanded, setExpanded] = React.useState(false);
    const [faqList, setFAQList] = useState();
    const [error, setError] = useState(null);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const fetchData = async () => {
        const token = window.localStorage.getItem('access_token');
        try {
            const response = await fetch(`${baseUrl}/faq/list-by-section?section=${section}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': token
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setFAQList(data?.faqs);
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <Box className="accordionContainer">
            <Box>
                <Typography sx={{ fontSize: '31px', fontWeight: 800,textAlign:"center"}}>Frequently Asked Questions</Typography>
                {faqList && faqList.map((item, index) => (
                    <Accordion key={item.id} expanded={expanded === item.id} onChange={handleChange(item.id)}>
                        <AccordionSummary
                            expandIcon={<IoIosArrowDown fontSize={21} style={{ color: "#0C98BC" }} />}
                        >
                            <Typography variant='h5' fontSize={15} fontWeight={500}>
                                {item?.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography fontSize={15} sx={{
                                padding: "0 10px",
                            }}>
                                {item?.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}

            </Box>
        </Box>
    );
}

export default CommonFAQAccordionSection;
