import React, { Suspense, useState, useEffect } from 'react';
import { withUserAuthGuard } from '../../withGaurd/withUserGaurd';
import { Box, Typography, useTheme } from '@mui/material';
import Row1Component from '../../adminPanel/dashboard/Row1Component';
import { Helmet } from 'react-helmet';
import { fetchAllHistoryRecords } from '../../utils/Api';
const Row2Component = React.lazy(() => import('../../adminPanel/dashboard/Row2Component'));
const Row3Component = React.lazy(() => import('../../adminPanel/dashboard/Row3Component'));
const Row4Component = React.lazy(() => import('../../adminPanel/dashboard/Row4Component'));

const Dashboard = withUserAuthGuard(() => {
  const theme = useTheme();
  const [hsCodeHistories, setHsCodeHistories] = useState([])
  const [dutyCalcHistories, setDutyCalcHistories] = useState([])
  const [dutySaverHistories, setDutySaverHistories] = useState([])

  useEffect(() => {
    const getAllHistory = async () => {
      const result = await fetchAllHistoryRecords();
      const hscode = result?.hscode
      setHsCodeHistories(result?.hscode)
      setDutyCalcHistories(result?.duty)
      setDutySaverHistories(result?.fta)
    }
    getAllHistory();
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard | IntGrow Corp.</title>
        <meta property="og:title" content="Dashboard" />
        <meta property="og:url" content="https://alpha.intgrow.co/dashboard" />
        <meta property="og:type" content="information" />
        <meta property="og:description" content="what we serve" />
        {/* <meta property="og:image" content={pageImage} /> */}
        <meta name="keywords" content="HS Code, duty calculator, duty saver " />
      </Helmet>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" sx={{ marginBottom: "10px", fontWeight: "700" }}>
            Dashboard
          </Typography>
        </Box>

        {/* GRID & CHARTS */}
        <Row1Component theme={theme} />
        <Row2Component theme={theme} hsCodeHistories={hsCodeHistories} />
        <Suspense fallback={<div>Loading data...</div>}>
          <Row3Component  theme={theme} dutyCalcHistories={dutyCalcHistories}/>
        </Suspense>
        <Suspense fallback={<div>Loading data...</div>}>
          <Row4Component theme={theme} dutySaverHistories={dutySaverHistories} />
        </Suspense>
      </Box>
    </>
  );
});

export default Dashboard;
