import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useLocation, } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HomeIcon from '@mui/icons-material/Home';
import Logo from "../../components/Layout/Logo";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === to}
      style={{
        color: selected === to ? "#fff" : colors.grey[100],
        backgroundColor: selected === to ? "var(--intgrow-primary-color)" : 'transparent',
        borderRadius: "7px"
      }}
      onClick={() => setSelected(to)}
      icon={icon}
    >
      <Typography
        sx={{ fontWeight: "600", color: selected === to ? "#fff" : colors.typography[100] }}>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const CMSSidebar = () => {
  const theme = useTheme();
  const location = useLocation();
  const path = location.pathname;
  const mode = theme.palette.mode;
  const colors = tokens(mode);
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 678);
  const [selected, setSelected] = useState(path);
  const [subMenuOpen, setSubMenuOpen] = useState("");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 678) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setSelected(location.pathname);
  }, [location.pathname]);

  const handleSubMenuClick = (menu) => {
    setSubMenuOpen(subMenuOpen === menu ? "" : menu);
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-layout": {
          overflowY: "auto",
          height: "100vh !important"
        },
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 20px 5px 8px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square" style={{ padding: "10px" }}>
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon sx={{ color: mode === "dark" ? "" : "#000" }} /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: "#fff",
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  <Logo logo={mode === "dark" ? "/assets/images/darkmode_logo.png" : "/assets/images/intgrow-transpernt-logo.png"} />
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Support"
              to="/cms/support"
              icon={<SupportAgentIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <SubMenu
              title="Blog"
              icon={<PeopleOutlinedIcon />}
              open={subMenuOpen === "blog"}
              onClick={() => handleSubMenuClick("blog")}
              style={{
                color: subMenuOpen === "blog" ? "#fff" : colors.grey[100],
                backgroundColor: subMenuOpen === "blog" ? "var(--intgrow-primary-color)" : 'transparent',
                borderRadius: "7px"
              }}
            >
              <Item
                title="Create Blog"
                to="/cms/blog/create"
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Blog List"
                to="/cms/blog/list"
                selected={selected}
                setSelected={setSelected}
              />
            </SubMenu>
            <SubMenu
              title="FAQ"
              icon={<HelpOutlineIcon />}
              open={subMenuOpen === "faq"}
              onClick={() => handleSubMenuClick("faq")}
              style={{
                color: subMenuOpen === "faq" ? "#fff" : colors.grey[100],
                backgroundColor: subMenuOpen === "faq" ? "var(--intgrow-primary-color)" : 'transparent',
                borderRadius: "7px"
              }}
            >
              <Item
                title="Add FAQ"
                to="/cms/faq/add"
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="FAQ List"
                to="/cms/faq/list"
                selected={selected}
                setSelected={setSelected}
              />
            </SubMenu>
            <SubMenu
              title="Glossary"
              icon={<CurrencyExchangeIcon />}
              open={subMenuOpen === "glossary"}
              onClick={() => handleSubMenuClick("glossary")}
              style={{
                color: subMenuOpen === "glossary" ? "#fff" : colors.grey[100],
                backgroundColor: subMenuOpen === "glossary" ? "var(--intgrow-primary-color)" : 'transparent',
                borderRadius: "7px"
              }}
            >
              <Item
                title="Add Glossary"
                to="/cms/glossary/add"
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Glossary List"
                to="/cms/glossary/list"
                selected={selected}
                setSelected={setSelected}
              />
            </SubMenu>
            <Item
              title="Upload File"
              to="/cms/upload-file"
              icon={<CloudUploadIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Latest Update"
              to="/cms/upload/latest-updates"
              icon={<AccessAlarmsIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Upload Media"
              to="/cms/upload-media"
              icon={<CloudUploadIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="All Media"
              to="/cms/uploaded-media-list"
              icon={<ChecklistRtlIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Home"
              to="/"
              icon={<HomeIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default CMSSidebar;
