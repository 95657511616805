import React, { useState, useEffect } from 'react';
import './hscode.style.css';
import { Grid, Box, Button, Typography, useTheme,Modal } from '@mui/material';
import styles from './HSCodeTree.module.scss';
import DisabledInputField from '../../components/InputField/disabledInputField';
import SouthIcon from '@mui/icons-material/South';
import icon from '../../assest/Sign_in_circle_duotone.png';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import backIcon from '../../assest/Back Button.png';
import arrowcon from '../../assest/ic-keyboard-arrow-left-24px.png';
import { tokens } from "../../theme";
import NewHSCodeTree from './HSCodeTree/NewHSCodeTree';
import { baseUrl } from '../../utils/baseURL';
import { selectData } from './data'
import { useWallet } from "../../context/WalletsContext";


const transformData = (data) => {
  const result = {};

  data.forEach(item => {
    const { hs2, hs2_des, hs4, hs4_des, hs6, hs6_des } = item;

    if (!result[hs2]) {
      result[hs2] = { description: hs2_des, subCodes: {} };
    }

    if (!result[hs2].subCodes[hs4]) {
      result[hs2].subCodes[hs4] = { description: hs4_des, subCodes: {} };
    }

    if (!result[hs2].subCodes[hs4].subCodes[hs6]) {
      result[hs2].subCodes[hs4].subCodes[hs6] = { description: hs6_des };
    }
  });

  return result;
};
const TreeNode = ({ code, node, onSelect, onLastNodeSelect, depth = 0 }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleClick = () => {
    if (!node.subCodes) {
      onLastNodeSelect();
    }
    handleToggle();
  };

  return (
    <div className={`tree-node ${expanded ? 'expanded' : ''}`} style={{ marginLeft: depth * 15 + 'px' }}>
      <div onClick={handleClick} className={`tree-node-label ${expanded ? 'expanded' : ''}`} style={{ display: "flex", cursor: "pointer", padding: "10px 0" }}>
        <span>{expanded ? <img src={arrowcon} alt="Collapse" /> : <img className='expandTree' src={backIcon} alt="Expand" />}</span>
        <Typography sx={{ marginBlock: "auto", marginInlineStart: 1, textAlign: "justify", padding: "15px 0 15px 10px", borderBottom: "1px solid #e0e0e0" }}>
          {code} - {node.description}
        </Typography>
      </div>
      {expanded && (
        <div className="tree-node-children">
          {node.subCodes && (
            <>
              {Object.entries(node.subCodes).map(([subCode, subNode]) => (
                <TreeNode key={subCode} code={subCode} node={subNode} onSelect={onSelect} onLastNodeSelect={onLastNodeSelect} depth={depth + 1} />
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};


const LeftTreeNode = ({ code, node, onSelect, onLastNodeSelect, depth = 0 }) => {
  const [expanded, setExpanded] = useState(true);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleClick = () => {
    handleToggle();
  };

  return (
    <div className={styles.treeNode} style={{ marginLeft: depth * 5 + 'px' }}>
      <div onClick={handleClick} className={expanded ? 'selected-code' : ''} style={{ display: "flex", cursor: "pointer", position: "relative", gap: "5px" }}>
        <span>{expanded ? <img src={arrowcon} alt="Collapse" /> : <KeyboardArrowRightIcon />}</span>
        <Typography sx={{ marginBlock: "auto" }}>{code}</Typography>
      </div>
      <div>
        <Typography sx={{ marginBlock: "auto", marginInlineStart: 2, textAlign: "justify", padding: "5px 0 5px 10px;", borderBottom: "1px solid #e0e0e0;" }}>{node.description}</Typography>
      </div>
      {expanded && (
        <>
          {node.subCodes && (
            <div>
              <div className="line"></div>
              {Object.entries(node.subCodes).map(([subCode, subNode]) => (
                <LeftTreeNode key={subCode} code={subCode} node={subNode} onSelect={onSelect} depth={depth + 1} />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};


const HSCodeSelector = ({ ImportingCountry, ExportingCountry, hscode, setIsHSCodeTreeOpen }) => {
  const [selectedCode, setSelectedCode] = useState("");
  const { fetchWallets } = useWallet();
  const [hsCodes, setHSCodes] = useState("");
  const [product, setProduct] = useState("");
  const [showHScodeTree, setShowHScodeTree] = useState(true);
  const [isHScodeTreeOnLeft, setIsHScodeTreeOnLeft] = useState(true);
  const [errorMSG, setErrorMSG] = useState("")
  const [openSelectHSCodeModel, setOpenSelectHSCodeModel] = useState(false)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  const handleSelect = (code) => {
    console.log("handleSelect", code);
    setSelectedCode(code);
  };

  const handleLastNodeSelect = (code) => {
    console.log("last node", code)
    setIsHScodeTreeOnLeft(true);
  };

  const handleModify = () => {
    setIsHSCodeTreeOpen(false);
    console.log("modify input field");
  };

  const getProductFromCountryCode = async (hscode, imp, exp) => {
    try {
      const token = window.localStorage.getItem("access_token")
      const response = await fetch(`${baseUrl}/getProductFromCountryCode?hs=${hscode}&imp=${imp}&exp=${exp}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          "x-api-key":token
        }
      });
      const data = await response.json();

      if (!response.ok) {
        setErrorMSG(`Network response was not ok. ${data?.message}`);
      }
      console.log("data", data);
      setProduct(data);
      fetchWallets()
    } catch (error) {
      console.log("error", error)
      setErrorMSG(error.Error)
      console.error('An error occurred:', error);
    }
  }
  useEffect(() => {
    async function fetchData() {
      const productData = await getProductFromCountryCode(hscode, ImportingCountry.value, ExportingCountry.value);

    }

    fetchData();
  }, [hscode, ImportingCountry.value, ExportingCountry.value]);


  useEffect(() => {
    const fetchHScodeDescription = async (hscode) => {
      try {
        const response = await fetch(`${baseUrl}/country/hsDetails?hs=${hscode}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });
        const data = await response.json();

        if (!response.ok) {
          console.log('Error in fetch hscode');
          throw new Error('No Relevant Matches Found; Please Provide Refine Text for Better Results..');
        }
        console.log("data", data);
        const transformed = transformData(data);
        setHSCodes(transformed);
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    fetchHScodeDescription(hscode);
  }, [hscode]);

  const handleHScodeTree = () => {
    setShowHScodeTree(prev => !prev);
  };


  useEffect(() => {
    if((product && product.import)){
      setOpenSelectHSCodeModel(true)
    }
  }, [product])

  const closeSelectHSCodeModel = () => {
    setOpenSelectHSCodeModel(false)
  }

  return (
    <Grid className={styles.hsCodeTreeContainer}>
      <Grid container spacing={2} className="hsCodeTreeHeader">
        <Grid item xs={12} md={3.25}>
          <DisabledInputField label="Product label / HS Code" value={hscode || ""} disabled />
        </Grid>
        <Grid item xs={12} md={3.25}>
          <DisabledInputField label="Importing Country" value={ImportingCountry?.label || ""} countryCode={ImportingCountry?.flag} disabled />
        </Grid>
        <Grid item xs={12} md={3.25}>
          <DisabledInputField label="Exporting Country" value={ExportingCountry?.label || ""} countryCode={ExportingCountry?.flag} disabled />
        </Grid>
        <Grid item xs={12} md={2.25} className={styles.modifyBtn}>
          <Button className={styles.modifyButton} onClick={handleModify}>Modify</Button>
        </Grid>
      </Grid>
      <Grid className={styles.hsCodeTree}>
        <Grid className={styles.leftSide} sx={{ backgroundColor: colors.primary[400] }}>
          <Box sx={{ backgroundColor: "#1994B7", color: "#ffffff" }}>
            <Typography variant='h5' fontSize={13} fontWeight={500}>HS Code </Typography>
          </Box>

          {isHScodeTreeOnLeft &&
            <Grid style={{ padding: "10px 0" }}>
              {/* <Box sx={{ backgroundColor: "#F4F6F5", color: "#1994B7" }} display={"flex"} justifyContent={"center"} onClick={handleHScodeTree}>
                <Typography marginBlock={"auto"} variant='h5' fontSize={13} fontWeight={500}>{hscode.slice(0, 2)} {hscode} value</Typography>
                <img src={icon} style={{ paddingInlineEnd: 35 }} alt="Icon" />
              </Box> */}
              {hsCodes && Object.entries(hsCodes).map(([code, node]) => (
                <Box display={"flex"} justifyContent={"space-between"} key={code}>
                  <LeftTreeNode code={code} node={node} onSelect={handleSelect} />
                  <Box sx={{ background: "#fbfbfb", padding: 0.25, borderRadius: "50%", display: "none" }}>
                    <SouthIcon style={{ fontSize: 16 }} />
                  </Box>
                </Box>
              ))}
            </Grid>

          }

        </Grid>
        <Grid className={styles.rightSide} sx={{ backgroundColor: colors.primary[400], display: showHScodeTree ? "block" : "none" }}>
          {isHScodeTreeOnLeft ? <NewHSCodeTree product={product} ExportingCountry={ExportingCountry} ImportingCountry={ImportingCountry} /> :
            hsCodes && Object.entries(hsCodes).map(([code, node]) => (
              <Box display={"flex"} justifyContent={"space-between"} key={code}>
                <TreeNode code={code} node={node} onSelect={handleSelect} onLastNodeSelect={() => handleLastNodeSelect(code)} />
                <Box sx={{ background: "#fbfbfb", padding: 0.25, borderRadius: "50%", display: "none" }}>
                  <SouthIcon style={{ fontSize: 16 }} />
                </Box>
              </Box>
            ))
          }

        </Grid>
      </Grid>

      <p style={{ color: "red" }}>{errorMSG}</p>

      <Modal open={openSelectHSCodeModel} onClose={closeSelectHSCodeModel}>
        <Box sx={{
          p: 4, backgroundColor: colors.primary[400], borderRadius: 2, maxWidth: 800,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
        }}>
          <Typography>
            Select HS Code Of {ImportingCountry.label} And {ExportingCountry.label} To Proceed.
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={closeSelectHSCodeModel} variant="contained"
              sx={{
                backgroundColor: "var(--intgrow-bg)",
                "&:hover": {
                  backgroundColor: "var(--intgrow-bg)"
                }
              }}>Close</Button>
          </Box>

        </Box>
      </Modal >
    </Grid>
  );
};

export default HSCodeSelector;
