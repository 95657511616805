import React, { useEffect, useState } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, Button } from '@mui/material';
import { baseUrl } from '../utils/baseURL';


const ApiKeyCell = ({ apiKey }) => {
  
    const firstPart = apiKey.slice(0, 6);
    const middlePart = apiKey.slice(6, -4);
    const lastPart = apiKey.slice(-4);
  
    return (
      <TableCell>
        <span style={{ fontWeight: 'bold',fontSize:"1.2rem" }}>{firstPart}</span>
        <span>{middlePart}</span>
        <span style={{ fontWeight: 'bold',fontSize:"1.2rem" }}>{lastPart}</span>
      </TableCell>
    );
  };

const MyApiKeys = ({refresh}) => {
    const [apiKeys, setApiKeys] = useState([]);

    useEffect(() => {
        const fetchApiKeys = async () => {
            try {
                const token = window.localStorage.getItem("access_token");
                const response = await fetch(`${baseUrl}/my-generated-developer-api-key`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': token,
                    },
                });

                if (!response.ok) {
                    throw new Error('Error fetching API keys');
                }

                const data = await response.json();
                setApiKeys(data.apiKeys);
            } catch (error) {
                console.error('Error fetching API keys', error.message);
            }
        };

        fetchApiKeys();
    }, [refresh]);

    const deleteApiKey = async (apiKey) => {
        try {
            const token = window.localStorage.getItem("access_token");
            const response = await fetch(`${baseUrl}/delete-my-generated-developer-api-key/${apiKey}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': token,
                },
            });

            if (!response.ok) {
                throw new Error('Error deleting API key');
            }

            // Remove the deleted API key from the state
            setApiKeys(apiKeys.filter((key) => key.id !== apiKey));
        } catch (error) {
            console.error('Error deleting API key', error.message);
        }
    };

    return (
        <Container maxWidth="md" sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
                My API Keys
            </Typography>
            <Paper sx={{ width: '100%', overflow: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Project Name</TableCell>
                            <TableCell>API Key</TableCell>
                            {/* <TableCell>Expires At</TableCell> */}
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {apiKeys.length > 0 ? (
                            apiKeys.map((apiKey, index) => (
                                <TableRow key={index}>
                                    <TableCell>{apiKey.project_name}</TableCell>
                                    {/* <TableCell>{apiKey.api_key}</TableCell> */}
                                    <ApiKeyCell apiKey={apiKey.api_key}/>
                                    {/* <TableCell>{new Date(apiKey.expires_at).toLocaleDateString()}</TableCell> */}
                                    <TableCell>
                                        <Button 
                                            variant="contained" 
                                            color="secondary" 
                                            onClick={() => deleteApiKey(apiKey.id)}
                                            sx={{
                                                backgroundColor: "var(--intgrow-bg)",
                                                color:"#fff",
                                                "&:hover": {
                                                    backgroundColor: "var(--intgrow-bg)"
                                                }
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4}>No API keys found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </Container>
    );
};

export default MyApiKeys;

