import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  TextField, Button, Grid, Container, Paper, Typography, Select, MenuItem, InputLabel,
  FormControl,
} from '@mui/material';
import { baseUrl } from '../../../utils/baseURL';

const initialValues = {
  name: '',
  short_name: '',
  title: '',
  type: '',
  url: '',
  description: '',
  keywords: '',
  tags: '',
  img_url: '',
  img_type: '',
  img_alt: '',
  video: '',
  related_blogs: [],
  content: '',
  image: null,
};


const CreateNewBlog = () => {
  const [content, setContent] = useState('');
  const [blogOptions, setBlogOptions] = useState([]);
  const [seletedOption, setSelectedOption] = useState([]);
  const [seletedImgType, setSelectedImgType] = useState([]);
  const editorRef = useRef(null);
  console.log("content",content)

  const imgTypeOptions = [
    { value: "image/jpeg", label: "image/jpeg" },
    { value: "image/png", label: "image/png" },
    { value: "image/gif", label: "image/gif" },
    { value: "image/tiff", label: "image/tiff" },
    { value: "image/svg", label: "image/svg" },
    { value: "image/webp", label: "image/webp" },
  ];


  useEffect(() => {
    const fetchedOptions = [
      { value: '5-imp-rice', label: 'imp-rice' },
      { value: '9-imp-rice', label: 'Exp-rice' },
      { value: '6-imp-rice', label: 'imp-rice' },
      { value: '1-imp-rice', label: 'Exp-rice' },
      { value: '8-imp-rice', label: 'imp-rice' },
      { value: '0-imp-rice', label: 'imp-rice' },
    ];
    setBlogOptions(fetchedOptions);
  }, []);

 
  useEffect(() => {
    const loadSCEEditor = () => {
      if (window.sceditor) {
        const editor = window.sceditor.create(editorRef.current, {
          format: 'bbcode',
          style: 'https://cdn.jsdelivr.net/npm/sceditor@latest/minified/themes/content/default.min.css',
        });

        if (editor) {
          editor.val(content);
          editor.bind('valuechanged', () => {
            console.log('Editor content changed:', editor.val());
            setContent(editor.val());
          });
        } else {
          console.error('SCEditor instance creation failed.');
        }
      } else {
        console.error('SCEditor is not defined');
      }
    };

    if (!window.sceditor) {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/npm/sceditor@latest/minified/sceditor.min.js';
      script.onload = () => {
        const bbcodeScript = document.createElement('script');
        bbcodeScript.src = 'https://cdn.jsdelivr.net/npm/sceditor@latest/minified/formats/bbcode.js';
        bbcodeScript.onload = () => {
          const xhtmlScript = document.createElement('script');
          xhtmlScript.src = 'https://cdn.jsdelivr.net/npm/sceditor@latest/minified/formats/xhtml.js';
          xhtmlScript.onload = loadSCEEditor;
          document.body.appendChild(xhtmlScript);
        };
        document.body.appendChild(bbcodeScript);
      };
      document.body.appendChild(script);
    } else {
      loadSCEEditor();
    }

    return () => {
      if (editorRef.current && window.sceditor) {
        const editorInstance = window.sceditor.instance(editorRef.current);
        if (editorInstance) {
          editorInstance.destroy();
        }
      }
    };
  }, [content]);
  

  const validationSchema = Yup.object().shape({
    related_blogs: Yup.array().required('Related Blogs are required'),
    name: Yup.string().required('Name is required'),
    short_name: Yup.string().required('Short name is required'),
    title: Yup.string().required('Title is required'),
    type: Yup.string().required('Type is required'),
    description: Yup.string().required('Description is required'),
    image: Yup.mixed().required('Image is required'),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("content",content)
    const token = window.localStorage.getItem('access_token');
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('short_name', values.short_name);
    formData.append('title', values.title);
    formData.append('type', values.type);
    formData.append('url', values.url);
    formData.append('description', values.description);
    formData.append('keywords', values.keywords);
    formData.append('tags', values.tags);
    formData.append('img_url', values.img_url);
    formData.append('img_type', values.img_type);
    formData.append('img_alt', values.img_alt);
    formData.append('video', values.video);
    formData.append('related_blogs', values.related_blogs);
    formData.append('content', content);
    formData.append('image', values.image);

    try {
      const response = await fetch(`${baseUrl}/blog/create`, {
        method: 'POST',
        headers: {
          "x-api-key":token
        },
        body: formData,
      });

      if (response.ok) {
        toast.success('Blog posted successfully');
        // window.location.reload(); // Optional: Refresh or redirect
      } else {
        toast.error('Failed to post blog.');
      }
    } catch (error) {
      toast.error('Failed to post blog.');
    }

    setSubmitting(false);
  };

  return (
    <Container maxWidth="md">
      <ToastContainer />
      <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Create New Blog
        </Typography>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      variant="outlined"
                      onChange={(e) => setFieldValue('name', e.target.value)}
                    />
                    <ErrorMessage name="name" component="div" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Short Name"
                      name="short_name"
                      variant="outlined"
                      onChange={(e) => setFieldValue('short_name', e.target.value)}
                    />
                    <ErrorMessage name="short_name" component="div" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Title"
                      name="title"
                      variant="outlined"
                      onChange={(e) => setFieldValue('title', e.target.value)}
                    />
                    <ErrorMessage name="title" component="div" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Type"
                      name="type"
                      variant="outlined"
                      onChange={(e) => setFieldValue('type', e.target.value)}
                    />
                    <ErrorMessage name="type" component="div" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="URL"
                      name="url"
                      variant="outlined"
                      onChange={(e) => setFieldValue('url', e.target.value)}
                    />
                    <ErrorMessage name="url" component="div" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      variant="outlined"
                      multiline
                      rows={4}
                      onChange={(e) => setFieldValue('description', e.target.value)}
                    />
                    <ErrorMessage name="description" component="div" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Keywords"
                      name="keywords"
                      variant="outlined"
                      onChange={(e) => setFieldValue('keywords', e.target.value)}
                    />
                    <ErrorMessage name="keywords" component="div" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Tags"
                      name="tags"
                      variant="outlined"
                      onChange={(e) => setFieldValue('tags', e.target.value)}
                    />
                    <ErrorMessage name="tags" component="div" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Image URL"
                      name="img_url"
                      variant="outlined"
                      onChange={(e) => setFieldValue('img_url', e.target.value)}
                    />
                    <ErrorMessage name="img_url" component="div" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Image Type</InputLabel>
                      <Select
                        label="Image Type"
                        name="img_type"
                        value={seletedImgType}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          setSelectedImgType(selectedValue);
                          setFieldValue('img_type', selectedValue);
                        }}
                      >
                        {imgTypeOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <ErrorMessage name="img_type" component="div" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Image Alt"
                      name="img_alt"
                      variant="outlined"
                      onChange={(e) => setFieldValue('img_alt', e.target.value)}
                    />
                    <ErrorMessage name="img_alt" component="div" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Video"
                      name="video"
                      variant="outlined"
                      onChange={(e) => setFieldValue('video', e.target.value)}
                    />
                    <ErrorMessage name="video" component="div" />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Related Blogs</InputLabel>
                      <Select
                        label="Related Blogs"
                        name="related_blogs"
                        multiple
                        value={seletedOption}
                        onChange={(e) => {
                          const selectedValues = e.target.value;
                          setSelectedOption(selectedValues);
                          setFieldValue('related_blogs', selectedValues);
                        }}
                      >
                        {blogOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <ErrorMessage name="related_blogs" component="div" />
                  </Grid>
                  <Grid item xs={12}>
                    <input
                      accept="image/*"
                      id="image"
                      name="image"
                      type="file"
                      onChange={(event) => {
                        setFieldValue('image', event.currentTarget.files[0]);
                      }}
                      hidden
                    />
                    <label htmlFor="image">
                      <Button variant="contained" component="span">
                        Upload Image
                      </Button>
                    </label>
                    <ErrorMessage name="image" component="div" />
                  </Grid>

                <Grid item xs={12}>
                  <textarea
                    ref={editorRef}
                    name="content"
                    style={{ display: 'none' }}
                  />
                  <ErrorMessage name="content" component="div" />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isSubmitting}
                    sx={{
                      backgroundColor: "var(--intgrow-bg)",
                      "&:hover": {
                        backgroundColor: "var(--intgrow-bg)"
                      }
                    }}
                  >
                    Create Blog
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default CreateNewBlog;
