import React, { useState, useEffect, useContext } from 'react'
import { Grid, Box, Typography, Button } from '@mui/material'
import styles from './NewHSCodeTree.module.scss'
import { useNavigate } from 'react-router-dom'
import { HsCodeContext } from '../../../context/HSCodeContext';



const NewHSCodeTree = ({ product, ImportingCountry, ExportingCountry }) => {
    const navigate = useNavigate()
    const { hsCodeDetails, setHsCodeDetails } = useContext(HsCodeContext);
    const [selectedImportProduct, setSelectedImportProduct] = useState("");
    const [selectedExportProduct, setSelectedExportProduct] = useState("");

    const handleSelectImportProduct = (item) => {
        setSelectedImportProduct(item);
    };


    const handleSelectExportProduct = (item) => {
        setSelectedExportProduct(item);
    };

    useEffect(() => {
        // console.log("Selected Import Product:", selectedImportProduct);
        // console.log("Selected Export Product:", selectedExportProduct);
        setHsCodeDetails(prev => ({
            ...prev,
            hsCode: selectedImportProduct?.value
        }));
    }, [selectedImportProduct]);

    const handleDutyCal = () => {
        navigate("/duty-calculator")
    }
    return (
        <>
            {(product && product.import) &&
                <Grid className={styles.selectHScodeContainer}>
                    <Grid className={styles.hsCodeTree}>
                        <Grid className={styles.rightSide}>
                            <Box className={styles.findCode}>
                                <Grid marginBlockStart={2} className={styles.import}>
                                    <Typography className={styles.importingCountryName} textAlign={"left"} variant='h4' fontSize={20} marginBlockEnd={2} fontWeight={700} margin={0} borderBottom={"1px solid #d8d8d8"} padding={"10px 20px"} position={"sticky"} top={0}>HS Code of {ImportingCountry.label}</Typography>
                                    {product?.import?.map((item, index) => {
                                        return (
                                            <Grid className={styles.card} key={index}>
                                                <Box><Typography variant='h4' fontSize={16} fontWeight={700}>{item.value}</Typography></Box>
                                                <Box><Typography>{item.label}</Typography></Box>
                                                <Grid
                                                    key={item.value}
                                                    className={`${selectedImportProduct?.value === item.value ? styles.active : styles.inActive}`}
                                                    onClick={() => handleSelectImportProduct(item)}>
                                                    <Typography>{"Select"}</Typography>
                                                    <Box></Box>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                                <Grid marginBlockStart={2} className={styles.export}>
                                    <Typography className={styles.exportingCountryName} textAlign={"left"} variant='h4' fontSize={20} marginBlockEnd={2} fontWeight={700} margin={0} borderBottom={"1px solid #d8d8d8"} padding={"10px 20px"} position={"sticky"} top={0}>HS Code of {ExportingCountry.label}</Typography>
                                    {product?.export?.map((item) => {
                                        return (
                                            <Grid className={styles.card}>
                                                <Box><Typography variant='h4' fontSize={16} fontWeight={700}>{item.value}</Typography></Box>
                                                <Box><Typography>{item.label}</Typography></Box>
                                                <Box key={item.value}
                                                    className={`${selectedExportProduct?.value === item.value ? styles.active : styles.inActive}`}
                                                    onClick={() => handleSelectExportProduct(item)}>
                                                    <Typography>{"Select"}</Typography>
                                                    <Box></Box>
                                                </Box>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Box>
                            <Box className={(selectedExportProduct && selectedImportProduct) ? styles.proceedToDutyCal : styles.disableProceedToDutyCal} display={(selectedExportProduct && selectedImportProduct) ? "block" : "none"} >
                                <Button onClick={handleDutyCal}>Proceed To Duty Calculator</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>

    )
}

export default NewHSCodeTree