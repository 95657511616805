import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import './select.style.css';
import CountryFlag from 'react-country-flag';
import { baseUrl } from '../../utils/baseURL';

const CountrySelectHome = ({ placeholder, setSelectedCountry, selectedCountry, type }) => {
    const [countries, setCountries] = useState([]);
    const [isSearchable, setIsSearchable] = useState(false);
    const selectRef = useRef(null);

    useEffect(() => {
        const fetchCountries = async () => {  
            try { 
                const response = await axios.get(`${baseUrl}/country/search`,{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                let filterImportingCountry
                if (type === "importing_country") {
                    filterImportingCountry = response.data.filter(item => (
                        item.is_import
                    ))
                } else {
                    filterImportingCountry = response.data.filter(item => (
                        item.is_export
                    ))
                }

                const countryData = filterImportingCountry.map(country => ({
                    value: country.value,
                    label: country.label,
                    flag: country.flag,
                    code: country.value
                }));
                setCountries(countryData);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        fetchCountries();
    }, [type]);

    const handleChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        console.log("selectedOption", selectedOption);
        if (selectRef.current) {
            selectRef.current.blur();
        }
    };

    const customSingleValue = ({ data }) => (
        <div className="custom-single-value">
            <CountryFlag countryCode={data.flag} svg style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }} />
            {data.label}
        </div>
    );

    const customOption = (props) => {
        const { data, innerRef, innerProps, isFocused, isSelected } = props;
        return (
            <div
                ref={innerRef}
                {...innerProps}
                className={`custom-option ${isFocused ? 'focused' : ''} ${isSelected ? 'selected' : ''}`}
            >
                <CountryFlag countryCode={data.flag} svg style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }} />
                {data.label}
            </div>
        );
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#e6f3ff' : state.isSelected ? '#cce6ff' : 'white',
            color: state.isFocused || state.isSelected ? '#000' : '#333',
            padding: 10,
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            color: '#000',
        }),
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            borderColor: '#ccc',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#888',
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            // justifyContent: 'center',
            padding: "10px 5px"
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'white',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#888',
        }),
        menuPortal: (provided) => ({
            ...provided,
            // top: "320px", 
        }),
    };

    return (
        <div className='select-country-container'>
            <Select
                ref={selectRef}
                options={countries}
                components={{ SingleValue: customSingleValue, Option: customOption }}
                styles={customStyles}
                isSearchable={isSearchable}
                onChange={handleChange}
                onFocus={() => setIsSearchable(true)}
                onBlur={() => setIsSearchable(false)}
                value={selectedCountry}
                placeholder={placeholder}
                getOptionLabel={(e) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CountryFlag countryCode={e.flag} svg style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }} />
                        {e.label}
                    </div>
                )}
                getOptionValue={(e) => e.label}
                menuPlacement="auto"
                menuPosition="absolute"
                menuPortalTarget={document.body}
            />
        </div>
    );
};

export default CountrySelectHome;
