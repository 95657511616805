import React from 'react'
import styles from './CommonButton.module.scss'
import { Box, Button, Grid } from '@mui/material'
import { BiUser } from "react-icons/bi";


const CommonButton = ({ name, isIcon, handleClick }) => {
    return (
        <Grid onClick={handleClick} className={styles.commonButton}>
            <Box>
                {isIcon ? <BiUser fontSize={21} /> : ""}

                <Button>{name}</Button>
            </Box>
        </Grid>
    )
}

export default CommonButton