import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardMedia, Typography, Button } from '@mui/material';

const blog =    {
    "id": 7,
    "uuid": "cbb67ddc-b37a-4f99-a05d-ad06b1f35bee",
    "name": "Olympic",
    "short_name": "316435youth-day",
    "title": "olympic",
    "type": "sports",
    "url": "https://beta.intgrow.co/blogs/blog/table-test",
    "description": "hello",
    "keywords": "environment day",
    "tags": "United Nation",
    "img_url": "https://img.freepik.com/free-photo/online-message-blog-chat-communication-envelop-graphic-icon-concept_53876-139717.jpg",
    "img_type": "image/jpeg",
    "img_alt": "new year",
    "video": "https://www.google.com/",
    "locale": "en_US",
    "related_blogs": "5-imp-rice",
    "image": "http://res.cloudinary.com/dznncrjxh/image/upload/v1723877877/INT75l3a6Rectangle%202258%20%287%29.png.png",
    "content": "<code>&lt;table&gt;<br>&nbsp; &lt;tr&gt;<br>&nbsp; &nbsp; &lt;th&gt;Company&lt;/th&gt;<br>&nbsp; &nbsp; &lt;th&gt;Contact&lt;/th&gt;<br>&nbsp; &nbsp; &lt;th&gt;Country&lt;/th&gt;<br>&nbsp; &lt;/tr&gt;<br>&nbsp; &lt;tr&gt;<br>&nbsp; &nbsp; &lt;td&gt;Alfreds Futterkiste&lt;/td&gt;<br>&nbsp; &nbsp; &lt;td&gt;Maria Anders&lt;/td&gt;<br>&nbsp; &nbsp; &lt;td&gt;Germany&lt;/td&gt;<br>&nbsp; &lt;/tr&gt;<br>&nbsp; &lt;tr&gt;<br>&nbsp; &nbsp; &lt;td&gt;Centro comercial Moctezuma&lt;/td&gt;<br>&nbsp; &nbsp; &lt;td&gt;Francisco Chang&lt;/td&gt;<br>&nbsp; &nbsp; &lt;td&gt;Mexico&lt;/td&gt;<br>&nbsp; &lt;/tr&gt;<br>&nbsp; &lt;tr&gt;<br>&nbsp; &nbsp; &lt;td&gt;Ernst Handel&lt;/td&gt;<br>&nbsp; &nbsp; &lt;td&gt;Roland Mendel&lt;/td&gt;<br>&nbsp; &nbsp; &lt;td&gt;Austria&lt;/td&gt;<br>&nbsp; &lt;/tr&gt;<br>&nbsp; &lt;tr&gt;<br>&nbsp; &nbsp; &lt;td&gt;Island Trading&lt;/td&gt;<br>&nbsp; &nbsp; &lt;td&gt;Helen Bennett&lt;/td&gt;<br>&nbsp; &nbsp; &lt;td&gt;UK&lt;/td&gt;<br>&nbsp; &lt;/tr&gt;<br>&nbsp; &lt;tr&gt;<br>&nbsp; &nbsp; &lt;td&gt;Laughing Bacchus Winecellars&lt;/td&gt;<br>&nbsp; &nbsp; &lt;td&gt;Yoshi Tannamuri&lt;/td&gt;<br>&nbsp; &nbsp; &lt;td&gt;Canada&lt;/td&gt;<br>&nbsp; &lt;/tr&gt;<br>&nbsp; &lt;tr&gt;<br>&nbsp; &nbsp; &lt;td&gt;Magazzini Alimentari Riuniti&lt;/td&gt;<br>&nbsp; &nbsp; &lt;td&gt;Giovanni Rovelli&lt;/td&gt;<br>&nbsp; &nbsp; &lt;td&gt;Italy&lt;/td&gt;<br>&nbsp; &lt;/tr&gt;<br>&lt;/table&gt;</code>",
    "author_id": 84,
    "ip": "106.219.167.25, 162.158.22.75",
    "modifiedBy": "84",
    "createdBy": "84",
    "createdAt": "2024-08-17T06:57:57.000Z",
    "updatedAt": "2024-08-17T06:57:57.000Z",
    "ig_user": {
        "last_name": "Singh",
        "first_name": "Narendra",
        "email": "narendraotusone@gmail.com"
    }
}

const BlogPost = () => {
  const {
    title,
    description,
    img_url,
    img_alt,
    content,
    url,
    ig_user
  } = blog;

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', mt: 4 }}>
      {img_url && (
        <CardMedia
          component="img"
          height="200"
          image={img_url}
          alt={img_alt}
        />
      )}
      <CardContent>
        <Typography variant="h4" component="div" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h6" color="text.secondary" paragraph>
          {description}
        </Typography>
        <Typography variant="body1" paragraph>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Typography>
        {url && (
          <Button variant="contained" color="primary" href={url} target="_blank" rel="noopener">
            Read Full Blog
          </Button>
        )}
        <Typography variant="body2" color="text.secondary" mt={2}>
          <strong>Author:</strong> {ig_user.first_name} {ig_user.last_name}
        </Typography>
      </CardContent>
    </Card>
  );
};

// BlogPost.propTypes = {
//   blog: PropTypes.shape({
//     title: PropTypes.string.isRequired,
//     description: PropTypes.string.isRequired,
//     img_url: PropTypes.string,
//     img_alt: PropTypes.string,
//     content: PropTypes.string.isRequired,
//     url: PropTypes.string,
//     ig_user: PropTypes.shape({
//       first_name: PropTypes.string.isRequired,
//       last_name: PropTypes.string.isRequired,
//       email: PropTypes.string.isRequired
//     }).isRequired
//   }).isRequired
// };

export default BlogPost;
