import React from 'react'
import { Typography } from '@mui/material'
import style from './continuewith.module.scss'

const ContinueWith = () => {
    return (
        <div className={style.container}>
            <div className={style.line}></div>
            <Typography variant="h6" className={style.text}>Continue with</Typography>
            <div className={style.line}></div>
        </div>
    )
}

export default ContinueWith
