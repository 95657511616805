import React, { useState, useEffect } from 'react';
import { TextField, Typography, Grid, Box, Button, useTheme,Modal } from '@mui/material';
import './calculator.css';
import { tokens } from '../../theme'

const Calculator = () => {
    const theme = useTheme()
    const mode = theme.palette.mode
    const colors = tokens(mode)

    const [fobValue, setFobValue] = useState(0);
    const [cifValue, setCifValue] = useState(0);
    const [ddpValue, setDdpValue] = useState(0);
    const [showSummaryModel, setShowSummaryModel] = useState(false)
    const [values, setValues] = useState({
        valueOfGoods: '',
        handlingCost: '',
        exportCost: '',
        handlingLoading: '',
        mainTransport: '',
        insurance: '',
        arrivalCost: '',
        importTaxes: '',
        customsFormalities: '',
        carriageAfter: ''
    });

    const showSummary = () => {
        setShowSummaryModel(true)
    }

    const closeSummaryModel=()=>{
        setShowSummaryModel(false)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };

    // Calculate FOB value
    useEffect(() => {
        const { valueOfGoods, handlingCost, exportCost, handlingLoading } = values;
        const fobTotalValue =
            parseFloat(valueOfGoods || 0) +
            parseFloat(handlingCost || 0) +
            parseFloat(exportCost || 0) +
            parseFloat(handlingLoading || 0);
        setFobValue(fobTotalValue);
    }, [values.valueOfGoods, values.handlingCost, values.exportCost, values.handlingLoading]);

    // Calculate CIF value
    useEffect(() => {
        const { mainTransport, insurance } = values;
        const totalCifValue =
            parseFloat(mainTransport || 0) +
            parseFloat(insurance || 0) +
            parseFloat(fobValue);
        setCifValue(totalCifValue);
    }, [values.mainTransport, values.insurance, fobValue]);

    // Calculate DDP value
    useEffect(() => {
        const { arrivalCost, importTaxes, customsFormalities, carriageAfter } = values;
        const ddpTotalValue =
            parseFloat(arrivalCost || 0) +
            parseFloat(importTaxes || 0) +
            parseFloat(customsFormalities || 0) +
            parseFloat(carriageAfter || 0) +
            parseFloat(cifValue);
        setDdpValue(ddpTotalValue);
    }, [values.arrivalCost, values.importTaxes, values.customsFormalities, values.carriageAfter, cifValue]);

    return (
        <Box className="offile-calculator" p={3} mt={4}>
            <Typography variant="h4" className='export-price-calculator' gutterBottom>
                Export Price Calculator
            </Typography>
            <Grid container spacing={2} sx={{ margin: "0 auto", width: "100%" }}>
                <Grid item xs={12} sx={{
                    paddingLeft:{
                        md:"0px"
                    }
                }}>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '2fr 1fr 1fr',
                        gap: 2,
                        backgroundColor: colors.primary[400],
                        padding: 2,
                        borderRadius: '4px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                    }}>
                        <Typography variant="subtitle1" fontWeight="bold">Type of expenses</Typography>
                        <Typography variant="subtitle1" fontWeight="bold">Values</Typography>
                        <Typography variant="subtitle1" fontWeight="bold">Incoterm</Typography>

                        {/* FOB Section */}
                        <Typography>Value of the goods (loaded on leaving the factory)</Typography>
                        <TextField
                            name="valueOfGoods"
                            value={values.valueOfGoods}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                sx: {
                                  padding: {
                                    xs: '5px',
                                    md: 'default', 
                                  },
                                },
                              }}
                        />
                        <Typography>From the factory (EXW)</Typography>

                        <Typography>Cost of handling carriage before (from the factory to the port or to the airport)</Typography>
                        <TextField
                            name="handlingCost"
                            value={values.handlingCost}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            
                        />
                        <Typography>-</Typography>

                        <Typography>Fixed cost of export Customs formalities</Typography>
                        <TextField
                            name="exportCost"
                            value={values.exportCost}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                        />
                        <Typography>-</Typography>

                        <Typography>Handling costs (loading onto the airplane, the vessel or the truck in the case of groupage) in originating terminal</Typography>
                        <TextField
                            name="handlingLoading"
                            value={values.handlingLoading}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                        />
                    </Box>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '2fr 1fr 1fr',
                        margin: "20px 0",
                        gap: 2,
                        backgroundColor: '#797d8c',
                        padding: 2,
                        borderRadius: '4px',
                        color: "#fff"
                    }}>
                        <Typography>Free on board / Free Carrier</Typography>
                        <Typography variant="body1" fontWeight="bold" textAlign="center">
                            {fobValue}
                        </Typography>
                        <Typography>FOB / FCA</Typography>
                    </Box>

                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '2fr 1fr 1fr',
                        gap: 2,
                        backgroundColor: colors.primary[400],
                        padding: 2,
                        borderRadius: '4px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                    }}>

                        {/* CIF Section */}
                        <Typography>Total cost of the main transport (by air, sea or land)</Typography>
                        <TextField
                            name="mainTransport"
                            value={values.mainTransport}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                        />
                        <Typography>-</Typography>

                        <Typography>Cost of insurance for the main transport</Typography>
                        <TextField
                            name="insurance"
                            value={values.insurance}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                        />
                    </Box>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '2fr 1fr 1fr',
                        gap: 2,
                        backgroundColor: '#797d8c',
                        padding: 2,
                        borderRadius: '4px',
                        color: "#fff",
                        margin: "20px 0",
                    }}>
                        <Typography>Cost of the Insurance and Freight</Typography>
                        <Typography variant="body1" fontWeight="bold" textAlign="center">
                            {cifValue}
                        </Typography>
                        <Typography>CIF</Typography>
                    </Box>

                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '2fr 1fr 1fr',
                        gap: 2,
                        backgroundColor: colors.primary[400],
                        padding: 2,
                        borderRadius: '4px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                    }}>

                        {/* DDP Section */}
                        <Typography>Cost of handling on arrival at the (air)port or bulk-breaking platform</Typography>
                        <TextField
                            name="arrivalCost"
                            value={values.arrivalCost}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                        />
                        <Typography>-</Typography>

                        <Typography>Import taxes (Customs Duty + Local Taxes)</Typography>
                        <TextField
                            name="importTaxes"
                            value={values.importTaxes}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                        />
                        <Typography>-</Typography>

                        <Typography>Cost of import Customs formalities (flat rate)</Typography>
                        <TextField
                            name="customsFormalities"
                            value={values.customsFormalities}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                        />
                        <Typography>-</Typography>

                        <Typography>Cost of carriage after (from the port (airport) to the buyer)</Typography>
                        <TextField
                            name="carriageAfter"
                            value={values.carriageAfter}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                        />
                    </Box>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '2fr 1fr 1fr',
                        gap: 2,
                        backgroundColor: '#797d8c',
                        padding: 2,
                        borderRadius: '4px',
                        color: "#fff",
                        margin: "20px 0",
                    }}>
                        <Typography>Delivered Duty Paid</Typography>
                        <Typography variant="body1" fontWeight="bold" textAlign="center">
                            {ddpValue}
                        </Typography>
                        <Typography>DDP</Typography>
                    </Box>
                </Grid>
            </Grid>
            {/* <Button variant="contained" color="primary" onClick={showSummary}
                sx={{
                    mt: 2,
                    backgroundColor: "var(--intgrow-bg)",
                    "&:hover": {
                        backgroundColor: "var(--intgrow-bg)"
                    }
                }}>
                Calculate
            </Button>

            <Modal open={showSummaryModel} onClose={closeSummaryModel}>
                <Box sx={{
                    p: 4, backgroundColor: colors.primary[400], borderRadius: 2, maxWidth: 800,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    overflow: "auto",
                    height: "450px"
                }}>
                    <Typography>
                        FOB / FCA-(Free on board / Free Carrier)={fobValue}
                    </Typography>
                    <Typography>
                        CIF-(Cost of the Insurance and Freight)={cifValue}
                    </Typography>
                    <Typography>
                        DDP-(Delivered Duty Paid)={ddpValue}
                    </Typography>
                </Box>
            </Modal > */}
        </Box>
    );
};

export default Calculator;
