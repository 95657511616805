import React from 'react'
import { Helmet } from 'react-helmet';
import FAQ from '../../components/FAQ/FAQ';
 
const FAQPage = () => {
  return (
    <div>
          <Helmet>
                <title>FAQ | IntGrow Corp.</title>
                <meta property="og:title" content="Dashboard" />
                <meta property="og:url" content="https://alpha.intgrow.co/dashboard" />
                <meta property="og:type" content="information" />
                <meta property="og:description" content="what we serve" />
                <meta name="keywords" content="HS Code, duty calculator, duty saver " />
            </Helmet>
            <FAQ/>
    </div>
  )
}

export default FAQPage
