import React, { createContext, useContext, useState } from 'react';
import { baseUrl } from '../utils/baseURL';

const WalletContext = createContext();

export const useWallet = () => useContext(WalletContext);

export const WalletProvider = ({ children }) => {
  const [userWallets, setUserWallets] = useState({});

  const fetchWallets = async () => {
    const token = window.localStorage.getItem("access_token");
    try {
      const response = await fetch(`${baseUrl}/wallets/get`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "x-api-key":token
        },
      });
      const responseData = await response.json();
      if (!response.ok) {
        console.error("Error in getting wallets");
      }
      setUserWallets(responseData?.result);
    } catch (error) {
      console.error("Error in fetching wallets", error);
    }
  };

  return (
    <WalletContext.Provider value={{ userWallets, fetchWallets }}>
      {children}
    </WalletContext.Provider>
  );
};
