import React, { useState } from 'react';
import './BlogDetails.css';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PersonIcon from '@mui/icons-material/Person';
import CategoryIcon from '@mui/icons-material/Category';
import { useTheme, Box } from '@mui/material';
import { formattedDate } from '../../../utils/utils';
import { tokens } from '../../../theme';

const BlogDetails = ({ initialblogList }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = tokens(mode);
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedBlogId, setExpandedBlogId] = useState(null);
  const itemsPerPage = 5;

  const lightColor = {
    color: mode === "dark" ? "#b9b9b9" : "#555"
  };

  const totalPages = Math.ceil(initialblogList.length / itemsPerPage);

  const currentBlogs = initialblogList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleReadmore = (blogId) => {
    setExpandedBlogId(expandedBlogId === blogId ? null : blogId);
  };

  return (

    <div className='mainContainer'>
      {(currentBlogs && currentBlogs.length>0 ) ? currentBlogs.map((blog) => (
        <div key={blog.id} className={mode === "dark" ? 'dark-blogContainer' : "blogContainer"}>
          <div className='contentRow'>
            <img src={blog.image} alt={blog.img_alt} className='blogimage' />
            <div className='textContent'>
              <h4 className='blogTitle'>{blog.title}</h4>
              <p className='blogDescription'  style={lightColor}>{blog.description}</p>
              <div className='blogFooter'>
                <div className='newsType' style={lightColor}><CategoryIcon style={{ marginRight: '5px', fontSize: '18px' }} />Type: {blog.type}</div>
                <div className='date' style={lightColor}>Date: {formattedDate(blog.createdAt)}</div>
              </div>
            </div>
          </div>
          
          {expandedBlogId === blog.id && (
            <Box className='expandedSection'>
              <div className='userDetails'>
                <div className='userName'><PersonIcon style={{ marginRight: '5px', fontSize: '18px' }} />{`${blog.ig_user.first_name} ${blog.ig_user.last_name}`}</div>
                <div className='blogType'><CategoryIcon style={{ marginRight: '5px', fontSize: '18px' }} />{blog.type}</div>
              </div>
              <Box className='blogContent'>
                <div dangerouslySetInnerHTML={{ __html: blog.content }} />
              </Box>
            </Box>
          )}

          <button
            className='readMore'
            onClick={() => handleReadmore(blog.id)}
            style={{
              color: mode === "dark" ? "#fff" : "#000"
            }}
          >
            {expandedBlogId === blog.id ? 'Show Less' : 'Read More'}
            <ChevronRightIcon style={{ color: 'blue', fontSize: 20, marginLeft: 5 }} />
          </button>
        </div>
      )):"No Blogs"}

      <div className='pagination'>
        {[...Array(Math.min(5, totalPages))].map((_, index) => (
          <button
            key={index}
            className={`paginationButton ${currentPage === index + 1 ? 'active' : ''}`}
            onClick={() => goToPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
        {totalPages > 5 && currentPage < totalPages && (
          <button className='nextButton' onClick={nextPage}>
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default BlogDetails;
