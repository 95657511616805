import React, { useState } from 'react';
import { Button, Box, Typography, TextField } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { baseUrl } from '../../utils/baseURL';

const UploadLatestUpdatesFile = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState();
  const [error, setError] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.csv, .xlsx',
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      setError('');
    },
    onDropRejected: () => {
      setError('Only CSV and Excel files are allowed');
    }
  });



  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('message', message);

    try {
      const response = await fetch(`${baseUrl}/upload-latest-updates`, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        console.log('File uploaded successfully');
        setFile(null);
        setError('');
      } else {
        console.error('Failed to upload file');
        setError('Failed to upload file');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Error uploading file');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        maxWidth: 600,
        margin: '0 auto',
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
        bgcolor: 'background.paper',
        margin: "20px auto"
      }}
    >
      <Typography variant="h5" component="h2" gutterBottom>
        Upload Latest Updates
      </Typography>
      <Box
        {...getRootProps()}
        sx={{
          p: 2,
          border: '1px dashed',
          borderRadius: 2,
          textAlign: 'center',
          cursor: 'pointer',
          bgcolor: 'background.default'
        }}
      >
        <input {...getInputProps()} />
        <Typography variant="body1" color="text.secondary">
          Drag and drop a file here, or click to select one
        </Typography>
        {file && <Typography variant="body2" color="text.primary">File: {file.name}</Typography>}
        {error && <Typography variant="body2" color="error">{error}</Typography>}
      </Box>
      <TextField
        label="Message"
        variant="outlined"
        fullWidth
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!file}
        sx={{
          backgroundColor: "var(--intgrow-bg)",
          "&:hover": {
            backgroundColor: "var(--intgrow-bg)"
          }
        }}>
        Upload
      </Button>
    </Box>
  );
};

export default UploadLatestUpdatesFile;
