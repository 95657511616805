import React from 'react'
import styles from './Footer.module.scss'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Logo from '../Layout/Logo'
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  const theme = useTheme()
  const navigation = useNavigate()
  const menus = ["HOME", "ABOUT", "SERVICES", "CONTACT"]
  const handleClick = () => {
    window.scrollTo(0, 0)
  }
  return (
    <Grid container className={styles.footerContainer} sx={{ backgroundColor: theme.palette.mode === "dark" ? "#1e1e1e;" : "var(--intgrow-primary-color)", color: theme.palette.mode === "dark" ? "" : "var(--intgrow-tertiary-color)" }}>
      <Grid item sm={6}>
        <Logo logo={theme.palette.mode === "dark" ? "/assets/images/intgrow-transpernt-logo.png" : "/assets/images/intgrow-white-logo.png"} />
        <Typography fontSize={15} lineHeight={1.6}>Smartly Calculate Duties and Taxes for Unbeatable Profits</Typography>
        <Box display={"flex"} marginBlockStart={6} marginBlockEnd={2}>
          <FaFacebookF fontSize={25} />
          <FaInstagram fontSize={25} style={{ marginInline: 8 }} />
          <FaXTwitter fontSize={25} />
          <FaLinkedinIn fontSize={25} style={{ marginInline: 8 }} />
        </Box>
        <Typography marginBlockStart={2} sx={{cursor:"pointer"}} onClick={() => { navigation("/disclaimer") }}>
          Disclaimer
        </Typography>
      </Grid>
      <Grid item sm={6}>
        <Grid>
          <Typography variant='h4' fontSize={21} fontWeight={600} sx={{ color: "#ffffff" }}>Solutions</Typography>
          <Typography onClick={() => navigation("#")}>{"HS Code Finder"}</Typography>
          <Typography onClick={() => navigation("#")}>{"Import Duty Calculator"}</Typography>
          <Typography onClick={() => navigation("#")}>{"DutySaver Pro"}</Typography>
        </Grid>
        <Grid>
          <Typography variant='h4' fontSize={21} fontWeight={600} sx={{ color: "#ffffff" }}>Resources</Typography>
          <Typography onClick={() => navigation("#")}>{"Help Center"}</Typography>
          <Typography onClick={() => navigation("#")}>{"API Documentations"}</Typography>
          <Typography onClick={() => navigation("#")}>{"E-Commerce Integration"}</Typography>
          <Typography onClick={() => { navigation("/glossary"); handleClick(); }}>{"Glossary"}</Typography>
        </Grid>
        <Grid>
          <Typography variant='h4' fontSize={21} fontWeight={600} sx={{ color: "#ffffff" }}>About</Typography>
          <Typography onClick={() => { navigation("/pricing") }}>{"Pricing"}</Typography>
          <Typography onClick={() => { handleClick(); navigation("/privacy") }}>{"Privacy"}</Typography>
          <Typography onClick={() => { navigation("/terms-and-condition"); handleClick() }}>{"Terms"}</Typography>
          <Typography onClick={() => { navigation("/contact"); handleClick(); }}>{"Contact Us"}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Footer