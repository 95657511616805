import React from 'react'
import ResendEmailVerificationForm from '../../components/auth/ResendEmailVerificationForm';
import logo from '../../assest/Intgrow Logo Transpernt.png'
import Header from '../../components/header/Header'

import Footer from '../../components/footer/Footer'
import styles from './ForgotPassword.module.scss'
import { Grid } from '@mui/material'

const ResendEmailVerification = () => {

  return (
    <Grid className={styles.forgotPasswordContainer}>
      <Header
        logo={logo}

      />
      <ResendEmailVerificationForm />
      <Footer />
    </Grid>
  )
}

export default ResendEmailVerification
