import React, { useRef } from 'react';

const OtpInput = ({ value, valueLength, onChange }) => {
  const inputRefs = useRef([]);

  const handleChange = (e, index) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue)) {
      const newOtpValue = value.split('');
      newOtpValue[index] = newValue;
      onChange(newOtpValue.join(''));
      if (newValue && index < valueLength - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !value[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div className="otp-input">
      {Array.from({ length: valueLength }).map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={value[index] || ''}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputRefs.current[index] = el)}
          style={{
            width: '40px',
            height: '40px',
            fontSize: '20px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            textAlign: 'center',
          }}
        />
      ))}
    </div>
  );
};

export default OtpInput;
