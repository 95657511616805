import { Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import React, { useEffect } from 'react';
import styles from './CurrentPlan.module.scss'
import CommonHeading from '../CommonHeading/CommonHeading'
import SimpleButton from '../../components/SimpleButton/SimpleButton'
import { tokens, ColorModeContext } from '../../theme';
import { Helmet } from 'react-helmet';


const CurrentPlan = () => {
    const data = [
        {
            "id": 1,
            "heading": "Basic",
            "subHeading": "Monthly Charge",
            "price": 14.99,
            "planName": "Free Setup",
            "purchaseDate": "Free Setup",
            "expiryData": "Free Setup",
            "planValue": "Free Setup",
            "invoice": "Free Setup"
        },
        {
            "id": 2,
            "heading": "Standard",
            "subHeading": "Monthly Charge",
            "price": 49.99,
            "planName": "Free Setup",
            "purchaseDate": "Free Setup",
            "expiryData": "Free Setup",
            "planValue": "Free Setup",
            "invoice": "Free Setup"
        },
        {
            "id": 3,
            "heading": "Premium",
            "subHeading": "Monthly Charge",
            "price": 89.99,
            "planName": "Free Setup",
            "purchaseDate": "Free Setup",
            "expiryData": "Free Setup",
            "planValue": "Free Setup",
            "invoice": "Free Setup"
        }
    ]
    const theme = useTheme()
    const colors = tokens(theme.palette.mode);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
          document.body.removeChild(script);
        };
      }, []);
    return (
        <>
            <Helmet>
                <title>Current Plan | IntGrow Corp.</title>
                <meta property="og:title" content="Dashboard" />
                <meta property="og:url" content="https://intgrow.co/dashboard" />
                <meta property="og:type" content="information" />
                <meta property="og:description" content="what we serve" />
                <meta name="keywords" content="HS Code, duty calculator, duty saver " />
            </Helmet>
            <Grid className={styles.currentPlanContainer}>
                <CommonHeading heading={"Pricing"} />
                <Grid>
                    <stripe-pricing-table pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
                        publishable-key={process.env.REACT_APP_PUBLISHIABL_KEY}>
                    </stripe-pricing-table>
                </Grid>
            </Grid>
        </>
    )
}

export default CurrentPlan