import React from 'react';
import { Tabs, Tab, Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';

const FootNotesTab = ({ footNoteData }) => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const colors = tokens(mode);
    const sortedData = [...footNoteData].sort((a, b) => a.priority - b.priority);
    const [selectedTab, setSelectedTab] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Box sx={{
            width: '100%',
            marginBottom: "20px",
            backgroundColor: colors.primary[400],
            borderRadius: "7px",
        }}>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="tabs"
                sx={{ 
                    '& .MuiTab-root': {
                        color: colors.primaryColor[100],
                        fontSize: "18px",
                        fontWeight: "600",
                    },
                    '& .Mui-selected': {
                        backgroundColor:"#1994b7",
                        color: '#fff !important',
                        borderRadius: '3px',
                        borderBottom: 'none',
                    },
                    '& .MuiTab-indicator': {
                        display: 'none !important' ,
                    },
                }}
            >
                {sortedData.map((item, index) => (
                    <Tab 
                        key={index} 
                        label={item.label} 
                        sx={{
                            borderRadius: '3px',
                            '&:not(.Mui-selected)': {
                                color: colors.primaryColor[100],
                            }
                        }}
                    />
                ))}
            </Tabs>
            {sortedData.map((item, index) => (
                <TabPanel value={selectedTab} index={index} key={index}>
                    <Typography sx={{
                            color:"#797d8c",
                            fontSize: "12px",
                    }}>{item.value}</Typography>
                </TabPanel>
            ))}
        </Box>
    );
};

const TabPanel = (props) => {
    const { value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {props.children}
                </Box>
            )}
        </div>
    );
};

export default FootNotesTab;
