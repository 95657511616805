import React, { useState } from 'react';
import styles from './ContactForm.module.scss';
import { Grid, TextareaAutosize, Typography } from '@mui/material';
import InputField from '../InputField/InputField';
import CommonButton from '../CommonButton/CommonButton';
import { baseUrl } from '../../utils/baseURL';

const ContactForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [message, setMessage] = useState("");

    const handleContactSubmit = async () => {
        try {
            const token = window.localStorage.getItem('access_token');
            const payload = {
                name, email, mobile, message
            };
            const response = await fetch(`${baseUrl}/auth/contact/us`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "x-api-key":token
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                alert("Your query has been submitted successfully");
                setMessage("")
                setMobile("")
                setEmail("")
                setName("")
            } else {
                alert("Failed to submit your query");
            }
        } catch (error) {
            console.error('Error submitting support request', error);
            alert("An error occurred while submitting your query");
        }
    };

    return (
        <Grid className={styles.contactForm}>
            <Typography marginBlockEnd={2} sx={{ color: "#ffffff" }} variant='h4' fontSize={21} fontWeight={800}>Contact Form</Typography>
            <InputField label={"Name"} placeholder={"Enter your name"} value={name} onChange={(e) => setName(e.target.value)} />
            <InputField label={"Phone"} placeholder={"Enter your phone"} value={mobile} onChange={(e) => setMobile(e.target.value)} />
            <InputField label={"Email"} placeholder={"Enter your email"} value={email} onChange={(e) => setEmail(e.target.value)} />
            <Typography sx={{ color: "#ffffff" }} placeholder={"Write message"}>Message</Typography>
            <TextareaAutosize 
                minRows={5} 
                value={message} 
                onChange={(e) => setMessage(e.target.value)} 
            />
            <CommonButton name={"Submit"} handleClick={handleContactSubmit} />
        </Grid>
    );
};

export default ContactForm;
