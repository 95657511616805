import React, { useState } from 'react';
import { Grid, TableCell, Button, CircularProgress, Modal, Box } from '@mui/material';
import styled from '@emotion/styled';
import DutyCalcShipmentSummary from './DutyCalcShipmentHistory';
import DutyCalCalculationTable from './DutyCalcCalculationTable';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const RightCell = styled(TableCell)`
  font-size: 14px;
  font-weight: 500;
  border-left: 1px solid #dadada;
  color: #797d8c;
`;

const LeftCell = styled(TableCell)`
  font-size: 14px;
  font-weight: 500;
  color: #797d8c;
`;

const DutyCalcHistoryModel = ({ product, countryData }) => {
  const [loading, setLoading] = useState(false);

  const handleDownloadPDF = async () => {
    setLoading(true);
    const input = document.getElementById('pdf-content');
    try {
      const canvas = await html2canvas(input, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('duty-saver-report.pdf');
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleDownloadPDF}
        disabled={loading}
      >
        Download as PDF
      </Button>
      
      <Modal
        open={loading}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
        closeAfterTransition
        disableBackdropClick
        disableEscapeKeyDown
      >
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          height="100vh"
        >
          <CircularProgress />
          downloadin...
        </Box>
      </Modal>
      
      <div id="pdf-content">
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} className="import">
            <DutyCalCalculationTable getDutyResponse={product} />
          </Grid>
          <Grid item xs={12} md={12} className="import">
            <DutyCalcShipmentSummary product={product} countryData={countryData} />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default DutyCalcHistoryModel;
