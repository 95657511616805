import React from 'react'
import ResetPwdForm from '../../components/auth/ResetPwdForm';
import logo from '../../assest/Intgrow Logo Transpernt.png';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import {Grid} from '@mui/material'

const ResetPassword = () => {
 

  return (
    <Grid>
      <Header logo={logo}/>
      <ResetPwdForm />
      <Footer/>
      </Grid>

  )
}

export default ResetPassword
