import React, { useState, useEffect, useCallback } from 'react'
import styles from './Profile.module.scss'
import { Box, Grid, Typography, useTheme, CircularProgress } from '@mui/material'
import CommonHeading from '../CommonHeading/CommonHeading'
import PhoneIcon from '@mui/icons-material/Phone';
import DisabledInputField from '../../components/InputField/disabledInputField'
import profile from '../../assest/profile.jpg'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SimpleButton from '../../components/SimpleButton/SimpleButton';
import { tokens } from '../../theme';
import { getUserData } from '../../utils/Api';
import { baseUrl } from "../../utils/baseURL";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import ProfileSetting from '../ProfileSetting/ProfileSetting';

const Profile = () => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState({
        first_name: '',
        last_name: '', 
        mobile: '',
        email: '',
        photo_url: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            const userData = await getUserData();
            setValues(userData);
        };
        fetchData();
    }, []);

    const handleChange = useCallback((event) => {
        setValues((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }, []);


    const updateUserInfo = async () => {
        try {
            setLoading(true);
            const url = `${baseUrl}/auth/me`;
            const data = {
                "first_name": values?.first_name,
                "last_name": values?.last_name,
                "email": values?.email,
                "mobile": values?.mobile,
                "country": values?.country,
                "state": values?.state,
            };
            const token = window.localStorage.getItem('access_token');
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "x-api-key":token
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                const responseData = await response.json();
                const loginError = new Error(responseData.error || 'An error occurred');
                loginError.status = response.status;
                setLoading(false);
                throw loginError;
            }
            const responseData = await response.json();
            toast.success(responseData.message || 'User Account updated successfully');
            setLoading(false);

            return response;
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);

        }
    };

    return (
        <>
            <Helmet>
                <title>Account Setting | IntGrow Corp.</title>
            </Helmet>
            <Grid className={styles.profileContainer} >
                <CommonHeading heading={"Account"} />
                <Grid className={styles.profile}>
                    <Grid className={styles.leftSide} sx={{ backgroundColor: colors.primary[400] }}>
                        <Box>
                            <Box>
                                <img src={values?.photo_url} />
                            </Box>
                            <Typography marginBlockStart={2} marginBlockEnd={1} variant='h2' fontSize={21} fontWeight={600}>{values?.first_name + " " + values?.last_name}</Typography>
                            <Box display={"flex"} marginBlockStart={3.5} marginBlockEnd={1}>
                                <PhoneIcon style={{ marginBlock: "auto", marginInlineEnd: 8 }} />
                                <Typography fontWeight={600} sx={{ marginBlock: "auto" }}>Mobile: {values?.mobile}</Typography>
                            </Box>
                            <Box display={"flex"}>
                                <MailOutlineIcon style={{ marginBlock: "auto", marginInlineEnd: 8 }} />
                                <Typography fontWeight={600} sx={{ marginBlock: "auto" }}>{values?.email}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid className={styles.rightSide} sx={{ backgroundColor: colors.primary[400] }}>
                        <Typography variant='h4' fontSize={25} fontWeight={700}>Profile</Typography>
                        <Typography fontSize={13} fontWeight={600}>The information can be edited</Typography>
                        <Grid className={styles.field}>
                            <Box>
                                <DisabledInputField label={"First Name"} name="first_name" value={values?.first_name} onChange={(event) => handleChange(event)} />
                                <DisabledInputField label={"Your Email"} disabled name="email" value={values?.email} onChange={(event) => handleChange(event)} />
                            </Box>
                            <Box>
                                <DisabledInputField label={"Last Name"} name="last_name" value={values?.last_name} onChange={(event) => handleChange(event)} />
                                <DisabledInputField type="number" label={"Phone Number"} name="mobile" value={values?.mobile} onChange={(event) => handleChange(event)} />
                                {/* <SelectField label={"State"} onChange={(event) => handleChange(event)} /> */}
                            </Box>
                        </Grid>
                        <SimpleButton name={"Save Details"} onClick={updateUserInfo} />
                    </Grid>
                    {loading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress />
                        </Box>
                    )}
                </Grid>

                <ProfileSetting/>
            </Grid>
        </>
    )
}

export default Profile