// AdminLayout.js
import React,{useState} from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../scenes/global/Sidebar";
// import AdminPanelTopbar from "../scenes/global/AdminPanelTopbar";
import AdminPanelTopbar from "../scenes/global/AdminPanelTobbar";

import { Box } from "@mui/material";

const DashboardLayout = () => {
  const [isSidebar, setIsSidebar] = useState(true);

    return (
        <div className='app'>
            <Sidebar  />
            <main className='content'>
                <Box flexGrow={1}>
                    <AdminPanelTopbar />
                    <Box>
                        <Outlet />
                    </Box>
                </Box>
            </main>
        </div >
    );
};

export default DashboardLayout;
