import React, { useState, useEffect } from 'react';
import { Box, useTheme, Typography, Grid, Button, CircularProgress, Modal } from '@mui/material';
import axios from 'axios';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { tokens } from '../../theme';
import { formattedDate } from '../../utils/utils';
import '../DutyCalculator/dutycalculator.style.css';
import { baseUrl } from '../../utils/baseURL';
import DutySaverTable from '../DutySaverPro/DutySaverTable';

const getNotesLabelsUrl = `${baseUrl}/dutyCalculator/getNotesLabels?import_country=`;

const DutySaverHistoryStripe = ({ saveDutyResponse }) => {
    const [loading, setLoading] = useState(false);

    const { imp_cyn, import_country, export_country, CIF } = saveDutyResponse;
    const selectedCurrency = imp_cyn;
    const importingCountry = import_country;
    const exportingCountry = export_country;
    const productValue = CIF;

    const inputData = {
        selectedCurrency,
        importingCountry,
        exportingCountry,
        productValue
    };

    const theme = useTheme();
    const mode = theme.palette.mode;
    const colors = tokens(mode);

    useEffect(() => {
        const fetchNotesLabelsData = async () => {
            const savedFootnotes = saveDutyResponse[0]?.[0];
            const _imp = (localStorage.getItem('imp') || savedFootnotes?.import_country)?.toLowerCase();
            const notesLabelsRes = await axios.get(`${getNotesLabelsUrl}${_imp}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const notesData = {};
            notesLabelsRes.data.forEach((n) => {
                notesData[n.duty_code] = n.duty_short_name;
            });
        };

        fetchNotesLabelsData();
    }, []);

    const handleDownloadPDF = async () => {
        setLoading(true);
        const input = document.getElementById('pdf-content');
        try {
            const canvas = await html2canvas(input, { scale: 2 });
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210; // A4 width in mm
            const pageHeight = 295; // A4 height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('duty-saver-report.pdf');
        } catch (error) {
            console.error("Error generating PDF:", error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <Box sx={{ margin: "25px", padding: "10px" }}>
            <Button variant="contained" color="primary" onClick={handleDownloadPDF}>
                Download as PDF
            </Button>

            <Modal
                open={loading}
                aria-labelledby="loading-modal-title"
                aria-describedby="loading-modal-description"
                closeAfterTransition
                disableBackdropClick
                disableEscapeKeyDown
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                >
                    <CircularProgress />
                    downloadin...
                </Box>
            </Modal>
            <div id="pdf-content">
                <div className="tnc-note-3">
                    This total landed cost calculation is applicable as of {formattedDate()}. Foreign exchange rates are revised in accordance with notifications from the importing country.
                </div>

                <Grid container spacing={2}>
                    <Grid item sm={12} md={12} my={4}>
                        <Box sx={{
                            backgroundColor: colors.primary[400],
                            borderRadius: "7px"
                        }}>
                            <DutySaverTable saveDutyResponse={saveDutyResponse} inputData={inputData} />
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </Box>
    );
};

export default DutySaverHistoryStripe;
